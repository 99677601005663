import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  table_head: {
    background: 'rgb(243, 244, 246)',
  },
  striped_table: {
    '& tr:nth-child(even) {background-color: #f2f2f2;}': {
      backgroundColor: '#F2F2F2'
    }
  },
  inline_btn: {
    whiteSpace: 'nowrap',
    marginLeft: 8
  },
  date_range_picker_wrapper: {
    display: 'flex',
    width: 'max-content',
    alignItems: 'baseline',
    WebkitBoxAlign: 'baseline',
    '& > div': {
      display: 'inline-flex',
    }
  },
}));
