import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IMessageTemplateListRequest, IMessageTemplate, IMessageTemplateDetails, IMessageTemplatesBase } from './interfaces';

export const messageTemplateApi = createApi({
  reducerPath: 'messageTemplateApi',
  tagTypes: ['messageTemplate'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getMessageTemplatesBase: build.query<IMessageTemplatesBase, string>({
      query: () => ({
        url: endpoints.messageTemplatesBase,
      }),
    }),
    getMessageTemplates: build.query<IListResp<IMessageTemplate>, IMessageTemplateListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        messageTemplateTypeId,
      }) => ({
        url: endpoints.messageTemplates,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          messageTemplateTypeId,
        }
      }),
      providesTags: () => ['messageTemplate']
    }),
    getMessageTemplate: build.query<IMessageTemplateDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.messageTemplate}/${id}`,
      }),
      providesTags: () => ['messageTemplate']
    }),
    createMessageTemplate: build.mutation<number, IMessageTemplateDetails>({
      query: ({
        name,
        type,
        isDefault,
        fromName,
        fromEmail,
        toEmail,
        ccEmail,
        bccEmail,
        subject,
        body
      }) => ({
        url: endpoints.messageTemplateCreate,
        method: 'POST',
        body: {
          name,
          type,
          isDefault,
          fromName,
          fromEmail,
          toEmail,
          ccEmail,
          bccEmail,
          subject,
          body
        }
      }),
      invalidatesTags: ['messageTemplate']
    }),
    updateMessageTemplate: build.mutation<IMessageTemplateDetails, IMessageTemplateDetails>({
      query: ({
        id,
        name,
        type,
        isDefault,
        fromName,
        fromEmail,
        toEmail,
        ccEmail,
        bccEmail,
        subject,
        body
      }) => ({
        url: `${endpoints.messageTemplateEdit}/${id}`,
        method: 'PATCH',
        body: {
          name,
          type,
          isDefault,
          fromName,
          fromEmail,
          toEmail,
          ccEmail,
          bccEmail,
          subject,
          body
        }
      }),
      invalidatesTags: ['messageTemplate']
    }),
    deleteMessageTemplate: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.messageTemplateDelete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['messageTemplate']
    }),
  }),
});

export const {
  useGetMessageTemplatesBaseQuery,
  useLazyGetMessageTemplatesQuery,
  useGetMessageTemplateQuery,
  useCreateMessageTemplateMutation,
  useUpdateMessageTemplateMutation,
  useDeleteMessageTemplateMutation
} = messageTemplateApi;
