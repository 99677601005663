import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IUser, IUserListRequest, IUserRequest, IUserResp } from './interfaces';

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['user'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    users: build.query<IListResp<IUser>, IUserListRequest>({
      query: ({
        pageSize,
        page,
        searchText
      }) => ({
        url: `${endpoints.users}`,
        params: { pageSize, page, searchText }
      }),
    }),
    getUser: build.query<IUserResp, string>({
      query: (id: string) => ({
        url: `${endpoints.user}/${id}`,
      }),
    }),
    createUser: build.mutation<number, IUserRequest>({
      query: ({
        email,
        firstName,
        lastName,
        companyName,
        address1,
        address2,
        city,
        state,
        zip,
        province,
        country,
        phone,
        isActive
      }) => ({
        url: endpoints.userCreate,
        method: 'POST',
        body: {
          email,
          firstName,
          lastName,
          companyName,
          address1,
          address2,
          city,
          state,
          zip,
          province,
          country,
          phone,
          isActive
        }
      }),
    }),
    updateUser: build.mutation<IUserResp, IUserRequest>({
      query: ({
        id,
        email,
        firstName,
        lastName,
        companyName,
        address1,
        address2,
        city,
        state,
        zip,
        province,
        country,
        phone,
        isActive
      }) => ({
        url: `${endpoints.userEdit}/${id}`,
        method: 'PATCH',
        body: {
          email,
          firstName,
          lastName,
          companyName,
          address1,
          address2,
          city,
          state,
          zip,
          province,
          country,
          phone,
          isActive
        }
      }),
    }),
    updateUserLogin: build.mutation<IUserResp, number>({
      query: (id) => ({
        url: `${endpoints.userLoginReset}/${id}`,
        method: 'PATCH'
      }),
    })
  }),
});

export const {
  useLazyUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserLoginMutation
} = userApi;
