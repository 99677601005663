import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, MenuItem, Select, Switch, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { book, Btn, CardWrapper } from '@monorepo/common';
import { showNotification } from '../../features/ui/sliceNotification';
import GridField from '../../components/GridField';
import ConfirmModal from '../../components/ConfirmModal';
import { useCreateReleaseMutation, useDeleteReleaseMutation, useGetReleaseQuery, useUpdateReleaseMutation } from '../../services/release';
import { IReleaseDetails } from '../../services/release/interfaces';
import { useStyles } from './styles';

const Release = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const applicationId = searchParams.get('application');
  const isNew = id === 'new';

  const [fields, setFields] = useState<IReleaseDetails>({ applicationId: +applicationId! || 0 } as IReleaseDetails);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data, isLoading: isLoadingData } = useGetReleaseQuery(isNew ? '0' : id!);
  const [createRelease, { isLoading: isCreatingData }] = useCreateReleaseMutation();
  const [updateRelease, { isLoading: isUpdatingData }] = useUpdateReleaseMutation();
  const [deleteRelease, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteReleaseMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create Release';
  } else if (data) {
    pageTitle = `Release #${data && data.id}`;
  } else {
    pageTitle = 'Release not found';
  }

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const save = () => {
    if (!fields.version) {
      return;
    }

    if (isNew) {
      createRelease({ ...fields }).then((res: any) => {
        if (res.error) {
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: res.error?.data || 'Error'
          }));
        } else {
          dispatch(showNotification({
            text: 'Release created',
            type: 'success',
            show: true
          }));
          navigate(book.admin.application(fields.applicationId));
        }
      });
    } else {
      updateRelease(fields).then((res: any) => {
        if (res.error) {
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: res.error?.data || 'Error'
          }));
        } else {
          dispatch(showNotification({
            text: 'Release updated',
            type: 'success',
            show: true
          }));
          navigate(book.admin.application(fields.applicationId));
        }
      });
    }
  };

  const deleteReleaseConfirm = () => {
    deleteRelease(+id!);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (data) {
      setFields(isNew ? { ...data, applicationId: +applicationId! } : data);
    }
  }, [data]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Release removed',
        type: 'success',
        show: true
      }));
      navigate(book.admin.application(fields.applicationId));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Release"
      title={pageTitle}
      isLoading={isLoadingData || isCreatingData || isUpdatingData || isDeletingData}
      childrenTitleRight={data && (
        <Box>
          <Btn
            className={classes.inline_btn}
            disabled={!fields.version}
            click={save}
          >
            Save
          </Btn>
          {!isNew && (
            <Btn
              className={classes.inline_btn}
              click={() => setShowDeleteModal(true)}
            >
              Delete
            </Btn>
          )}
        </Box>
      )}
    >
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteReleaseConfirm}
      />
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Active"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              <Switch
                checked={fields.isActive || false}
                onChange={(event) => {
                  changeCellField('isActive', event.target.checked);
                }}
                name="isActive"
              />
            </GridField>
            <GridField
              label="Application"
              labelmd={3}
              fieldmd={9}
            >
              {fields.id != null && (
                <Select
                  value={fields.applicationId}
                  size="small"
                  onChange={(event) => changeCellField('applicationId', +event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  {data.applications.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </GridField>
            <GridField
              label="Version"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                fullWidth
                value={fields.version || ''}
                size="small"
                inputProps={{ maxLength: 250 }}
                onChange={(val) => changeCellField('version', val.target.value || '')}
              />
            </GridField>
            <GridField
              label="File Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                fullWidth
                value={fields.fileName || ''}
                size="small"
                inputProps={{ maxLength: 250 }}
                onChange={(val) => changeCellField('fileName', val.target.value || '')}
              />
            </GridField>
            <GridField
              label="Password"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                fullWidth
                value={fields.password || ''}
                size="small"
                inputProps={{ maxLength: 250 }}
                onChange={(val) => changeCellField('password', val.target.value || '')}
              />
            </GridField>
            <GridField
              label="Date"
              labelmd={3}
              fieldmd={9}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={fields.releaseDate}
                  onChange={(event) => changeCellField('releaseDate', event)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </GridField>
            <GridField
              label="Release Notes"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                fullWidth
                value={fields.releaseNotes || ''}
                size="small"
                onChange={(val) => changeCellField('releaseNotes', val.target.value || '')}
              />
            </GridField>
            <GridField
              label="Comments"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                fullWidth
                value={fields.comments || ''}
                size="small"
                onChange={(val) => changeCellField('comments', val.target.value || '')}
              />
            </GridField>
          </Grid>
        </Box>
      )}
    </CardWrapper>
  );
};

export default Release;
