import { memo } from 'react';
import { MenuItem, MenuGroup, book, MenuItemWithSubMenu } from '@monorepo/common';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { useAppSelector } from '../app/hooks';
import { Permission } from '../constants/permission';
import { searchForCommonItems } from '../utils/searchForCommonItems';

const MenuInternalLinks = () => {
  const { userRoles, isSuperUser } = useAppSelector(({ profile }) => ({
    userRoles: profile.UserRoles,
    isSuperUser: profile.IsSuper
  }));

  const hasReportsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Reports].map(String));
  const hasLicensesPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Licenses].map(String));
  const hasSupportContractsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.SupportContracts].map(String));
  const hasLogsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Logs].map(String));
  const hasProductsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Products].map(String));
  const hasQuotesPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Quotes].map(String));
  const hasRenewalQuotesPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.RenewalQuotes].map(String));
  const hasAdminUsersPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.AdminUsers].map(String));
  const hasOrdersPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Orders].map(String));
  const hasMessageTemplatesPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.MessageTemplates].map(String));
  const hasOnlineAccountsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.OnlineAccounts].map(String));
  const hasApplicationsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Applications].map(String));
  const hasSalesRepsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.SalesReps].map(String));
  const hasMergeTextsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.MergeTexts].map(String));

  return (
    <MenuGroup title="Home">
      {hasReportsPermission && (
        <>
          <MenuItem
            title="Dashboard"
            href={book.admin.dashboard}
            icon={<TimelineIcon />}
          />
          <MenuItemWithSubMenu
            title="Reports"
            icon={<AssignmentOutlinedIcon />}
          >
            <MenuItem
              title="Order Exports"
              href={book.admin.orderExports}
              subItem
            />
            <MenuItem
              title="Active Contract Mailing List"
              href={book.admin.activeContractMailingList}
              subItem
            />
          </MenuItemWithSubMenu>
        </>
      )}

      {(hasSupportContractsPermission || hasLicensesPermission) && (
        <MenuItemWithSubMenu
          title="Support"
          icon={<AssignmentIndIcon />}
        >
          {hasSupportContractsPermission && (
            <MenuItem
              title="Contracts"
              href={book.admin.supportContract()}
              subItem
            />
          )}
          {hasLicensesPermission && (
            <>
              <MenuItem
                title="Licenses"
                href={book.admin.license()}
                subItem
              />
              <MenuItem
                title="Licensing Tool"
                href={book.admin.licensingTool}
                subItem
              />
            </>
          )}
        </MenuItemWithSubMenu>
      )}

      {(hasProductsPermission
        || hasQuotesPermission
        || hasOrdersPermission
        || hasApplicationsPermission
        || hasSalesRepsPermission) && (
          <MenuItemWithSubMenu
            title="Sales"
            icon={<RequestQuoteIcon />}
          >
            {hasProductsPermission && (
              <MenuItem
                title="Products"
                href={book.admin.product()}
                subItem
              />
            )}
            {hasQuotesPermission && (
              <MenuItem
                title="Quotes"
                href={book.admin.quote()}
                subItem
              />
            )}
            {hasOrdersPermission && (
              <MenuItem
                title="Orders"
                href={book.admin.order()}
                subItem
              />
            )}
            {hasApplicationsPermission && (
              <MenuItem
                title="Applications"
                href={book.admin.application()}
                subItem
              />
            )}
            {hasSalesRepsPermission && (
              <MenuItem
                title="Sales Reps"
                href={book.admin.salesRep()}
                subItem
              />
            )}
          </MenuItemWithSubMenu>
      )}

      {hasRenewalQuotesPermission && (
        <MenuItemWithSubMenu
          title="Renewals"
          icon={<CurrencyExchangeIcon />}
        >
          <MenuItem
            title="Renewal Quotes"
            href={book.admin.renewalQuote()}
            subItem
          />
          <MenuItem
            title="Up For Renewal"
            href={book.admin.upForRenewal}
            subItem
          />
        </MenuItemWithSubMenu>
      )}

      {hasOnlineAccountsPermission && (
        <MenuItemWithSubMenu
          title="Checkbox Online"
          icon={<LibraryAddCheckIcon />}
        >
          <MenuItem
            title="US Accounts"
            href={`${book.admin.onlineAccount()}/US`}
            subItem
          />
          <MenuItem
            title="EU Accounts"
            href={`${book.admin.onlineAccount()}/Europe`}
            subItem
          />
          <MenuItem
            title="CA Accounts"
            href={`${book.admin.onlineAccount()}/Canada`}
            subItem
          />
          <MenuItem
            title="AU Accounts"
            href={`${book.admin.onlineAccount()}/Australia`}
            subItem
          />
        </MenuItemWithSubMenu>
      )}

      {hasAdminUsersPermission && (
        <MenuItem
          title="Admin Users"
          href={book.admin.adminUser()}
          icon={<PersonOutlineIcon />}
        />
      )}

      {hasMessageTemplatesPermission && (
        <MenuItem
          title="Message Templates"
          href={book.admin.messageTemplate()}
          icon={<MailOutlineIcon />}
        />
      )}

      {hasMergeTextsPermission && (
        <MenuItem
          title="Merge Texts"
          href={book.admin.mergeText()}
          icon={<TextFieldsIcon />}
        />
      )}

      {hasLogsPermission && (
        <MenuItemWithSubMenu
          title="Logs"
          icon={<ErrorOutlineIcon />}
        >
          <MenuItem
            title="Core logs"
            href={book.admin.coreLog}
            subItem
          />
          <MenuItem
            title="Exception Logs"
            href={book.admin.log}
            subItem
          />
        </MenuItemWithSubMenu>
      )}

    </MenuGroup>
  );
};

export default memo(MenuInternalLinks);
