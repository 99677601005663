import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, CardWrapper, regExp } from '@monorepo/common';
import { Autocomplete, Box, Button, CircularProgress, createFilterOptions, debounce, Grid, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { showNotification } from '../../features/ui/sliceNotification';
import { useDeleteOrderMutation, useGetBuyerBillingAutocompleteQuery, useGetBuyerShipmentAutocompleteQuery, useGetOrderQuery, useSendCustomerReceiptEmailMutation, useUpdateOrderMutation } from '../../services/order';
import { IBuyerAutocomplete, IOrderDetails } from '../../services/order/interfaces';
import GridField from '../../components/GridField';
import HeaderToolBar from './components/HeaderToolBar';
import OrderPayments from './components/OrderPayments';
import OrderProductDetails from './components/OrderProductDetails';

const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data, isLoading: isLoadingData } = useGetOrderQuery(id!);

  const [fields, setFields] = useState<IOrderDetails>({} as IOrderDetails);

  const [billingSearchText, setBillingSearchText] = useState<string>('');
  const [shipmentSearchText, setShipmentSearchText] = useState<string>('');
  const [isBillingSearchModifyed, setIsBillingSearchModifyed] = useState<boolean>(false);
  const [isShipmentSearchModifyed, setIsShipmentSearchModifyed] = useState<boolean>(false);
  const [isOrderDateModifyed, setIsOrderDateModifyed] = useState<boolean>(false);

  const { isFetching: isFetchingBuyerBillingData, data: buyerBillingData } = useGetBuyerBillingAutocompleteQuery(billingSearchText, { skip: !isBillingSearchModifyed || !billingSearchText });
  const { isFetching: isFetchingBuyerShipmentData, data: buyerShipmentData } = useGetBuyerShipmentAutocompleteQuery(shipmentSearchText, { skip: !isShipmentSearchModifyed || !shipmentSearchText });
  const [updateOrder, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateOrderMutation();
  const [sendCustomerReceiptEmail, { isLoading: isSendingEmail, isSuccess: isSendEmailSuccess }] = useSendCustomerReceiptEmailMutation();
  const [deleteOrder, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteOrderMutation();

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const onSearchBillingText = useMemo(() => debounce((value: string) => {
    setBillingSearchText(value);
    setIsBillingSearchModifyed(true);
  }, 800), []);

  const onSearchShipmentText = useMemo(() => debounce((value: string) => {
    setShipmentSearchText(value);
    setIsShipmentSearchModifyed(true);
  }, 800), []);

  const onChangeOrderDate = (date: Date | null) => {
    setIsOrderDateModifyed(true);
    changeCellField('orderDate', date);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: IBuyerAutocomplete) => option.email + option.company + option.orderId,
  });

  const onSelectBillingInfo = (value: IBuyerAutocomplete | string | null) => {
    if (typeof value !== 'string' && value !== null) {
      setIsBillingSearchModifyed(false);
      setBillingSearchText(value.email);
      setFields({
        ...fields,
        billEmail: value.email,
        billFirstName: value.firstName,
        billLastName: value.lastName,
        billPhone: value.phone,
        billCompany: value.company,
        billAddress1: value.address1,
        billAddress2: value.address2,
        billCountry: value.country,
        billProvince: value.province,
        billState: value.state,
        billCity: value.city,
        billZip: value.zip,
      });
    }
  };

  const onSelectShipmentInfo = (value: IBuyerAutocomplete | string | null) => {
    if (typeof value !== 'string' && value !== null) {
      setIsShipmentSearchModifyed(false);
      setShipmentSearchText(value.email);
      setFields({
        ...fields,
        shipEmail: value.email,
        shipFirstName: value.firstName,
        shipLastName: value.lastName,
        shipPhone: value.phone,
        shipCompany: value.company,
        shipAddress1: value.address1,
        shipAddress2: value.address2,
        shipCountry: value.country,
        shipProvince: value.province,
        shipState: value.state,
        shipCity: value.city,
        shipZip: value.zip,
      });
    }
  };

  const copyBillingAddress = () => {
    setShipmentSearchText(billingSearchText || '');
    setFields({
      ...fields,
      shipEmail: billingSearchText,
      shipFirstName: fields.billFirstName,
      shipLastName: fields.billLastName,
      shipPhone: fields.billPhone,
      shipCompany: fields.billCompany,
      shipAddress1: fields.billAddress1,
      shipAddress2: fields.billAddress2,
      shipCountry: fields.billCountry,
      shipCity: fields.billCity,
      shipProvince: fields.billProvince,
      shipState: fields.billState,
      shipZip: fields.billZip,
    });
  };

  const save = () => {
    setIsBillingSearchModifyed(true);
    setIsShipmentSearchModifyed(true);

    if (billingSearchText.trim() && shipmentSearchText.trim()) {
      updateOrder({
        ...fields,
        billEmail: billingSearchText,
        shipEmail: shipmentSearchText,
      }).then((res: any) => {
        if (res.error) {
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: res.error?.data || 'Error'
          }));
        }
      });
    }
  };

  const deleteOrderConfirm = () => {
    deleteOrder(+id!);
    navigate(book.admin.order());
  };

  const isTextValid = (text: string, regExpression: RegExp, isRequired?: boolean) => {
    if (!isRequired && (text === null || text === '')) {
      return true;
    }

    const result = text?.match(regExpression);

    return result && result[0] === text;
  };

  const isValid = () => !!(
    billingSearchText.trim()
    && shipmentSearchText.trim()
    && fields.orderDate
    && !((fields.billCountry === 'United States' || fields.billCountry === 'Canada') && !isTextValid(fields.billZip, regExp.zip, true))
    && !((fields.shipCountry === 'United States' || fields.shipCountry === 'Canada') && !isTextValid(fields.shipZip, regExp.zip, true))
    && isTextValid(fields.billPhone, regExp.phone)
    && isTextValid(fields.shipPhone, regExp.phone)
  );

  useEffect(() => {
    if (data) {
      setBillingSearchText(data.billEmail || '');
      setShipmentSearchText(data.shipEmail || '');
      setFields(data);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Order updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isSendEmailSuccess) {
      dispatch(showNotification({
        text: 'Customer email re-sent',
        type: 'success',
        show: true
      }));
    }
  }, [isSendEmailSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Order removed',
        type: 'success',
        show: true
      }));
      navigate(book.admin.order());
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Order"
      title={data ? `Order #${id}` : 'Order not found'}
      maxWidth="100%"
      isLoading={isLoadingData || isUpdatingData || isSendingEmail || isDeletingData}
      childrenTitleRight={data && (
        <HeaderToolBar
          guid={fields.guid}
          save={save}
          isValid={isValid()}
          sendCustomerReceiptEmail={() => sendCustomerReceiptEmail(+id!)}
          deleteOrderConfirm={deleteOrderConfirm}
        />
      )}
    >
      {data && (
        <>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={1}
            >
              <GridField label="Sales Rep">
                <Select
                  fullWidth
                  value={fields.salesRepId || -1}
                  size="small"
                  onChange={(event: SelectChangeEvent<number>) => changeCellField('salesRepId', +event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  <MenuItem
                    key="-1"
                    value="-1"
                  >
                    &nbsp;
                  </MenuItem>
                  {data && data.salesReps.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridField>
              <GridField label="Status">
                {fields.status != null && (
                  <Select
                    fullWidth
                    value={fields.status}
                    size="small"
                    onChange={(event: SelectChangeEvent<number>) => changeCellField('status', +event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    {data.orderStatuses.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </GridField>
              <GridField label="Payment Type">
                {fields.paymentType != null && (
                  <Select
                    fullWidth
                    value={fields.paymentType}
                    size="small"
                    onChange={(event: SelectChangeEvent<number>) => changeCellField('paymentType', +event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    {data.paymentTypes.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </GridField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={1}
            >
              <GridField label="Is Bogus">
                {fields.isBogus != null && (
                  <Switch
                    checked={fields.isBogus}
                    onChange={(event) => changeCellField('isBogus', event.target.checked)}
                  />
                )}
              </GridField>
              <GridField label="Order Date">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={fields.orderDate}
                    onChange={(event) => onChangeOrderDate(event)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        error={!fields.orderDate && isOrderDateModifyed}
                        helperText={!fields.orderDate && isOrderDateModifyed && 'The Order Date field is required'}
                      />
                    )}
                  />
                </LocalizationProvider>
              </GridField>
              <GridField label="User IP">
                <TextField
                  fullWidth
                  value={fields.userIp || ''}
                  size="small"
                  inputProps={{ maxLength: 20 }}
                  onChange={(val) => changeCellField('userIp', val.target.value || '')}
                />
              </GridField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={1}
            >
              <Grid
                sx={{ fontWeight: 'bold' }}
                item
                xs={12}
              >
                <Typography variant="h6">
                  Billing Info
                </Typography>
              </Grid>

              <GridField label="Email">
                <Autocomplete
                  freeSolo
                  fullWidth
                  filterOptions={filterOptions}
                  value={{ email: billingSearchText } as IBuyerAutocomplete}
                  getOptionLabel={(option) => option.email || ''}
                  options={buyerBillingData || []}
                  loading={isFetchingBuyerBillingData}
                  onChange={(event, value) => onSelectBillingInfo(value)}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'clear') {
                      onSearchBillingText(newInputValue);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.orderId}
                    >
                      <Box>
                        {`Order ID: ${option.orderId}`}
                        <br />
                        {`Email: ${option.email}`}
                        <br />
                        {`Company: ${option.company}`}
                      </Box>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!billingSearchText.trim() && isBillingSearchModifyed}
                      helperText={!billingSearchText.trim() && isBillingSearchModifyed && 'The email field is required'}
                      onChange={(event) => onSearchBillingText(event.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        inputProps: {
                          ...params.inputProps,
                          maxLength: 250
                        },
                        endAdornment: (
                          <>
                            {isFetchingBuyerBillingData ? (
                              <CircularProgress
                                color="inherit"
                                size={20}
                                sx={{ mr: 4 }}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </GridField>

              <GridField label="First Name">
                <TextField
                  fullWidth
                  value={fields.billFirstName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('billFirstName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Last Name">
                <TextField
                  fullWidth
                  value={fields.billLastName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('billLastName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Phone">
                <TextField
                  fullWidth
                  value={fields.billPhone || ''}
                  size="small"
                  inputProps={{ maxLength: 50 }}
                  error={!isTextValid(fields.billPhone, regExp.phone)}
                  helperText={!isTextValid(fields.billPhone, regExp.phone) && 'Billing phone number field is not valid'}
                  onChange={(val) => changeCellField('billPhone', val.target.value || '')}
                />
              </GridField>

              <GridField label="Company">
                <TextField
                  fullWidth
                  value={fields.billCompany || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('billCompany', val.target.value || '')}
                />
              </GridField>

              <GridField label="Billing Address">
                <TextField
                  fullWidth
                  value={fields.billAddress1 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('billAddress1', val.target.value || '')}
                />
              </GridField>

              <GridField label="Billing Address 2">
                <TextField
                  fullWidth
                  value={fields.billAddress2 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('billAddress2', val.target.value || '')}
                />
              </GridField>

              <GridField label="Country">
                <Select
                  fullWidth
                  value={fields.billCountry || ''}
                  size="small"
                  onChange={(event) => changeCellField('billCountry', event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  <MenuItem
                    key=""
                    value=""
                  >
                    &nbsp;
                  </MenuItem>
                  {data && data.countries.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.text}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridField>

              {fields.billCountry === 'United States' ? (
                <GridField label="State">
                  <Select
                    fullWidth
                    value={fields.billState || ''}
                    size="small"
                    onChange={(event) => changeCellField('billState', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.states.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>
              ) : (
                <GridField label="Province">
                  <TextField
                    fullWidth
                    value={fields.billProvince || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('billProvince', val.target.value || '')}
                  />
                </GridField>
              )}

              <GridField label="City">
                <TextField
                  fullWidth
                  value={fields.billCity || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('billCity', val.target.value || '')}
                />
              </GridField>

              <GridField label="ZIP/Postal">
                <TextField
                  fullWidth
                  value={fields.billZip || ''}
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  error={(fields.billCountry === 'United States' || fields.billCountry === 'Canada') && !isTextValid(fields.billZip, regExp.zip, true)}
                  helperText={(fields.billCountry === 'United States' || fields.billCountry === 'Canada') && !isTextValid(fields.billZip, regExp.zip, true) && 'Billing ZIP field is not valid'}
                  onChange={(val) => changeCellField('billZip', val.target.value || '')}
                />
              </GridField>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={1}
            >
              <Grid
                sx={{ fontWeight: 'bold' }}
                item
                xs={12}
              >
                <Typography variant="h6">
                  End User / Shipping Info
                  <Button
                    sx={{ height: '28px', textTransform: 'none' }}
                    onClick={copyBillingAddress}
                  >
                    Copy from billing
                  </Button>
                </Typography>
              </Grid>

              <GridField label="Email">
                <Autocomplete
                  freeSolo
                  fullWidth
                  filterOptions={filterOptions}
                  value={{ email: shipmentSearchText } as IBuyerAutocomplete}
                  getOptionLabel={(option) => option.email || ''}
                  options={buyerShipmentData || []}
                  loading={isFetchingBuyerShipmentData}
                  onChange={(event, value) => onSelectShipmentInfo(value)}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'clear') {
                      onSearchShipmentText(newInputValue);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      component="li"
                      key={option.orderId}
                    >
                      <Box>
                        {`Order ID: ${option.orderId}`}
                        <br />
                        {`Email: ${option.email}`}
                        <br />
                        {`Company: ${option.company}`}
                      </Box>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!shipmentSearchText.trim() && isShipmentSearchModifyed}
                      helperText={!shipmentSearchText.trim() && isShipmentSearchModifyed && 'The email field is required'}
                      onChange={(event) => onSearchShipmentText(event.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        inputProps: {
                          ...params.inputProps,
                          maxLength: 250
                        },
                        endAdornment: (
                          <>
                            {isFetchingBuyerShipmentData ? (
                              <CircularProgress
                                color="inherit"
                                size={20}
                                sx={{ mr: 4 }}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </GridField>

              <GridField label="First Name">
                <TextField
                  fullWidth
                  value={fields.shipFirstName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('shipFirstName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Last Name">
                <TextField
                  fullWidth
                  value={fields.shipLastName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('shipLastName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Phone">
                <TextField
                  fullWidth
                  value={fields.shipPhone || ''}
                  size="small"
                  inputProps={{ maxLength: 50 }}
                  error={!isTextValid(fields.shipPhone, regExp.phone)}
                  helperText={!isTextValid(fields.shipPhone, regExp.phone) && 'Shipping phone number field is not valid'}
                  onChange={(val) => changeCellField('shipPhone', val.target.value || '')}
                />
              </GridField>

              <GridField label="Company">
                <TextField
                  fullWidth
                  value={fields.shipCompany || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('shipCompany', val.target.value || '')}
                />
              </GridField>

              <GridField label="Shipping Address">
                <TextField
                  fullWidth
                  value={fields.shipAddress1 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('shipAddress1', val.target.value || '')}
                />
              </GridField>

              <GridField label="Shipping Address 2">
                <TextField
                  fullWidth
                  value={fields.shipAddress2 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('shipAddress2', val.target.value || '')}
                />
              </GridField>

              <GridField label="Country">
                <Select
                  fullWidth
                  value={fields.shipCountry || ''}
                  size="small"
                  onChange={(event) => changeCellField('shipCountry', event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  <MenuItem
                    key=""
                    value=""
                  >
                    &nbsp;
                  </MenuItem>
                  {data && data.countries.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.text}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridField>

              {fields.shipCountry === 'United States' ? (
                <GridField label="State">
                  <Select
                    fullWidth
                    value={fields.shipState || ''}
                    size="small"
                    onChange={(event) => changeCellField('shipState', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.states.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>
              ) : (
                <GridField label="Province">
                  <TextField
                    fullWidth
                    value={fields.shipProvince || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('shipProvince', val.target.value || '')}
                  />
                </GridField>
              )}

              <GridField label="City">
                <TextField
                  fullWidth
                  value={fields.shipCity || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeCellField('shipCity', val.target.value || '')}
                />
              </GridField>

              <GridField label="ZIP/Postal">
                <TextField
                  fullWidth
                  value={fields.shipZip || ''}
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  error={(fields.shipCountry === 'United States' || fields.shipCountry === 'Canada') && !isTextValid(fields.shipZip, regExp.zip, true)}
                  helperText={(fields.shipCountry === 'United States' || fields.shipCountry === 'Canada') && !isTextValid(fields.shipZip, regExp.zip, true) && 'Shipping ZIP field is not valid'}
                  onChange={(val) => changeCellField('shipZip', val.target.value || '')}
                />
              </GridField>
            </Grid>
          </Grid>
          <OrderProductDetails
            salesTax={data.salesTax}
            items={data.productDetails}
          />
          <OrderPayments items={data.payments} />
        </>
      )}
    </CardWrapper>
  );
};

export default Order;
