import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { TableCell, TableRow, Typography } from '@mui/material';

import { total } from '../utils/sumall';
import { ICurrentRowTotalValue, ITotal } from '../gridInterfaces';
import { currencyFormatter } from '../../../utils/currencyFormatter';

import { useStyles } from '../styles';
import { TableFooterMobileAmount } from './TableFooterMobileAmount';

interface ITableFooterMobileProps<T> extends ITotal<T>, ICurrentRowTotalValue {}

const renderValue = (val: number) => {
  if (val < 0) {
    return (
       <span style={{ color: 'black' }}>
          {currencyFormatter.format(val).replace('-', '')}
       </span>
    );
  }
  return currencyFormatter.format(val);
};
type IAmount = {
  [key: string]: number
};
const TableFooterMobile = <T extends unknown>({ data, columns, getCurrentRowTotalValue }:ITableFooterMobileProps<T>) => {
  const classes = useStyles();
  const [isAllAmounts, setIsAllAmounts] = useState<IAmount>({});
  const getAmounts = (value:IAmount) => {
    setIsAllAmounts((el) => ({
      ...el,
      ...value
    }));
  };
  const isErrorClass = ({ standardAmount, fixedAmount = null, staticClass }:{ standardAmount: number, fixedAmount: number | null, staticClass?: string }) => {
    if (fixedAmount) {
      if (fixedAmount < 0) {
        return 'black';
      }

      return 'inherit';
    }
    if (!fixedAmount && standardAmount) {
      if (standardAmount < 0) {
        return 'black';
      }
      return 'inherit';
    }
    return staticClass;
  };

  useEffect(() => {
    if (getCurrentRowTotalValue) {
      getCurrentRowTotalValue(isAllAmounts);
    }
  }, [isAllAmounts, getCurrentRowTotalValue]);

  return (
     <>
        {columns.map((col) => {
          if (col.footerProps) {
            return (
               <TableRow
                  className={classes.table_footer_mobile}
                  key={`mobile-${col.field}`}
               >
                  <TableCell><b>{col.title || col.field}</b></TableCell>
                  <TableCell align="right">
                     <TableFooterMobileAmount
                        nameCol={col.field as string}
                        setAllAmounts={() => {
                          if (col.footerProps?.showTotalAmount) {
                            getAmounts({ [col.field as string]: total(data.result, col.field as string) as number });
                          }
                        }}
                        nativeValue={!col.footerProps?.customTotalAmount
                          ? (data && total(data.result, col.field as string))
                          : Number(col.footerProps.customTotalAmount)}
                     >
                        <Typography
                           className={clsx(col.footerProps.totalClassName, {
                             [classes.value_green]: (col.footerProps.colorIndicator && data) && total(data.result, col.field as string) > 0,
                             [classes.value_black]: (col.footerProps.colorIndicator && data) && total(data.result, col.field as string) < 0
                           })}
                           variant="body1"
                           fontWeight={600}
                           color={isErrorClass({
                             fixedAmount: col.footerProps.customTotalAmount || null,
                             standardAmount: total(data.result, col.field as string),
                             staticClass: col.footerProps.color || 'black'
                           })}
                        >
                           Total:
                           {' '}
                           {!col.footerProps?.customTotalAmount && data && renderValue(total(data.result, col.field as string))}
                           {col.footerProps?.customTotalAmount && renderValue(col.footerProps.customTotalAmount)}
                        </Typography>
                     </TableFooterMobileAmount>
                  </TableCell>
               </TableRow>
            );
          }
          return null;
        })}
     </>
  );
};

export default TableFooterMobile;
