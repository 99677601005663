import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, CardWrapper, regExp } from '@monorepo/common';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { showNotification } from '../../features/ui/sliceNotification';
import GridField from '../../components/GridField';
import { useDeleteRenewalQuoteMutation, useGetRenewalQuoteQuery, useUpdateRenewalQuoteMutation } from '../../services/renewalQuote';
import { IRenewalQuoteDetails } from '../../services/renewalQuote/interfaces';
import RenewalQuoteDetails from './components/RenewalQuoteDetails';
import { useStyles } from './styles';
import ConfirmModal from '../../components/ConfirmModal';
import AdminLink from '../../components/AdminLink';
import { Permission } from '../../constants/permission';
import RenewalQuoteEmails from './components/RenewalQuoteEmails';

const RenewalQuote = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading: isLoadingData } = useGetRenewalQuoteQuery(id!);

  const [fields, setFields] = useState<IRenewalQuoteDetails>({} as IRenewalQuoteDetails);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [updateRenewalQuote, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateRenewalQuoteMutation();
  const [deleteRenewalQuote, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteRenewalQuoteMutation();

  const changeField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const copyBillingAddress = () => {
    setFields({
      ...fields,
      shipmentEmail: fields.billingEmail,
      shipmentFirstName: fields.billingFirstName,
      shipmentLastName: fields.billingLastName,
      shipmentPhone: fields.billingPhone,
      shipmentCompany: fields.billingCompany,
      shipmentAddress1: fields.billingAddress1,
      shipmentAddress2: fields.billingAddress2,
      shipmentCountry: fields.billingCountry,
      shipmentCity: fields.billingCity,
      shipmentProvince: fields.billingProvince,
      shipmentState: fields.billingState,
      shipmentZip: fields.billingZip,
    });
  };

  const onSubmit = () => {
    updateRenewalQuote({ ...fields }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      }
    });
  };

  const isTextValid = (text: string, regExpression: RegExp) => {
    const result = text?.match(regExpression);

    return result && result[0] === text;
  };

  const isValid = () => !!(
    fields.supportContractId
    && fields.billingEmail?.trim()
    && fields.shipmentEmail?.trim()
    && fields.productId1Year
    && fields.productId2Year
    && !((fields.billingCountry === 'United States' || fields.billingCountry === 'Canada') && !isTextValid(fields.billingZip, regExp.zip))
    && !((fields.shipmentCountry === 'United States' || fields.shipmentCountry === 'Canada') && !isTextValid(fields.shipmentZip, regExp.zip))
    && (fields.billingPhone === '' || isTextValid(fields.billingPhone, regExp.phone))
    && (fields.shipmentPhone === '' || isTextValid(fields.shipmentPhone, regExp.phone))
  );

  const save = () => {
    if (isValid()) {
      onSubmit();
    }
  };

  const deleteRenewalQuoteConfirm = () => {
    deleteRenewalQuote(+id!);
    setShowDeleteModal(false);
    navigate(book.admin.renewalQuote());
  };

  useEffect(() => {
    if (data) {
      setFields({ ...data, id: +id! });
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Renewal Quote updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Renewal Quote removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Renewal Quote"
      title={data ? `Renewal Quote #${id}` : 'Renewal Quote not found'}
      maxWidth="100%"
      isLoading={isLoadingData || isUpdatingData || isDeletingData}
      childrenTitleRight={data && (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Btn
            className={classes.inline_btn}
            click={save}
            disabled={!isValid()}
          >
            Save
          </Btn>
          <Btn
            className={classes.inline_btn}
            click={() => setShowDeleteModal(true)}
          >
            Delete
          </Btn>
        </Box>
      )}
    >
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteRenewalQuoteConfirm}
        title="Are you sure to delete it permanently? Will not be regenerated"
      />
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Source Order"
              labelmd={2}
              labelxs={4}
              fieldmd={4}
              fieldxs={8}
              wrap
            >
              <AdminLink
                title={data.sourceOrderId}
                url={book.admin.order(data.sourceOrderId)}
                permissions={[Permission.Orders]}
              />
            </GridField>

            <Grid
              item
              md={6}
              xs={0}
              sx={{ pl: '0 !important' }}
            />

            <GridField
              label="Support Contract"
              labelmd={2}
              labelxs={4}
              fieldmd={4}
              fieldxs={8}
            >
              <TextField
                fullWidth
                value={fields.supportContractId || ''}
                size="small"
                type="number"
                inputProps={{ maxLength: 10 }}
                error={!fields.supportContractId}
                helperText={!fields.supportContractId && 'Support Contract ID is required'}
                onChange={(val) => changeField('supportContractId', +val.target.value)}
              />
            </GridField>

            <GridField
              label="Application Name"
              labelmd={2}
              labelxs={4}
              fieldmd={4}
              fieldxs={8}
            >
              <TextField
                fullWidth
                value={fields.applicationName || ''}
                size="small"
                inputProps={{ maxLength: 250 }}
                onChange={(val) => changeField('applicationName', val.target.value || '')}
              />
            </GridField>

            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={2}
            >
              <GridField label="Contract End Date">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={fields.contractEndDate}
                    onChange={(event) => changeField('contractEndDate', event)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </GridField>

              <GridField label="Opted Out Date">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={fields.optOutDate}
                    onChange={(event) => changeField('optOutDate', event)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </GridField>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={2}
            >
              <GridField label="Additional Email 1">
                <TextField
                  fullWidth
                  value={fields.email2 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('email2', val.target.value || '')}
                />
              </GridField>

              <GridField label="Additional Email 2">
                <TextField
                  fullWidth
                  value={fields.email3 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('email3', val.target.value || '')}
                />
              </GridField>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={1}
            >
              <Grid
                sx={{ fontWeight: 'bold' }}
                item
                xs={12}
              >
                <Typography variant="h6">
                  Billing Info
                </Typography>
              </Grid>

              <GridField label="Email">
                <TextField
                  fullWidth
                  value={fields.billingEmail || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  error={!fields.billingEmail?.trim()}
                  helperText={!fields.billingEmail?.trim() && 'Email is required'}
                  onChange={(val) => changeField('billingEmail', val.target.value || '')}
                />
              </GridField>

              <GridField label="First Name">
                <TextField
                  fullWidth
                  value={fields.billingFirstName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('billingFirstName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Last Name">
                <TextField
                  fullWidth
                  value={fields.billingLastName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('billingLastName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Phone">
                <TextField
                  fullWidth
                  value={fields.billingPhone || ''}
                  size="small"
                  inputProps={{ maxLength: 50 }}
                  error={fields.billingPhone !== '' && !isTextValid(fields.billingPhone, regExp.phone)}
                  helperText={fields.billingPhone !== '' && !isTextValid(fields.billingPhone, regExp.phone) && 'Billing phone number field is not valid'}
                  onChange={(val) => changeField('billingPhone', val.target.value || '')}
                />
              </GridField>

              <GridField label="Company">
                <TextField
                  fullWidth
                  value={fields.billingCompany || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('billingCompany', val.target.value || '')}
                />
              </GridField>

              <GridField label="Billing Address">
                <TextField
                  fullWidth
                  value={fields.billingAddress1 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('billingAddress1', val.target.value || '')}
                />
              </GridField>

              <GridField label="Billing Address 2">
                <TextField
                  fullWidth
                  value={fields.billingAddress2 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('billingAddress2', val.target.value || '')}
                />
              </GridField>

              <GridField label="Country">
                <Select
                  fullWidth
                  value={fields.billingCountry || ''}
                  size="small"
                  onChange={(event) => changeField('billingCountry', event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  <MenuItem
                    key=""
                    value=""
                  >
                    &nbsp;
                  </MenuItem>
                  {data && data.countries.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.text}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridField>

              {fields.billingCountry === 'United States' ? (
                <GridField label="State">
                  <Select
                    fullWidth
                    value={fields.billingState || ''}
                    size="small"
                    onChange={(event) => changeField('billingState', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.states.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>
              ) : (
                <GridField label="Province">
                  <TextField
                    fullWidth
                    value={fields.billingProvince || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeField('billingProvince', val.target.value || '')}
                  />
                </GridField>
              )}

              <GridField label="City">
                <TextField
                  fullWidth
                  value={fields.billingCity || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('billingCity', val.target.value || '')}
                />
              </GridField>

              <GridField label="ZIP/Postal">
                <TextField
                  fullWidth
                  value={fields.billingZip || ''}
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  error={(fields.billingCountry === 'United States' || fields.billingCountry === 'Canada') && !isTextValid(fields.billingZip, regExp.zip)}
                  helperText={(fields.billingCountry === 'United States' || fields.billingCountry === 'Canada') && !isTextValid(fields.billingZip, regExp.zip) && 'Billing ZIP field is not valid'}
                  onChange={(val) => changeField('billingZip', val.target.value || '')}
                />
              </GridField>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              container
              spacing={1}
            >
              <Grid
                sx={{ fontWeight: 'bold' }}
                item
                xs={12}
              >
                <Typography variant="h6">
                  End User / Shipping Info
                  <Button
                    sx={{ height: '28px', textTransform: 'none' }}
                    onClick={copyBillingAddress}
                  >
                    Copy from billing
                  </Button>
                </Typography>
              </Grid>

              <GridField label="Email">
                <TextField
                  fullWidth
                  value={fields.shipmentEmail || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  error={!fields.shipmentEmail?.trim()}
                  helperText={!fields.shipmentEmail?.trim() && 'Email is required'}
                  onChange={(val) => changeField('shipmentEmail', val.target.value || '')}
                />
              </GridField>

              <GridField label="First Name">
                <TextField
                  fullWidth
                  value={fields.shipmentFirstName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('shipmentFirstName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Last Name">
                <TextField
                  fullWidth
                  value={fields.shipmentLastName || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('shipmentLastName', val.target.value || '')}
                />
              </GridField>

              <GridField label="Phone">
                <TextField
                  fullWidth
                  value={fields.shipmentPhone || ''}
                  size="small"
                  inputProps={{ maxLength: 50 }}
                  error={fields.shipmentPhone !== '' && !isTextValid(fields.shipmentPhone, regExp.phone)}
                  helperText={fields.shipmentPhone !== '' && !isTextValid(fields.shipmentPhone, regExp.phone) && 'Shipping phone number field is not valid'}
                  onChange={(val) => changeField('shipmentPhone', val.target.value || '')}
                />
              </GridField>

              <GridField label="Company">
                <TextField
                  fullWidth
                  value={fields.shipmentCompany || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('shipmentCompany', val.target.value || '')}
                />
              </GridField>

              <GridField label="Shipping Address">
                <TextField
                  fullWidth
                  value={fields.shipmentAddress1 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('shipmentAddress1', val.target.value || '')}
                />
              </GridField>

              <GridField label="Shipping Address 2">
                <TextField
                  fullWidth
                  value={fields.shipmentAddress2 || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('shipmentAddress2', val.target.value || '')}
                />
              </GridField>

              <GridField label="Country">
                <Select
                  fullWidth
                  value={fields.shipmentCountry || ''}
                  size="small"
                  onChange={(event) => changeField('shipmentCountry', event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  <MenuItem
                    key=""
                    value=""
                  >
                    &nbsp;
                  </MenuItem>
                  {data && data.countries.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.text}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridField>

              {fields.shipmentCountry === 'United States' ? (
                <GridField label="State">
                  <Select
                    fullWidth
                    value={fields.shipmentState || ''}
                    size="small"
                    onChange={(event) => changeField('shipmentState', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.states.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>
              ) : (
                <GridField label="Province">
                  <TextField
                    fullWidth
                    value={fields.shipmentProvince || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeField('shipmentProvince', val.target.value || '')}
                  />
                </GridField>
              )}

              <GridField label="City">
                <TextField
                  fullWidth
                  value={fields.shipmentCity || ''}
                  size="small"
                  inputProps={{ maxLength: 250 }}
                  onChange={(val) => changeField('shipmentCity', val.target.value || '')}
                />
              </GridField>

              <GridField label="ZIP/Postal">
                <TextField
                  fullWidth
                  value={fields.shipmentZip || ''}
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  error={(fields.shipmentCountry === 'United States' || fields.shipmentCountry === 'Canada') && !isTextValid(fields.shipmentZip, regExp.zip)}
                  helperText={(fields.shipmentCountry === 'United States' || fields.shipmentCountry === 'Canada') && !isTextValid(fields.shipmentZip, regExp.zip) && 'Shipping ZIP field is not valid'}
                  onChange={(val) => changeField('shipmentZip', val.target.value || '')}
                />
              </GridField>
            </Grid>
          </Grid>
          <RenewalQuoteDetails
            items={[
              {
                id: 1,
                productId: data.productId1Year,
                partNumber: data.partNumber1Year,
                productQty: fields.productQty1Year,
                priceEarly: fields.price1YearEarly,
                price: fields.price1Year,
                quoteIdEarly: fields.quoteId1YearEarly,
                quoteId: fields.quoteId1Year,
              },
              {
                id: 2,
                productId: data.productId2Year,
                partNumber: data.partNumber2Year,
                productQty: fields.productQty2Year,
                priceEarly: fields.price2YearEarly,
                price: fields.price2Year,
                quoteIdEarly: fields.quoteId2YearEarly,
                quoteId: fields.quoteId2Year,
              }
            ]}
            changeCellField={changeField}
          />
          <RenewalQuoteEmails items={fields?.emails || []} />
        </Box>
      )}
    </CardWrapper>
  );
};

export default RenewalQuote;
