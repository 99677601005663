import { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { book, TableSearch } from '@monorepo/common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SendIcon from '@mui/icons-material/Send';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  startDate: Date | null,
  endDate: Date | null,
  handleStartDateChange: (event: any) => void,
  handleEndDateChange: (event: any) => void,
}

const TableToolBar = ({ onSearch, startDate, endDate, handleStartDateChange, handleEndDateChange }: ITableToolBarProps) => {
  const navigate = useNavigate();
  const [renewalQuoteId, setRenewalQuoteId] = useState<string>('');

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <TextField
          fullWidth
          label="Renewal Quote ID"
          value={renewalQuoteId}
          onChange={(event) => setRenewalQuoteId(event.target.value)}
          InputProps={{
            endAdornment: (
              <Button onClick={() => navigate(book.admin.renewalQuote(+renewalQuoteId))}>
                <SendIcon />
              </Button>
            )
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
