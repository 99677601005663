import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  date_range_picker_wrapper: {
    margin: 'auto',
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
    WebkitBoxAlign: 'baseline',
    '& > div': {
      display: 'inline-flex',
    }
  },
}));
