import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'debounce';
import { Box, Table } from '@mui/material';
import clsx from 'clsx';
import TableSearch from '../TableSearch';
import TablePagination from '../TablePagination';
import EmptyTable from './components/EmptyTable';
import Loader from '../Loader';
import { scrollUp } from '../../utils/scrollUp';
import {
  ColSortDirections,
  IApiProps,
  IDefaultValueApiRequestProps,
  ITableProps, ITablePropsPositionElement,
} from './gridInterfaces';
import GridDesktop from './components/GridDesktop';
import GridMobile from './components/GridMobile';
import { useStyles } from './styles';
import {
  defaultApiValues,
  defaultSearchableProps,
  defaultStyleEffects,
  defaultTableProps,
  defaultValueApiRequest,
} from './defaultValues';
import { internalRequest } from './utils/internalRequeat';
import { paginatedResults } from './utils/pagination';
import { localTotal } from './utils/localTotal';
import { filterByText } from './utils/filterByText';
import { sortData } from './utils/sortData';
import { updateDirectionValue } from './utils/updateDirectionValue';
import { Scrollbar } from '../../utils/scrollbar';

export const DataGrid = <T extends unknown>({
  columns,
  collapseProps,
  styleProps,

  options,

  tableProps,

  events,
  children,
  searchableProps,
  pageable = true,
  keyExtractor,
  tableHeadComponent,
  sortable = false,
  globalId = null,
  apiProps,
  isLoadingData = false,

  defaultGridProps,

  toolBarComponent,
  getCurrentRowTotalValue
}: ITableProps<T>) => {
  const classes = useStyles();
  // ---------------------------------------------------- Implements default data with new data from outside
  // SearchableProps
  const { changeSearchField, showSearchField, renderCustomSearchField, placeholder } = {
    ...defaultSearchableProps,
    ...searchableProps,
  };
  const { api, apiMethodName, apiParams, isSkip, responseData, getRequestParams, externalData, isServerOperation, getExternalFilteredData }: IApiProps<T> = {
    ...defaultApiValues as IApiProps<T>,
    ...apiProps
  };
  const { defaultSortBy, defaultSortDirection, defaultPageSize, scrollUpAfterRequest } = {
    ...defaultValueApiRequest as IDefaultValueApiRequestProps<T>,
    ...defaultGridProps
  };
  const initialTableProps: ITablePropsPositionElement = {
    ...defaultTableProps,
    ...tableProps
  };
  // ----------------------------------------------------------------------------------------------------------------
  const [internalData, setInternalData] = useState<{ total: number; result: T[] } | null>(null);
  const [internalLoading, setInternalLoading] = useState<boolean>(false);

  const [isFirstInit, setIsFirstInit] = useState<boolean>(true);
  const [prevApiParams, setPrevApiParams] = useState<string | null>(null);

  const [colSortBy, setColSortBy] = useState<number>(0);
  const [activeCol, setActiveCol] = useState<keyof T | null>(defaultSortBy as keyof T | null);
  const [activeColDirection, setActiveColDirection] = useState<ColSortDirections>(defaultSortDirection as ColSortDirections);
  const [searchText, setSearchText] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(defaultPageSize as number);

  const [isVisibleTableFooter, setIsVisibleTableFooter] = useState<boolean>(false);

  // URL params for request
  const requestUrlPrams = useMemo(() => ({
    page,
    pageSize,
    ...(showSearchField && searchText.length) && { searchText },
    ...(sortable && activeCol) && { sortBy: activeCol },
    ...(sortable && activeColDirection) && { sortingOrder: activeColDirection }
  }), [searchText, sortable, pageSize, page, activeColDirection, activeCol]);

  const customApiRequestProps = () => {
    if (typeof apiParams === 'object') {
      return {
        page: searchText.length ? 1 : page,
        pageSize,
        ...(sortable) && {
          ...(activeCol) && { sortBy: activeCol },
          ...(activeColDirection) && { sortingOrder: activeColDirection }
        },
        ...(apiParams && typeof apiParams === 'object') && {
          ...apiParams
        }
      };
    }
    return apiParams;
  };

  // ______________________________________ RTQ Request Function
  const { data, isFetching, getDataForGrid } = internalRequest({
    api,
    apiMethodName
  });

  const changePagination = (p: number) => {
    setPage(p);
    if (!isServerOperation) {
      if (externalData && internalData) {
        setInternalData({
          total: internalData.total,
          result: paginatedResults<T>({
            data: externalData.result,
            pageSize,
            page: p
          })
        });
      }
    }
  };

  const filteringData = ({ colName, direction }: { colName: keyof T | null; direction: number }) => {
    setPage(1);
    if (!isServerOperation) {
      if (externalData) {
        sortData<T>({
          activeColName: colName,
          setInternalData,
          pageSize,
          incomingData: externalData.result,
          direction,
          searchText,
        });
      }
    }
  };

  const onChangeSearchField = useMemo(() => debounce((value: string) => {
    setPage(1);
    setSearchText(value.trim());
  }, 300), []);

  const getCurrentCol = (currentActiveCol: keyof T) => {
    if (currentActiveCol === activeCol) {
      setColSortBy(colSortBy < 2 ? (colSortBy + 1) : 0);
      setActiveCol(currentActiveCol);
      return;
    }
    setColSortBy(1);
    setActiveCol(currentActiveCol);
  };

  // change after click
  useEffect(() => {
    updateDirectionValue<T>({
      isFirstInit,
      setIsFirstInit,
      colSortBy,
      setActiveCol,
      setActiveColDirection
    });
  }, [colSortBy]);

  useEffect(() => {
    filteringData({
      colName: activeCol,
      direction: colSortBy
    });
  }, [colSortBy, activeCol]);

  // ----------------------------------     for internal request
  useEffect(() => {
    if (!isSkip && (!externalData) && getDataForGrid) {
      if (!apiParams) {
        // REQUEST TO SERVER
        getDataForGrid(requestUrlPrams);
        return;
      }
      if (JSON.stringify(customApiRequestProps()) !== prevApiParams) {
        setPrevApiParams(JSON.stringify(customApiRequestProps()));
        getDataForGrid(customApiRequestProps());
      }
    }
  }, [requestUrlPrams, isSkip, apiParams]);

  // ----------------------------------     for external request
  useEffect(() => {
    if (getRequestParams) {
      if (!apiParams) {
        getRequestParams(requestUrlPrams);
        return;
      }
      getRequestParams(customApiRequestProps() as IDefaultValueApiRequestProps<T>);
    }
  }, [requestUrlPrams, getRequestParams, apiParams]);

  // watch for the search field
  useEffect(() => {
    if (changeSearchField) {
      changeSearchField(searchText);
    }
    if (!isServerOperation && externalData) {
      const { total, result } = filterByText({ data: externalData.result, searchText });
      setInternalData({
        total,
        result: paginatedResults<T>({
          pageSize,
          page: 1,
          data: result
        })
      });
    }
  }, [searchText, changeSearchField, externalData]);

  // ___________________________________________________________________     get result  api after INSIDE REQUEST
  useEffect(() => {
    if (!isFetching) {
      if (data) setInternalData(data);

      if (scrollUpAfterRequest) scrollUp();
    }
  }, [data, isFetching, scrollUpAfterRequest]);

  // // ---------------------------------------------------------------------  Manage local data
  // save data in local state
  useEffect(() => {
    if (externalData) {
      if (isServerOperation) {
        setInternalData(externalData);
        return;
      }

      setInternalData({
        total: externalData.total,
        result: paginatedResults<T>({
          data: externalData.result,
          pageSize,
          page
        })
      });
    }
  }, [externalData]);

  useEffect(() => {
    if (externalData && getExternalFilteredData) {
      getExternalFilteredData(sortData<T>({
        activeColName: activeCol,
        pageSize,
        incomingData: externalData.result,
        direction: colSortBy,
        searchText,
      }));
    }
  }, [externalData, colSortBy, activeCol]);

  // _________________________________________ delay for Loader
  useEffect(() => {
    if (isFetching) {
      setInternalLoading(true);
      return;
    }
    const timeout = setTimeout(() => {
      setInternalLoading(false);
      clearTimeout(timeout);
    }, 1500);
  }, [isFetching]);

  useEffect(() => {
    if (internalData && responseData) {
      responseData(internalData.result);
    }
  }, [internalData]);

  // __________________________________________ SHOW/HIDE footer
  useEffect(() => {
    const result = columns.filter((item) => item.footerProps);
    if (result.length) {
      setIsVisibleTableFooter(true);
      return;
    }
    setIsVisibleTableFooter(false);
  }, [columns]);

  useEffect(() => {
    if (options?.exportToExel?.export) {
      console.log(columns);
      console.log('~~~~~~~~~~~~~~~~~~~~~~~ isExport', options?.exportToExel?.export);
    }
  }, [options, columns]);

  return (
    <>
      <Loader isShow={internalLoading || isLoadingData} />
      {/* ------------------------------------ Search component - standard or custom component     ----------------------------------------- */}
      {showSearchField && !renderCustomSearchField && (
        <Box sx={{ m: '34px 24px' }}>
          <TableSearch
            change={onChangeSearchField}
            className={styleProps?.classNameForTableSearch}
            placeholder={placeholder}
          />
        </Box>
      )}

      {(!showSearchField && renderCustomSearchField) && renderCustomSearchField}

      {/* For custom component  */}
      {toolBarComponent && toolBarComponent}

      <Scrollbar>
        <Table
          id="qwerty"
          className={clsx(styleProps?.classNameForTable)}
        >
          {tableHeadComponent && tableHeadComponent}
          {/* ---------------------------------------------------  Attention!! The next two MediaQuery have separate lists   -------------------------   */}
          {/* -------------------------------------------- Desktop mode */}
          <GridDesktop<T>
            configureDataForExel={!!options?.exportToExel}
            collapseProps={collapseProps}
            events={events}
            columns={columns}
            globalId={globalId}
            sortable={sortable}
            activeCol={activeCol}
            styleProps={{
              ...defaultStyleEffects,
              ...styleProps
            }}
            keyExtractor={keyExtractor}
            data={internalData || null}
            getCurrentCol={getCurrentCol}
            tableProps={initialTableProps}
            activeColDirection={activeColDirection}
            tableHeadComponent={tableHeadComponent}
            isVisibleTableFooter={isVisibleTableFooter}
            getCurrentRowTotalValue={getCurrentRowTotalValue}
          >
            {children}
          </GridDesktop>

          {/* -------------------------------------------- Mobile mode */}
          <GridMobile
            columns={columns}
            globalId={globalId}
            events={events}
            sortable={sortable}
            data={internalData}
            activeCol={activeCol}
            styleProps={{
              ...defaultStyleEffects,
              ...styleProps
            }}
            getCurrentCol={getCurrentCol}
            tableProps={initialTableProps}
            collapseProps={collapseProps}
            activeColDirection={activeColDirection}
            isVisibleTableFooter={isVisibleTableFooter}
            getCurrentRowTotalValue={getCurrentRowTotalValue}
          >
            {children}
          </GridMobile>
        </Table>
      </Scrollbar>

      {internalData?.result && internalData.result.length === 0 && <EmptyTable />}

      {/* Show/Hide Pagination component */}
      {pageable && !!internalData?.result?.length && (
        <TablePagination
          classNameForWrap={clsx({
            [classes.pagination_empty]: internalData?.result && internalData.result.length === 0
          })}
          pageSize={pageSize}
          change={changePagination}
          currentPage={page}
          total={localTotal({ internalData })}
        />
      )}
    </>
  );
};

export default DataGrid;
