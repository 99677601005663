import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IRelease, IReleaseDetails, IReleaseListRequest } from './interfaces';

export const releaseApi = createApi({
  reducerPath: 'releaseApi',
  tagTypes: ['release'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getReleases: build.query<IListResp<IRelease>, IReleaseListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        applicationId,
      }) => ({
        url: endpoints.releases,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          applicationId,
        }
      }),
      providesTags: () => ['release']
    }),
    getRelease: build.query<IReleaseDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.release}/${id}`,
      }),
    }),
    createRelease: build.mutation<number, IReleaseDetails>({
      query: ({
        applicationId,
        version,
        fileName,
        password,
        releaseNotes,
        comments,
        isActive,
        releaseDate,
      }) => ({
        url: endpoints.releaseCreate,
        method: 'POST',
        body: {
          applicationId,
          version,
          fileName,
          password,
          releaseNotes,
          comments,
          isActive,
          releaseDate,
        }
      }),
      invalidatesTags: ['release'],
    }),
    updateRelease: build.mutation<IReleaseDetails, IReleaseDetails>({
      query: ({
        id,
        applicationId,
        isActive,
        version,
        fileName,
        password,
        releaseDate,
        releaseNotes,
        comments,
      }) => ({
        url: `${endpoints.releaseEdit}/${id}`,
        method: 'PATCH',
        body: {
          applicationId,
          isActive,
          version,
          fileName,
          password,
          releaseDate,
          releaseNotes,
          comments,
        }
      }),
      invalidatesTags: ['release'],
    }),
    deleteRelease: build.mutation<IReleaseDetails, number>({
      query: (id: number) => ({
        url: `${endpoints.releaseDelete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['release'],
    }),
  }),
});

export const {
  useLazyGetReleasesQuery,
  useGetReleaseQuery,
  useCreateReleaseMutation,
  useUpdateReleaseMutation,
  useDeleteReleaseMutation,
} = releaseApi;
