import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListRequest, IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IActiveContractMailing, IMostRecentOrder, IMostRecentOrdersReq, ISalesByProductGroups, ISalesReq, ITotalSalesByProductGroup } from './interfaces';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  tagTypes: ['report'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getActiveContractMailingList: build.query<IListResp<IActiveContractMailing>, IListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy
      }) => ({
        url: endpoints.activeContractMailingList,
        params: { pageSize, page, searchText, sortingOrder, sortBy }
      }),
    }),
    getSalesByProductGroups: build.query<ISalesByProductGroups, string>({
      query: () => ({
        url: endpoints.salesByProductGroups
      }),
    }),
    getTotalSalesByProductGroups: build.query<IListResp<ITotalSalesByProductGroup>, ISalesReq>({
      query: ({ startDate, endDate }) => ({
        url: endpoints.totalSalesByProductGroups,
        params: { startDate, endDate }
      }),
    }),
    getMostRecentOrdersList: build.query<IListResp<IMostRecentOrder>, IMostRecentOrdersReq>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        startDate,
        endDate,
        salesReportGroupId
      }) => ({
        url: endpoints.mostRecentOrdersList,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          startDate,
          endDate,
          salesReportGroupId
        }
      }),
    }),
  }),
});

export const {
  useLazyGetActiveContractMailingListQuery,
  useGetSalesByProductGroupsQuery,
  useLazyGetTotalSalesByProductGroupsQuery,
  useLazyGetMostRecentOrdersListQuery
} = reportApi;
