import { book, Btn, CardWrapper, DataGrid } from '@monorepo/common';
import { salesRepApi } from '../../services/salesRep';
import { ISalesRep } from '../../services/salesRep/interfaces';
import AdminLink from '../../components/AdminLink';
import { Permission } from '../../constants/permission';

const SalesReps = () => (
  <CardWrapper
    titleCenter
    centerText
    helmet="Sales Reps"
    title="Sales Reps"
    maxWidth="100%"
    childrenTitleRight={(
      <Btn link={`${book.admin.salesRep()}/new`}>
        Create
      </Btn>
    )}
  >
    <DataGrid<ISalesRep>
      keyExtractor={(col) => col.id.toString()}
      sortable
      styleProps={{
        isSelectedEven: true
      }}
      defaultGridProps={{
        defaultSortBy: 'id',
        defaultSortDirection: 'desc',
        defaultPageSize: 25
      }}
      columns={[
        {
          field: 'id',
          title: 'ID',
          template: (col) => (
            <AdminLink
              url={book.admin.salesRep(col.id)}
              title={col.id}
            />
          )
        },
        {
          field: 'adminUserId',
          title: 'Admin User',
          hideCurrentSortable: true,
          template: (col) => (
            col.adminUserId && (
              <AdminLink
                url={book.admin.adminUser(col.adminUserId)}
                title={col.adminUserName}
                permissions={[Permission.AdminUsers]}
              />
            )
          )
        },
        {
          field: 'name',
          title: 'Name',
        },
        {
          field: 'email',
          title: 'Email',
        },
        {
          field: 'phoneNumber',
          title: 'Phone number',
          hideCurrentSortable: true,
        },
        {
          field: 'initials',
          title: 'Initials',
          hideCurrentSortable: true,
        },
      ]}
      apiProps={{
        api: salesRepApi,
        apiMethodName: 'useLazyGetSalesRepsQuery',
      }}
    />
  </CardWrapper>
);

export default SalesReps;
