import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userProfileInitialState, IUserProfileReducer } from './userProfileInitialState';

export const slice = createSlice({
  name: 'userProfile',
  initialState: userProfileInitialState,
  reducers: {
    fillProfile: (state:IUserProfileReducer, action:PayloadAction<IUserProfileReducer>): void => {
      Object.assign(state, { ...action.payload });
    }
  },
});

export const { fillProfile } = slice.actions;
export default slice.reducer;
