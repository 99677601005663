import { useState } from 'react';
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import { TableSearch } from '@monorepo/common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ISelectListItem } from '../../../interfaces';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  startDate: Date | null,
  endDate: Date | null,
  handleEndDateChange: (event: any) => void,
  handleStartDateChange: (event: any) => void,
  setIsActive: (a: boolean | null) => void,
  setIsTrial: (a: boolean | null) => void,
}

const TableToolBar = ({ onSearch, startDate, endDate, handleStartDateChange, handleEndDateChange, setIsActive, setIsTrial }: ITableToolBarProps) => {
  const [typeIds, setTypeIds] = useState<string[]>([]);

  const accountTypes = [{ value: '0', text: 'Paid' }, { value: '1', text: 'Trial' }, { value: '2', text: 'Active' }, { value: '3', text: 'Inactive' }] as ISelectListItem[];

  const onTypesChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    setTypeIds(value);

    if (value.includes(accountTypes[0].value) && !value.includes(accountTypes[1].value)) {
      setIsTrial(false);
    } else if (value.includes(accountTypes[1].value) && !value.includes(accountTypes[0].value)) {
      setIsTrial(true);
    } else {
      setIsTrial(null);
    }

    if (value.includes(accountTypes[2].value) && !value.includes(accountTypes[3].value)) {
      setIsActive(true);
    } else if (value.includes(accountTypes[3].value) && !value.includes(accountTypes[2].value)) {
      setIsActive(false);
    } else {
      setIsActive(null);
    }
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <FormControl fullWidth>
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            labelId="type-select-label"
            multiple
            value={typeIds}
            label="Type"
            onChange={onTypesChange}
            sx={{ textAlign: 'left' }}
            input={<OutlinedInput label="Type" />}
            renderValue={(selected) => selected.map((typeId) => accountTypes.find((t) => typeId === t.value)?.text).join(', ')}
          >
            {accountTypes.map((item: ISelectListItem) => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                <Checkbox checked={typeIds.includes(item.value)} />
                <ListItemText primary={item.text} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
