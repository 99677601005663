import { Typography, Toolbar } from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@monorepo/common';
import { IGridDataResponse } from '@monorepo/common/src/components/DataGrid/gridInterfaces';
import { IRenewalQuoteEmailQueue } from '../../../services/renewalQuote/interfaces';

interface RenewalQuoteEmailsProps {
  items: IRenewalQuoteEmailQueue[],
}

const RenewalQuoteEmails = ({ items }: RenewalQuoteEmailsProps) => (
  <>
    <Toolbar sx={{ mt: 2 }}>
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        component="div"
      >
        Emails
      </Typography>
    </Toolbar>
    <DataGrid<IRenewalQuoteEmailQueue>
      keyExtractor={(col) => col.email + col.processedDate?.toString()}
      sortable
      styleProps={{
        isSelectedEven: true
      }}
      searchableProps={{
        showSearchField: false,
      }}
      columns={[
        {
          field: 'email',
          title: 'Email',
          hideCurrentSortable: true,
        },
        {
          field: 'ccEmail',
          title: 'CC Email',
          hideCurrentSortable: true,
        },
        {
          field: 'type',
          title: 'Type',
          hideCurrentSortable: true,
        },
        {
          field: 'status',
          title: 'Status',
          hideCurrentSortable: true,
        },
        {
          field: 'processedDate',
          title: 'Processed date',
          template: (col) => <>{col.processedDate ? moment(col.processedDate).format('YYYY-MM-DD HH:mm') : '-'}</>,
        }
      ]}
      apiProps={{
        isServerOperation: false,
        externalData: {
          total: items.length,
          result: items,
        } as IGridDataResponse<IRenewalQuoteEmailQueue>,
      }}
    />
  </>
);

export default RenewalQuoteEmails;
