import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp, ISelectListItem } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { ILicense, ILicenseDetails, ILicenseLimitRequest, ILicenseListRequest, ILicensesBase } from './interfaces';

export const licenseApi = createApi({
  reducerPath: 'licenseApi',
  tagTypes: ['license'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getLicensesBase: build.query<ILicensesBase, string>({
      query: () => ({
        url: endpoints.licensesBase,
      }),
    }),
    getLicenses: build.query<IListResp<ILicense>, ILicenseListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        applicationId
      }) => ({
        url: endpoints.licenses,
        params: { pageSize, page, searchText, sortingOrder, sortBy, applicationId }
      }),
    }),
    getLicense: build.query<ILicenseDetails, string>({
      query: (serialNumber: string) => ({
        url: `${endpoints.license}/${serialNumber}`,
      }),
    }),
    getLicenseLimitTypes: build.query<ISelectListItem[], string>({
      query: () => ({
        url: endpoints.licenseLimitTypes
      }),
    }),
    getLicenseHash: build.query<string, string>({
      query: (date) => ({
        url: endpoints.licenseHash,
        params: { date }
      }),
    }),
    updateLicenseLimit: build.mutation<string, ILicenseLimitRequest>({
      query: ({ serialNumber, id, type, value }) => ({
        url: `${endpoints.licenseEditLimit}/${serialNumber}`,
        method: 'PUT',
        body: {
          id,
          type,
          value
        }
      }),
    }),
    deleteLicenseLimit: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.licenseDeleteLimit}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetLicensesBaseQuery,
  useLazyGetLicensesQuery,
  useGetLicenseQuery,
  useGetLicenseLimitTypesQuery,
  useGetLicenseHashQuery,
  useUpdateLicenseLimitMutation,
  useDeleteLicenseLimitMutation
} = licenseApi;
