import React from 'react';
import clsx from 'clsx';
import { Card } from '@material-ui/core';
import { useStyles } from '../styles';
import { IChildrenProps } from '../../../interfaces';

interface ICardWrapperInternalWrapperProps extends IChildrenProps {
  centerText?: boolean,
  noOverflow?: boolean,
  fullWidth?: boolean,
  lateralPadding?: boolean,
  classNameForInternalBlock?: string,
  classnameForWrapper?: string,
  internalStyles: any
}
const CardWrapperInternalWrapper = ({ internalStyles, classNameForInternalBlock, classnameForWrapper, lateralPadding, centerText, noOverflow, fullWidth, children }:ICardWrapperInternalWrapperProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.wrapper, classnameForWrapper)}>
      <Card
        className={clsx(classes.internal_block, classNameForInternalBlock, {
          [classes.center]: centerText,
          [classes.noOverflow]: noOverflow,
          [classes.fullWidth]: fullWidth,
          [classes.lateral_padding]: lateralPadding,
        })}
        {...internalStyles}
      >
        {children}
      </Card>
    </div>
  );
};

export default CardWrapperInternalWrapper;
