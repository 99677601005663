import { book, Scrollbar } from '@monorepo/common';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useStyles } from '../styles';
import { Permission } from '../../../constants/permission';
import AdminLink from '../../../components/AdminLink';
import { ISupportContractLicense } from '../../../services/supportContract/interfaces';

interface ISupportContractLicensesProps {
  items: ISupportContractLicense[],
}

const SupportContractLicenses = ({ items }: ISupportContractLicensesProps) => {
  const classes = useStyles();

  return (
    <>
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Licenses
        </Typography>
      </Toolbar>
      <TableContainer component={Paper}>
        <Scrollbar>
          <Table className={classes.striped_table}>
            <TableHead className={classes.table_head}>
              <TableRow>
                <TableCell>Serial Number</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>Is Active</TableCell>
                <TableCell>Create Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((license: ISupportContractLicense) => (
                <TableRow
                  key={license.serialNumber}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <AdminLink
                      url={book.admin.license(license.serialNumber)}
                      title={license.serialNumber}
                      permissions={[Permission.Licenses]}
                    />
                  </TableCell>
                  <TableCell>
                    <AdminLink
                      url={book.admin.user(license.userId)}
                      title={license.userId}
                      permissions={[Permission.Users]}
                    />
                  </TableCell>
                  <TableCell>
                    <AdminLink
                      url={book.admin.order(license.orderId)}
                      title={license.orderId}
                      permissions={[Permission.Orders]}
                    />
                  </TableCell>
                  <TableCell>
                    {license.isActive ? <CheckIcon /> : ''}
                  </TableCell>
                  <TableCell>
                    {license.createDate}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
};

export default SupportContractLicenses;
