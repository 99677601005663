import { Scrollbar } from '@monorepo/common';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { currencyFormatter } from '@monorepo/common/src/utils/currencyFormatter';
import moment from 'moment';
import { useStyles } from '../styles';
import { IOrderPayments } from '../../../services/order/interfaces';

interface IOrderPaymentsProps {
  items: IOrderPayments[],
}

const OrderPayments = ({ items }: IOrderPaymentsProps) => {
  const classes = useStyles();

  return (
    <>
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Payments
        </Typography>
      </Toolbar>
      <TableContainer component={Paper}>
        <Scrollbar>
          <Table className={classes.striped_table}>
            <TableHead className={classes.table_head}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Auth Code</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>AVSAddress Code</TableCell>
                <TableCell>AVSZip Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((payment: IOrderPayments) => (
                <TableRow
                  key={payment.paymentId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {payment.paymentId}
                  </TableCell>
                  <TableCell>
                    {payment.paymentDate && moment(payment.paymentDate).format('YYYY-MM-DD')}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(payment.paymentAmount)}
                  </TableCell>
                  <TableCell>
                    {payment.paymentType}
                  </TableCell>
                  <TableCell>
                    {payment.authCode}
                  </TableCell>
                  <TableCell>
                    {payment.transactionId}
                  </TableCell>
                  <TableCell>
                    {payment.avsAddressCode}
                  </TableCell>
                  <TableCell>
                    {payment.avsZipCode}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
};

export default OrderPayments;
