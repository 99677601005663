import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';

interface IPost {
  Email: string,
  Password: string
}
interface IGooglePost {
  JwtFromGoogle: string
}
interface IResp {
  access_token: string,
  refresh_token: string,
}

export const loginApi = createApi({
  reducerPath: 'loginApi',
  tagTypes: ['login'],
  baseQuery: fetchBaseQuery({
    baseUrl: endpoints.dashboardUrl,
  }),
  endpoints: (build) => ({
    post: build.mutation<IResp, IPost>({
      query: ({
        Password, Email
      }) => ({
        url: endpoints.login,
        method: 'POST',
        body: {
          Password, Email
        }
      }),
    }),
    googlePost: build.mutation<IResp, IGooglePost>({
      query: ({
        JwtFromGoogle
      }) => ({
        url: endpoints.googleLogin,
        method: 'POST',
        body: {
          JwtFromGoogle
        }
      }),
    }),
  }),
});
