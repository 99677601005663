import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Btn, Loader } from '@monorepo/common';
import { IApplicationProductMap, IApplicationProductMapItem } from '../../../services/application/interfaces';
import { useCreateApplicationMaintMapMutation, useDeleteApplicationMaintMapMutation, useUpdateApplicationMaintMapMutation } from '../../../services/application';
import { ISelectListItem } from '../../../interfaces';
import ConfirmModal from '../../../components/ConfirmModal';
import { showNotification } from '../../../features/ui/sliceNotification';
import { useStyles } from '../styles';

interface IApplicationMaintenancesProps {
  applicationId: number,
  applicationName: string,
  items: IApplicationProductMapItem[],
  applications: ISelectListItem[],
  products: ISelectListItem[],
}

const ApplicationMaintenances = ({ applicationId, applicationName, items, applications, products }: IApplicationMaintenancesProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [fields, setFields] = useState({} as IApplicationProductMap);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [createMaintMap, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateApplicationMaintMapMutation();
  const [updateMaintMap, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateApplicationMaintMapMutation();
  const [deleteMaintMap, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteApplicationMaintMapMutation();

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const addMaintenance = () => {
    setActiveRow(0);
    setFields({
      applicationId,
      productId: +products[0].value,
    });
  };

  const editMaintenance = (index: number) => {
    const maintenance = items[index];

    setActiveRow(index + 1);
    setFields({
      applicationId,
      productId: maintenance.productId,
    });
  };

  const saveMaintenance = (maintenance?: IApplicationProductMap) => {
    if (maintenance) {
      updateMaintMap({
        applicationId,
        productId: maintenance.productId,
        newApplicationId: fields.applicationId,
        newProductId: fields.productId,
      });
    } else {
      createMaintMap(fields);
    }
  };

  const deleteMaintenanceConfirm = (productId: number) => {
    deleteMaintMap({
      applicationId,
      productId,
    });
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      setActiveRow(null);
      dispatch(showNotification({
        text: 'Maintenance created',
        type: 'success',
        show: true
      }));
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setActiveRow(null);
      dispatch(showNotification({
        text: 'Maintenance updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Maintenance deleted',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Maintenance
        </Typography>
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Btn click={addMaintenance}>
            Add maintenance
          </Btn>
        </Box>
      </Toolbar>
      {(isCreatingData || isUpdatingData || isDeletingData) && (
        <Loader
          position="absolute"
          size={70}
          blur
        />
      )}
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteMaintenanceConfirm(idToDelete)}
      />
      <TableContainer component={Paper}>
        <Table className={classes.striped_table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              <TableCell>Application</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeRow === 0 && (
              <TableRow key={0}>
                <TableCell>
                  <Select
                    value={fields.applicationId}
                    size="small"
                    onChange={(event) => changeCellField('applicationId', +event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    {applications.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={fields.productId}
                    size="small"
                    onChange={(event) => changeCellField('productId', +event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    {products.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Tooltip title="Save">
                    <IconButton
                      onClick={() => saveMaintenance()}
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => setActiveRow(null)}
                      color="error"
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {items.map((col, index) => (
              <TableRow key={col.productId}>
                <TableCell>
                  {activeRow === index + 1 ? (
                    <Select
                      value={fields.applicationId}
                      size="small"
                      onChange={(event) => changeCellField('applicationId', +event.target.value)}
                      sx={{ textAlign: 'left' }}
                    >
                      {applications.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    applicationName
                  )}
                </TableCell>
                <TableCell>
                  {activeRow === index + 1 ? (
                    <Select
                      value={fields.productId}
                      size="small"
                      onChange={(event) => changeCellField('productId', +event.target.value)}
                      sx={{ textAlign: 'left' }}
                    >
                      {products.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    products.find((p) => p.value === col.productId.toString())?.text
                  )}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {activeRow === index + 1 ? (
                    <>
                      <Tooltip title="Save">
                        <IconButton
                          onClick={() => saveMaintenance(col)}
                          color="primary"
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton
                          onClick={() => setActiveRow(null)}
                          color="error"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => editMaintenance(index)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => setIdToDelete(col.productId)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ApplicationMaintenances;
