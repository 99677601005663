import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IQuote, IQuoteDetails, IQuoteListRequest, IQuoteProcessRequest, IQuoteProcessResponse, IQuoteProductDetail, IQuoteProductDetailsRequest, IQuotesBase } from './interfaces';

export const quoteApi = createApi({
  reducerPath: 'quoteApi',
  tagTypes: ['quote'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getQuotesBase: build.query<IQuotesBase, string>({
      query: () => ({
        url: endpoints.quotesBase,
      }),
    }),
    getQuotes: build.query<IListResp<IQuote>, IQuoteListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        salesRepId,
        startDate,
        endDate
      }) => ({
        url: endpoints.quotes,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          salesRepId,
          startDate,
          endDate
        }
      }),
      providesTags: () => ['quote']
    }),
    getQuote: build.query<IQuoteDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.quote}/${id}`,
      }),
      providesTags: () => ['quote']
    }),
    createQuote: build.mutation<number, IQuoteDetails>({
      query: ({
        salesRepId,
        isTaxExempt,
        billingEmail,
        billingAddress1,
        billingAddress2,
        billingCity,
        billingCompany,
        billingCountry,
        billingFirstName,
        billingLastName,
        billingPhone,
        billingProvince,
        billingState,
        billingZip,
        shipmentEmail,
        shipmentAddress1,
        shipmentAddress2,
        shipmentCity,
        shipmentCompany,
        shipmentCountry,
        shipmentFirstName,
        shipmentLastName,
        shipmentPhone,
        shipmentProvince,
        shipmentState,
        shipmentZip,
        paymentMethod,
        creditCardNumber,
        creditCardExp,
        creditCardCSC,
        expireDate,
        contextId,
        hostedRegion,
        discountCode,
        quoteDetails,
      }) => ({
        url: endpoints.quoteCreate,
        method: 'POST',
        body: {
          salesRepId,
          isTaxExempt,
          billingEmail,
          billingAddress1,
          billingAddress2,
          billingCity,
          billingCompany,
          billingCountry,
          billingFirstName,
          billingLastName,
          billingPhone,
          billingProvince,
          billingState,
          billingZip,
          shipmentEmail,
          shipmentAddress1,
          shipmentAddress2,
          shipmentCity,
          shipmentCompany,
          shipmentCountry,
          shipmentFirstName,
          shipmentLastName,
          shipmentPhone,
          shipmentProvince,
          shipmentState,
          shipmentZip,
          paymentMethod,
          creditCardNumber,
          creditCardExp,
          creditCardCSC,
          expireDate,
          contextId,
          hostedRegion,
          discountCode,
          quoteDetails,
        }
      }),
    }),
    createQuoteDetail: build.mutation<IQuoteProductDetail, IQuoteProductDetailsRequest>({
      query: ({
        quoteId,
        productId,
        description,
        qty,
        price,
        extPrice,
        showListPrice
      }) => ({
        url: `${endpoints.quoteCreateQuoteDetail}/${quoteId}`,
        method: 'POST',
        body: {
          productId,
          description,
          qty,
          price,
          extPrice,
          showListPrice
        }
      }),
      invalidatesTags: ['quote']
    }),
    copyQuote: build.mutation<number, number>({
      query: (id) => ({
        url: `${endpoints.quoteCopy}/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['quote']
    }),
    updateQuote: build.mutation<IQuoteDetails, IQuoteDetails>({
      query: ({
        id,
        salesRepId,
        isTaxExempt,
        billingEmail,
        billingAddress1,
        billingAddress2,
        billingCity,
        billingCompany,
        billingCountry,
        billingFirstName,
        billingLastName,
        billingPhone,
        billingProvince,
        billingState,
        billingZip,
        shipmentEmail,
        shipmentAddress1,
        shipmentAddress2,
        shipmentCity,
        shipmentCompany,
        shipmentCountry,
        shipmentFirstName,
        shipmentLastName,
        shipmentPhone,
        shipmentProvince,
        shipmentState,
        shipmentZip,
        paymentMethod,
        creditCardNumber,
        creditCardExp,
        creditCardCSC,
        expireDate,
        contextId,
        hostedRegion,
        discountCode,
      }) => ({
        url: `${endpoints.quoteEdit}/${id}`,
        method: 'PATCH',
        body: {
          salesRepId,
          isTaxExempt,
          billingEmail,
          billingAddress1,
          billingAddress2,
          billingCity,
          billingCompany,
          billingCountry,
          billingFirstName,
          billingLastName,
          billingPhone,
          billingProvince,
          billingState,
          billingZip,
          shipmentEmail,
          shipmentAddress1,
          shipmentAddress2,
          shipmentCity,
          shipmentCompany,
          shipmentCountry,
          shipmentFirstName,
          shipmentLastName,
          shipmentPhone,
          shipmentProvince,
          shipmentState,
          shipmentZip,
          paymentMethod,
          creditCardNumber,
          creditCardExp,
          creditCardCSC,
          expireDate,
          contextId,
          hostedRegion,
          discountCode,
        }
      }),
      invalidatesTags: ['quote']
    }),
    updateQuoteDetail: build.mutation<IQuoteProductDetail, IQuoteProductDetail>({
      query: ({
        id,
        productId,
        description,
        qty,
        price,
        extPrice,
        showListPrice,
      }) => ({
        url: `${endpoints.quoteEditQuoteDetail}/${id}`,
        method: 'PATCH',
        body: {
          productId,
          description,
          qty,
          price,
          extPrice,
          showListPrice,
        }
      }),
      invalidatesTags: ['quote']
    }),
    processQuote: build.mutation<IQuoteProcessResponse, IQuoteProcessRequest>({
      query: ({
        id,
        salesRepId,
        isTaxExempt,
        billingEmail,
        billingAddress1,
        billingAddress2,
        billingCity,
        billingCompany,
        billingCountry,
        billingFirstName,
        billingLastName,
        billingPhone,
        billingProvince,
        billingState,
        billingZip,
        shipmentEmail,
        shipmentAddress1,
        shipmentAddress2,
        shipmentCity,
        shipmentCompany,
        shipmentCountry,
        shipmentFirstName,
        shipmentLastName,
        shipmentPhone,
        shipmentProvince,
        shipmentState,
        shipmentZip,
        paymentMethod,
        creditCardNumber,
        creditCardExp,
        creditCardCSC,
        expireDate,
        contextId,
        hostedRegion,
        discountCode,
        doNotEmailCustomer,
        doNotEmailCompany,
      }) => ({
        url: `${endpoints.quoteProcess}/${id}`,
        method: 'POST',
        body: {
          salesRepId,
          isTaxExempt,
          billingEmail,
          billingAddress1,
          billingAddress2,
          billingCity,
          billingCompany,
          billingCountry,
          billingFirstName,
          billingLastName,
          billingPhone,
          billingProvince,
          billingState,
          billingZip,
          shipmentEmail,
          shipmentAddress1,
          shipmentAddress2,
          shipmentCity,
          shipmentCompany,
          shipmentCountry,
          shipmentFirstName,
          shipmentLastName,
          shipmentPhone,
          shipmentProvince,
          shipmentState,
          shipmentZip,
          paymentMethod,
          creditCardNumber,
          creditCardExp,
          creditCardCSC,
          expireDate,
          contextId,
          hostedRegion,
          discountCode,
          doNotEmailCustomer,
          doNotEmailCompany,
        }
      }),
      invalidatesTags: ['quote']
    }),
    deleteQuote: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.quoteDelete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['quote']
    }),
    deleteQuoteDetail: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.quoteDeleteQuoteDetail}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['quote']
    }),
  }),
});

export const {
  useGetQuotesBaseQuery,
  useLazyGetQuotesQuery,
  useGetQuoteQuery,
  useCreateQuoteMutation,
  useCreateQuoteDetailMutation,
  useCopyQuoteMutation,
  useUpdateQuoteMutation,
  useUpdateQuoteDetailMutation,
  useProcessQuoteMutation,
  useDeleteQuoteMutation,
  useDeleteQuoteDetailMutation,
} = quoteApi;
