import { formattedUrl } from '../utils/formatedUrl';

interface ILinks {
  checkbox: string,
  api: string,
  admin: string,
  adminApi: string,
}

export const baseUrls:ILinks = {
  checkbox: 'https://orders.checkbox.com',
  api: formattedUrl('https://signup-api.checkbox.com', 'https://localhost:5003'),
  admin: 'https://admin.checkbox.com',
  adminApi: formattedUrl('https://adminapi.checkbox.com', 'https://localhost:5001'),
};
