import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Btn, CardWrapper } from '@monorepo/common';
import { Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { showNotification } from '../../features/ui/sliceNotification';
import GridField from '../../components/GridField';
import { useGetLicenseHashQuery } from '../../services/license';
import { useStyles } from './styles';

const LicensingTool = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [date, setDate] = useState<Date | null>(null);
  const [isDateModifyed, setIsDateModifyed] = useState<boolean>(false);
  const [queryDate, setQueryDate] = useState<Date | null>(null);
  const [script, setScript] = useState<string>('');

  const { data, isLoading } = useGetLicenseHashQuery(moment(queryDate)?.format('YYYY-MM-DD'), { skip: !queryDate });

  const generateScript = () => {
    setQueryDate(date);
    setIsDateModifyed(true);
  };

  const copyScript = () => {
    navigator.clipboard.writeText(script);
    dispatch(showNotification({
      text: 'Copied!',
      type: 'success',
      show: true
    }));
  };

  useEffect(() => {
    if (date) {
      setIsDateModifyed(true);
    }
  }, [date]);

  useEffect(() => {
    if (data) {
      setScript(`DECLARE @@SettingName VARCHAR(100) = 'CheckboxSerialHash'
DECLARE @@SettingValue NVARCHAR(2000) = '${data}'

IF NOT EXISTS(Select SettingName FROM ckbx_Settings WHERE SettingName = @@SettingName)
    INSERT INTO ckbx_Settings (SettingName, SettingValue) VALUES (@@SettingName, @@SettingValue)
ELSE
    UPDATE ckbx_Settings SET SettingValue = @@SettingValue WHERE SettingName = @@SettingName`);
    }
  }, [data]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Licensing Tool"
      title="Licensing Tool"
      maxWidth="100%"
      isLoading={isLoading}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        component="div"
      >
        License Extender Script
      </Typography>
      <Grid
        sx={{ mt: 2 }}
        container
        spacing={2}
      >
        <GridField
          label="End Date"
          labelmd={2}
          fieldmd={10}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={date}
              onChange={(event) => setDate(event)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={!date && isDateModifyed}
                  helperText={!date && isDateModifyed && 'End Date is reqired'}
                />
              )}
            />
          </LocalizationProvider>
        </GridField>
        <GridField
          label="Script"
          labelmd={2}
          fieldmd={10}
        >
          <TextField
            value={script}
            multiline
            fullWidth
            minRows={4}
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                style: {
                  resize: 'both'
                }
              }
            }}
          />
        </GridField>
        <GridField
          label=""
          labelmd={2}
          fieldmd={10}
        >
          <Btn
            className={classes.inline_btn}
            click={generateScript}
          >
            Generate Script
          </Btn>
          <Btn
            className={classes.inline_btn}
            click={copyScript}
          >
            Copy Script
          </Btn>
        </GridField>
      </Grid>
    </CardWrapper>
  );
};

export default LicensingTool;
