import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  date_range_picker_wrapper: {
    margin: 'auto',
    display: 'flex',
    width: 'max-content',
    alignItems: 'baseline',
    WebkitBoxAlign: 'baseline',
    '& > div': {
      display: 'inline-flex',
    }
  },
  btn: {
    margin: '12px auto'
  }
}));
