import { Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface ITableToolBarProps {
  startDate: Date | null,
  endDate: Date | null,
  handleStartDateChange: (event: any) => void,
  handleEndDateChange: (event: any) => void,
}

const TableToolBar = ({ startDate, endDate, handleStartDateChange, handleEndDateChange }: ITableToolBarProps) => (
  <Grid
    container
    spacing={3}
    sx={{ p: 3 }}
  >
    <Grid
      item
      xs={12}
      md={6}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => (
            <TextField
              required
              fullWidth
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => (
            <TextField
              required
              fullWidth
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Grid>
  </Grid>
);

export default TableToolBar;
