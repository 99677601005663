import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import cookies from 'js-cookie';
import { book, cookieNames } from '@monorepo/common';
import { endpoints } from '../constants/endpoints';
import { showNotification } from '../features/ui/sliceNotification';
import { fillGlobalError } from '../features/sliceGlobalError';

const baseQuery = fetchBaseQuery({
  baseUrl: endpoints.dashboardUrl,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${cookies.get(cookieNames.access_token)}`);
    return headers;
  }
});
export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (cookies.get(cookieNames.refresh_token)) {
    if (result.error && result.error.status === 401) {
      const refreshResult = await baseQuery({
        url: `/account/RefreshToken?refreshToken=${cookies.get(cookieNames.refresh_token)}`,
        method: 'GET',
      }, api, extraOptions);

      if (refreshResult.data) {
        // @ts-ignore
        await cookies.set(cookieNames.access_token, refreshResult.data.access_token);
        // @ts-ignore
        await cookies.set(cookieNames.refresh_token, refreshResult.data.refresh_token);
        result = await baseQuery(args, api, extraOptions);
      } else {
        cookies.remove(cookieNames.access_token);
        window.location.href = book.admin.account.login;
      }
    } else if (result.error && result.error.status === 403) {
      api.dispatch(fillGlobalError({
        status: 403,
        message: 'Sorry, you do not have permission'
      }));
      api.dispatch(showNotification({
        type: 'error',
        show: true,
        fixed: true,
        text: 'Sorry, you do not have permission'
      }));
    } else if (result.error && result.error.status === 'PARSING_ERROR') {
      api.dispatch(showNotification({
        type: 'error',
        show: true,
        text: result.error.data
      }));
    }
  } else {
    cookies.remove(cookieNames.access_token);
    window.location.href = book.admin.account.login;
  }

  return result;
};
