import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IAdminUser, IAdminUserListRequest, IAdminUserRequest, IAdminUserDetails } from './interfaces';

export const adminUserApi = createApi({
  reducerPath: 'adminUserApi',
  tagTypes: ['adminUser'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getAdminUsers: build.query<IListResp<IAdminUser>, IAdminUserListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        isActive
      }) => ({
        url: endpoints.adminUsers,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          isActive
        }
      }),
      providesTags: () => ['adminUser']
    }),
    getAdminUser: build.query<IAdminUserDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.adminUser}/${id}`,
      }),
      providesTags: () => ['adminUser']
    }),
    createAdminUser: build.mutation<number, IAdminUserRequest>({
      query: ({
        email,
        name,
        userName,
        isActive,
        isSuperUser,
        isGoogleUser,
        slackUserId,
        asanaUserId,
        phoneNumber,
        permissions
      }) => ({
        url: endpoints.adminUserCreate,
        method: 'POST',
        body: {
          email,
          name,
          userName,
          isActive,
          isSuperUser,
          isGoogleUser,
          slackUserId,
          asanaUserId,
          phoneNumber,
          permissions
        }
      }),
      invalidatesTags: ['adminUser']
    }),
    updateAdminUser: build.mutation<IAdminUserDetails, IAdminUserRequest>({
      query: ({
        id,
        email,
        name,
        userName,
        isActive,
        isSuperUser,
        isGoogleUser,
        slackUserId,
        asanaUserId,
        phoneNumber,
        permissions
      }) => ({
        url: `${endpoints.adminUserEdit}/${id}`,
        method: 'PATCH',
        body: {
          email,
          name,
          userName,
          isActive,
          isSuperUser,
          isGoogleUser,
          slackUserId,
          asanaUserId,
          phoneNumber,
          permissions
        }
      }),
      invalidatesTags: ['adminUser']
    }),
    deleteAdminUser: build.mutation<IAdminUserDetails, number>({
      query: (id: number) => ({
        url: `${endpoints.adminUserDelete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['adminUser']
    }),
  }),
});

export const {
  useLazyGetAdminUsersQuery,
  useGetAdminUserQuery,
  useCreateAdminUserMutation,
  useUpdateAdminUserMutation,
  useDeleteAdminUserMutation
} = adminUserApi;
