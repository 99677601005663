import { useRef, useState } from 'react';
import { Box, ButtonGroup, ClickAwayListener, Grow, MenuList, MenuItem, Paper, Popper } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { baseUrls, Btn } from '@monorepo/common';
import { useStyles } from '../styles';

interface IHeaderToolBarProps {
  id: string,
  isNew: boolean,
  isValid: boolean,
  showProcessBtn: boolean,
  save: () => void,
  copy: () => void,
  processQuotePopup: () => void
  deleteQuotePopup: () => void
}

const HeaderToolBar = ({ id, isNew, isValid, showProcessBtn, save, copy, processQuotePopup, deleteQuotePopup }: IHeaderToolBarProps) => {
  const classes = useStyles();
  const options = [
    { name: 'Generate Quote PDF', url: `${baseUrls.checkbox}/mvc/api/quote/${id}/quote-pdf` },
    { name: 'Generate Quote w/Signature PDF', url: `${baseUrls.checkbox}/mvc/api/quote/${id}/quote-pdf-signature` },
    { name: 'Generate Invoice PDF', url: `${baseUrls.checkbox}/mvc/api/quote/${id}/invoice-pdf` },
  ];
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (url: string) => {
    setOpen(false);
    window.open(url, '_blank')?.focus();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Btn
        className={classes.inline_btn}
        disabled={!isValid}
        click={save}
      >
        Save
      </Btn>
      {!isNew && (
        <>
          {showProcessBtn && (
            <Btn
              className={classes.inline_btn}
              disabled={!isValid}
              click={processQuotePopup}
            >
              Save and Process Order
            </Btn>
          )}
          <Btn
            className={classes.inline_btn}
            click={deleteQuotePopup}
          >
            Delete
          </Btn>
          <Btn
            className={classes.inline_btn}
            click={copy}
          >
            Copy
          </Btn>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            className={classes.inline_btn_group}
          >
            <Btn click={handleToggle}>
              Generate PDFs
              <ArrowDropDownIcon />
            </Btn>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem>
                      {options.map((option) => (
                        <MenuItem
                          key={option.name}
                          onClick={() => handleMenuItemClick(option.url)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </Box>
  );
};

export default HeaderToolBar;
