import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inline_btn: {
    display: 'inline-block',
    marginLeft: 8,
  },
  inline_btn_group: {
    marginLeft: 8,
    '&.MuiButtonGroup-root': {
      borderRadius: '8px',
    },
    '& button': {
      paddingRight: '32px',
      '& svg': {
        position: 'absolute',
        right: '6px',
      }
    }
  },
  btn_add: {
    whiteSpace: 'nowrap',
  },
  table_head: {
    background: 'rgb(243, 244, 246)',
  },
  striped_table: {
    '& tr:nth-child(even)': {
      backgroundColor: '#F2F2F2'
    }
  },
}));
