import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Switch, TextField } from '@mui/material';
import { book, Btn, CardWrapper } from '@monorepo/common';
import { showNotification } from '../../features/ui/sliceNotification';
import GridField from '../../components/GridField';
import { useCreateApplicationMutation, useGetApplicationQuery, useUpdateApplicationMutation } from '../../services/application';
import { IApplicationDetails } from '../../services/application/interfaces';
import ApplicationReleases from './components/ApplicationReleases';
import ApplicationProdToApps from './components/ApplicationProdToApps';
import ApplicationMaintenances from './components/ApplicationMaintenances';
import ApplicationUpgrades from './components/ApplicationUpgrades';

const Application = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isNew = id === 'new';

  const [fields, setFields] = useState<IApplicationDetails>({} as IApplicationDetails);

  const { data, isLoading: isLoadingData } = useGetApplicationQuery(isNew ? '0' : id!);
  const [createApplication, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateApplicationMutation();
  const [updateApplication, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateApplicationMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create Application';
  } else if (data) {
    pageTitle = `Application #${data && data.id}`;
  } else {
    pageTitle = 'Application not found';
  }

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const save = () => {
    if (isNew) {
      createApplication(fields);
    } else {
      updateApplication(fields);
    }
  };

  useEffect(() => {
    if (data) {
      setFields(data);
    }
  }, [data]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Application created',
        type: 'success',
        show: true
      }));
      navigate(book.admin.application());
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Application updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Application"
      title={pageTitle}
      isLoading={isLoadingData || isCreatingData || isUpdatingData}
      childrenTitleRight={fields
        && (
          <Btn
            click={save}
            disabled={!(fields.name?.trim())}
          >
            Save
          </Btn>
        )}
    >
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Active"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={fields.isActive || false}
                onChange={(event) => {
                  changeCellField('isActive', event.target.checked);
                }}
                name="isActive"
              />
            </GridField>
            <GridField
              label="Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.name || ''}
                fullWidth
                error={!fields.name?.trim()}
                helperText={!fields.name?.trim() && 'The subject field is required'}
                onChange={(event) => {
                  changeCellField('name', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Description"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.description || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('description', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="License / Warranty"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.licenseText || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('licenseText', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Renewal Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.renewalEmail || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('renewalEmail', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Guid"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data.guid}
            </GridField>
          </Grid>
          {!isNew && (
            <ApplicationReleases applicationId={+id!} />
          )}
          {data && !isNew && (
            <ApplicationProdToApps
              applicationId={+id!}
              applicationName={data.name}
              applications={data.applications}
              products={data.products}
            />
          )}
          {data && !isNew && (
            <ApplicationMaintenances
              applicationId={+id!}
              applicationName={data.name}
              items={data.maintenances}
              applications={data.applications}
              products={data.products}
            />
          )}
          {data && !isNew && (
            <ApplicationUpgrades
              applicationId={+id!}
              applicationName={data.name}
              items={data.upgrades}
              applications={data.applications}
              products={data.products}
            />
          )}
        </Box>
      )}
    </CardWrapper>
  );
};

export default Application;
