import { useState, useMemo, useEffect } from 'react';
import { Autocomplete, Box, CircularProgress, createFilterOptions, IconButton, TextField, Tooltip } from '@mui/material';
import { book, Btn, CardWrapper, cookieNames, DataGrid, ModalWindow } from '@monorepo/common';
import { currencyFormatter } from '@monorepo/common/src/utils/currencyFormatter';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import { debounce } from 'debounce';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import cookies from 'js-cookie';
import { useStyles } from './styles';
import TableToolBar from './components/TableToolBar';
import { renewalQuoteApi, useGenerateQuotesMutation, useUpdateRenewalQuoteItemMutation } from '../../services/renewalQuote';
import { IRenewalQuoteItem } from '../../services/renewalQuote/interfaces';
import { useAppSelector } from '../../app/hooks';
import { searchForCommonItems } from '../../utils/searchForCommonItems';
import { Permission } from '../../constants/permission';
import { IProductItem } from '../../services/product/interfaces';
import { useGetProductsQuery } from '../../services/product';
import { showNotification } from '../../features/ui/sliceNotification';
import AdminLink from '../../components/AdminLink';

const UpForRenewal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const upForRenewalDates = (cookies.get(cookieNames.up_for_renewal_dates) || '').split('-');

  const [startDate, setStartDate] = useState<Date | null>(upForRenewalDates.length > 1 ? moment.utc(upForRenewalDates[0]).toDate() : null);
  const [endDate, setEndDate] = useState<Date | null>(upForRenewalDates.length > 1 ? moment.utc(upForRenewalDates[1]).toDate() : null);
  const [earlyPriceEndDate, setEarlyPriceEndDate] = useState<Date | null>(null);
  const [regularPriceEndDate, setRegularPriceEndDate] = useState<Date | null>(null);
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [product1SearchText, setProduct1SearchText] = useState<string>('');
  const [product2SearchText, setProduct2SearchText] = useState<string>('');
  const [isProduct1SearchModifyed, setIsProduct1SearchModifyed] = useState<boolean>(false);
  const [isProduct2SearchModifyed, setIsProduct2SearchModifyed] = useState<boolean>(false);
  const [fields, setFields] = useState<IRenewalQuoteItem>({} as IRenewalQuoteItem);
  const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false);

  const { isFetching: isFetchingProducts1Data, data: products1 } = useGetProductsQuery({ searchText: product1SearchText, page: 1, pageSize: 100, sortingOrder: 'desc', sortBy: 'id' }, { skip: !isProduct1SearchModifyed || !product1SearchText });
  const { isFetching: isFetchingProducts2Data, data: products2 } = useGetProductsQuery({ searchText: product2SearchText, page: 1, pageSize: 100, sortingOrder: 'desc', sortBy: 'id' }, { skip: !isProduct2SearchModifyed || !product2SearchText });

  const [updateRenewalQuote, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateRenewalQuoteItemMutation();
  const [generateQuotes, { isLoading: isGeneratingQuotesData }] = useGenerateQuotesMutation();

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const { userRoles, isSuperUser } = useAppSelector(({ profile }) => ({
    userRoles: profile.UserRoles,
    isSuperUser: profile.IsSuper
  }));

  const hasRenewalQuotesGeneratorPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.RenewalQuotesGenerator].map(String));

  const editRenewalQuote = (renewalQuote: IRenewalQuoteItem) => {
    setActiveRow(renewalQuote.id);
    setFields(renewalQuote);
    setProduct1SearchText(renewalQuote.partNumber1Year || '');
    setProduct2SearchText(renewalQuote.partNumber2Year || '');
    setIsError(false);
  };

  const saveRenewalQuote = () => {
    if (!fields.productId1Year || !fields.productId2Year) {
      setIsError(true);
      return;
    }

    updateRenewalQuote(fields);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: IProductItem) => option.partNumber + option.name + option.id,
  });

  const onSearchProduct1Text = useMemo(() => debounce((value: string) => {
    setProduct1SearchText(value);
    setIsProduct1SearchModifyed(true);
  }, 800), []);

  const onSelectProduct1 = (value: IProductItem | string | null) => {
    if (value && typeof value !== 'string') {
      setProduct1SearchText(value.partNumber);
      changeCellField('productId1Year', value.id);
    }
  };

  const onSearchProduct2Text = useMemo(() => debounce((value: string) => {
    setProduct2SearchText(value);
    setIsProduct2SearchModifyed(true);
  }, 800), []);

  const onSelectProduct2 = (value: IProductItem | string | null) => {
    if (value && typeof value !== 'string') {
      setProduct2SearchText(value.partNumber);
      changeCellField('productId2Year', value.id);
    }
  };

  const generateQuotesSubmit = () => {
    const earlyPriceDate = moment(earlyPriceEndDate).startOf('day');
    const regularPriceDate = moment(regularPriceEndDate).startOf('day');

    generateQuotes({
      startDate: startDate!,
      endDate: endDate!,
      earlyPriceEndDate: moment.utc(earlyPriceDate.format('MM/DD/YYYY')).toDate(),
      regularPriceEndDate: moment.utc(regularPriceDate.format('MM/DD/YYYY')).toDate(),
    }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      } else if (res.data !== undefined) {
        dispatch(showNotification({
          text: `${res.data} renewals was processed`,
          type: 'success',
          show: true
        }));
        setShowGenerateModal(false);
      }
    });
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Renewal Quote updated',
        type: 'success',
        show: true
      }));
      setActiveRow(-1);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (startDate && endDate && startDate <= endDate) {
      cookies.set(cookieNames.up_for_renewal_dates, `${moment(startDate).format('MM/DD/YYYY')}-${moment(endDate).format('MM/DD/YYYY')}`);
    }
  }, [startDate, endDate]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Up For Renewal"
      title="Up For Renewal"
      maxWidth="100%"
      isLoading={isUpdatingData || isGeneratingQuotesData}
      childrenTitleRight={
        hasRenewalQuotesGeneratorPermission && (
          <Box>
            <Btn
              click={() => setShowGenerateModal(true)}
              disabled={!startDate || !endDate}
              className={classes.inline_btn}
            >
              Generate Quotes
            </Btn>
            <Btn
              link={book.admin.earlyRenewalEmails}
              disabled={!startDate || !endDate}
              className={classes.inline_btn}
            >
              Early Emails
            </Btn>
            <Btn
              link={book.admin.renewalEmails}
              disabled={!startDate || !endDate}
              className={classes.inline_btn}
            >
              Regular Emails
            </Btn>
            <Btn
              link={book.admin.expiredServerEmails}
              disabled={!startDate || !endDate}
              className={classes.inline_btn}
            >
              Invoices to Server
            </Btn>
            <Btn
              link={book.admin.expiredHostedEmails}
              disabled={!startDate || !endDate}
              className={classes.inline_btn}
            >
              Invoices to Hosted
            </Btn>
          </Box>
        )
      }
    >
      <ModalWindow
        show={showGenerateModal}
        title="Generate Quotes"
        closeModal={() => setShowGenerateModal(false)}
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Early discount end date"
              value={earlyPriceEndDate}
              onChange={(value) => setEarlyPriceEndDate(value)}
              renderInput={(params) => (
                <TextField
                  required
                  sx={{ mb: 2 }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Regular price end date"
              value={regularPriceEndDate}
              onChange={(value) => setRegularPriceEndDate(value)}
              renderInput={(params) => (
                <TextField
                  required
                  sx={{ mb: 2 }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Btn
          click={generateQuotesSubmit}
          disabled={!earlyPriceEndDate || !regularPriceEndDate}
          className={classes.popup_btn}
        >
          Submit
        </Btn>
      </ModalWindow>
      <DataGrid<IRenewalQuoteItem>
        keyExtractor={(col) => col.id.toString()}
        globalId={activeRow}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={(value) => setStartDate(value)}
            handleEndDateChange={(value) => setEndDate(value)}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultSortDirection: 'desc',
          defaultPageSize: 25,
        }}
        columns={[
          {
            rowId: (col) => col.id,
            field: 'id',
            title: 'ID',
            template: (col) => (
              <AdminLink
                url={book.admin.renewalQuote(col.id)}
                title={col.id || ''}
              />
            ),
            editTemplate: (col) => col.id
          },
          {
            field: 'applicationName',
            title: 'Application Name',
            hideCurrentSortable: true,
            editTemplate: () => (
              <TextField
                value={fields.applicationName || ''}
                size="small"
                onChange={(val) => { changeCellField('applicationName', val.target.value || ''); }}
              />
            )
          },
          {
            field: 'email',
            title: 'Email',
            hideCurrentSortable: true,
            editTemplate: () => (
              <TextField
                value={fields.email || ''}
                size="small"
                onChange={(val) => { changeCellField('email', val.target.value || ''); }}
              />
            )
          },
          {
            field: 'firstName',
            title: 'Name',
            hideCurrentSortable: true,
            template: (col) => `${col.firstName} ${col.lastName}`,
            editTemplate: () => (
              <Box>
                <TextField
                  value={fields.firstName || ''}
                  size="small"
                  onChange={(val) => { changeCellField('firstName', val.target.value || ''); }}
                />
                <TextField
                  value={fields.lastName || ''}
                  size="small"
                  onChange={(val) => { changeCellField('lastName', val.target.value || ''); }}
                />
              </Box>
            )
          },
          {
            field: 'companyName',
            title: 'Company',
            hideCurrentSortable: true,
            editTemplate: () => (
              <TextField
                value={fields.companyName || ''}
                size="small"
                onChange={(val) => { changeCellField('companyName', val.target.value || ''); }}
              />
            )
          },
          {
            field: 'supportContractId',
            title: 'Contract',
            template: (col) => (
              <AdminLink
                url={book.admin.supportContract(col.supportContractId)}
                title={col.supportContractId || ''}
                permissions={[Permission.SupportContracts]}
              />
            ),
            editTemplate: () => (
              <TextField
                fullWidth
                value={fields.supportContractId}
                size="small"
                type="number"
                onChange={(val) => changeCellField('supportContractId', +val.target.value)}
              />
            )
          },
          {
            field: 'contractEndDate',
            title: 'Contract Ends',
            template: (col) => <>{col.contractEndDate ? moment(col.contractEndDate).format('M/D/YYYY') : '-'}</>,
            editTemplate: () => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={fields.contractEndDate}
                  onChange={(event) => changeCellField('contractEndDate', event)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            )
          },
          {
            field: 'partNumber1Year',
            title: 'Product Part # 1 Year',
            hideCurrentSortable: true,
            editTemplate: () => (
              <Autocomplete
                sx={{ width: 200 }}
                filterOptions={filterOptions}
                value={{ partNumber: product1SearchText } as IProductItem}
                getOptionLabel={(option) => option.partNumber}
                options={products1?.result || []}
                loading={isFetchingProducts1Data}
                onChange={(event, value) => onSelectProduct1(value)}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === 'clear') {
                    onSearchProduct1Text(newInputValue);
                  }
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                  >
                    {`${option.partNumber} - ${option.name} - ${currencyFormatter.format(option.price)}`}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={isError && !fields.productId1Year}
                    helperText={isError && !fields.productId1Year && 'Product 1 Year field is required'}
                    onChange={(event) => onSearchProduct1Text(event.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isFetchingProducts1Data ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              sx={{ mr: 4 }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )
          },
          {
            field: 'partNumber2Year',
            title: 'Product Part # 2 Year',
            hideCurrentSortable: true,
            editTemplate: () => (
              <Autocomplete
                sx={{ width: 200 }}
                filterOptions={filterOptions}
                value={{ partNumber: product2SearchText } as IProductItem}
                getOptionLabel={(option) => option.partNumber}
                options={products2?.result || []}
                loading={isFetchingProducts2Data}
                onChange={(event, value) => onSelectProduct2(value)}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === 'clear') {
                    onSearchProduct2Text(newInputValue);
                  }
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                  >
                    {`${option.partNumber} - ${option.name} - ${currencyFormatter.format(option.price)}`}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={isError && !fields.productId2Year}
                    helperText={isError && !fields.productId2Year && 'Product 2 Year field is required'}
                    onChange={(event) => onSearchProduct2Text(event.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isFetchingProducts2Data ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              sx={{ mr: 4 }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )
          },
          {
            field: 'price1YearEarly',
            title: 'Price Early 1 Year',
            hideCurrentSortable: true,
            isCurrency: true,
            editTemplate: () => (
              <TextField
                fullWidth
                value={fields.price1YearEarly || ''}
                size="small"
                type="number"
                onChange={(val) => changeCellField('price1YearEarly', +val.target.value)}
              />
            )
          },
          {
            field: 'price2YearEarly',
            title: 'Price Early 2 Year',
            hideCurrentSortable: true,
            isCurrency: true,
            editTemplate: () => (
              <TextField
                fullWidth
                value={fields.price2YearEarly || ''}
                size="small"
                type="number"
                onChange={(val) => changeCellField('price2YearEarly', +val.target.value)}
              />
            )
          },
          {
            field: 'price1Year',
            title: 'Price 1 Year',
            hideCurrentSortable: true,
            isCurrency: true,
            editTemplate: () => (
              <TextField
                fullWidth
                value={fields.price1Year || ''}
                size="small"
                type="number"
                onChange={(val) => changeCellField('price1Year', +val.target.value)}
              />
            )
          },
          {
            field: 'price2Year',
            title: 'Price 2 Year',
            hideCurrentSortable: true,
            isCurrency: true,
            editTemplate: () => (
              <TextField
                fullWidth
                value={fields.price2Year || ''}
                size="small"
                type="number"
                onChange={(val) => changeCellField('price2Year', +val.target.value)}
              />
            )
          },
          {
            field: 'lastCol',
            title: 'empty',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (
              <Box sx={{ whiteSpace: 'nowrap' }}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => editRenewalQuote(col)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ),
            editTemplate: () => (
              <>
                <Tooltip title="Save">
                  <IconButton
                    onClick={() => saveRenewalQuote()}
                    color="primary"
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={() => setActiveRow(null)}
                    color="error"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          },
        ]}
        apiProps={{
          api: renewalQuoteApi,
          apiMethodName: 'useLazyGetRenewalQuotesQuery',
          isSkip: !startDate || !endDate,
          apiParams: {
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
            isOptedOut: false,
          },
        }}
      />
    </CardWrapper>
  );
};

export default UpForRenewal;
