import { useMemo, useState } from 'react';
import { Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Autocomplete, TextField, CircularProgress, createFilterOptions, debounce } from '@mui/material';
import { book, Scrollbar } from '@monorepo/common';
import { currencyFormatter } from '@monorepo/common/src/utils/currencyFormatter';
import { useStyles } from '../styles';
import { useGetProductsQuery } from '../../../services/product';
import { IProductItem } from '../../../services/product/interfaces';
import { IRenewalQuoteDetail } from '../../../services/renewalQuote/interfaces';
import AdminLink from '../../../components/AdminLink';
import { Permission } from '../../../constants/permission';

interface IRenewalQuoteDetailsProps {
  items: IRenewalQuoteDetail[],
  changeCellField: (name: string, value?: any) => void
}

const RenewalQuoteDetails = ({ items, changeCellField }: IRenewalQuoteDetailsProps) => {
  const classes = useStyles();
  const [product1SearchText, setProduct1SearchText] = useState<string>(items[0].partNumber || '');
  const [product2SearchText, setProduct2SearchText] = useState<string>(items[1].partNumber || '');
  const [isProduct1SearchModifyed, setIsProduct1SearchModifyed] = useState<boolean>(false);
  const [isProduct2SearchModifyed, setIsProduct2SearchModifyed] = useState<boolean>(false);
  const tableColumnNames = [{ id: 1, title: '' }, { id: 2, title: 'Product Part #' }, { id: 3, title: 'Qty' }, { id: 4, title: 'Price Early' }, { id: 5, title: 'Price' }, { id: 6, title: 'Quote ID Early' }, { id: 7, title: 'Quote ID' }];

  const { isFetching: isFetchingProducts1Data, data: products1 } = useGetProductsQuery({ searchText: product1SearchText, page: 1, pageSize: 100, sortingOrder: 'desc', sortBy: 'id', isActive: true, includeDetails: true }, { skip: !isProduct1SearchModifyed || !product1SearchText });
  const { isFetching: isFetchingProducts2Data, data: products2 } = useGetProductsQuery({ searchText: product2SearchText, page: 1, pageSize: 100, sortingOrder: 'desc', sortBy: 'id', isActive: true, includeDetails: true }, { skip: !isProduct2SearchModifyed || !product2SearchText });

  const onSearchProduct1Text = useMemo(() => debounce((value: string) => {
    setProduct1SearchText(value);
    setIsProduct1SearchModifyed(true);
  }, 800), []);

  const onSelectProduct1 = (value: IProductItem | string | null) => {
    if (value && typeof value !== 'string') {
      setProduct1SearchText(value.partNumber);
      changeCellField('productId1Year', value.id);
    }
  };

  const onSearchProduct2Text = useMemo(() => debounce((value: string) => {
    setProduct2SearchText(value);
    setIsProduct2SearchModifyed(true);
  }, 800), []);

  const onSelectProduct2 = (value: IProductItem | string | null) => {
    if (value && typeof value !== 'string') {
      setProduct2SearchText(value.partNumber);
      changeCellField('productId2Year', value.id);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: IProductItem) => option.partNumber + option.name + option.id,
  });

  return (
    <Scrollbar>
      <TableContainer
        component={Paper}
        sx={{ mt: 4 }}
      >
        <Table className={classes.striped_table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              {tableColumnNames.map((c) => <TableCell key={c.id}>{c.title}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((col) => (
              <TableRow key={col.id}>
                <TableCell>{`${col.id} Year`}</TableCell>
                <TableCell>
                  {col.id === 1 ? (
                    <Autocomplete
                      sx={{ width: 200 }}
                      filterOptions={filterOptions}
                      value={{ partNumber: product1SearchText } as IProductItem}
                      getOptionLabel={(option) => option.partNumber}
                      options={products1?.result || []}
                      loading={isFetchingProducts1Data}
                      onChange={(event, value) => onSelectProduct1(value)}
                      onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'clear') {
                          onSearchProduct1Text(newInputValue);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          key={option.id}
                        >
                          {`${option.partNumber} - ${option.name} - ${currencyFormatter.format(option.price)}`}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={!product1SearchText.trim() && isProduct1SearchModifyed}
                          helperText={!product1SearchText.trim() && isProduct1SearchModifyed && 'The part number field is required'}
                          onChange={(event) => onSearchProduct1Text(event.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isFetchingProducts1Data ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                    sx={{ mr: 4 }}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      sx={{ width: 200 }}
                      filterOptions={filterOptions}
                      value={{ partNumber: product2SearchText } as IProductItem}
                      getOptionLabel={(option) => option.partNumber}
                      options={products2?.result || []}
                      loading={isFetchingProducts2Data}
                      onChange={(event, value) => onSelectProduct2(value)}
                      onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'clear') {
                          onSearchProduct2Text(newInputValue);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          key={option.id}
                        >
                          {`${option.partNumber} - ${option.name} - ${currencyFormatter.format(option.price)}`}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={!product2SearchText.trim() && isProduct2SearchModifyed}
                          helperText={!product2SearchText.trim() && isProduct2SearchModifyed && 'The part number field is required'}
                          onChange={(event) => onSearchProduct2Text(event.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isFetchingProducts2Data ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                    sx={{ mr: 4 }}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={col.productQty || ''}
                    size="small"
                    type="number"
                    onChange={(val) => changeCellField(`productQty${col.id}Year`, +val.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={col.priceEarly || ''}
                    size="small"
                    type="number"
                    onChange={(val) => changeCellField(`price${col.id}YearEarly`, +val.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={col.price || ''}
                    size="small"
                    type="number"
                    onChange={(val) => changeCellField(`price${col.id}Year`, +val.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <AdminLink
                    url={book.admin.quote(col.quoteIdEarly)}
                    title={col.quoteIdEarly || ''}
                    permissions={[Permission.Quotes]}
                  />
                </TableCell>
                <TableCell>
                  <AdminLink
                    url={book.admin.quote(col.quoteId)}
                    title={col.quoteId || ''}
                    permissions={[Permission.Quotes]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
};

export default RenewalQuoteDetails;
