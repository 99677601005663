import React, { memo } from 'react';
import { Divider } from '@material-ui/core';

import Loader from '../../components/Loader';
import ErrorBoundary from '../ErrorBoundary';
import { IRegDeskCardsProps } from './interfaceCardWrapper';
import CardWrapperModalWindow from './components/CardWrapperModalWindow';
import CardWrapperTitle from './components/CardWrapperTitle';
import CardWrapperInternalWrapper from './components/CardWrapperInternalWrapper';
import CardWrapperTopPanel from './components/CardWrapperTopPanel';
// import { useStyles } from './styles';

const CardWrapper = ({
  title,
  helmet,
  children,
  maxWidth,
  isLoading = false,
  fullWidth = false,
  noOverflow = false,
  centerText = false,
  classNameFotTitle,
  childrenTitleRight,
  modalWindowOptions,
  titleCenter = false,
  classnameForWrapper,
  lateralPadding = false,
  classNameForInternalBlock
}: IRegDeskCardsProps) => {
  // const classes = useStyles();
  const internalStyles = maxWidth && { style: { maxWidth } };

  return (
     <>
        <CardWrapperTitle title={helmet} />
        <CardWrapperModalWindow modalWindowOptions={modalWindowOptions} />
        <Loader isShow={isLoading} />

        <CardWrapperInternalWrapper
           fullWidth={fullWidth}
           noOverflow={noOverflow}
           centerText={centerText}
           internalStyles={internalStyles}
           lateralPadding={lateralPadding}
           classnameForWrapper={classnameForWrapper}
           classNameForInternalBlock={classNameForInternalBlock}
        >
           <CardWrapperTopPanel
              title={title}
              titleCenter={titleCenter}
              classNameFotTitle={classNameFotTitle}
              childrenTitleRight={childrenTitleRight}
           />
           <ErrorBoundary>
              {childrenTitleRight && <Divider />}
              {children}
           </ErrorBoundary>
        </CardWrapperInternalWrapper>
     </>
  );
};

export default memo(CardWrapper);
