import CheckIcon from '@mui/icons-material/Check';
import { book, CardWrapper, DataGrid } from '@monorepo/common';
import moment from 'moment';
import { IUser } from '../../services/user/interfaces';
import { userApi } from '../../services/user';
import AdminLink from '../../components/AdminLink';

const Users = () => (
  <CardWrapper
    titleCenter
    centerText
    helmet="Users"
    title="Users"
    maxWidth="100%"
  >
    <DataGrid<IUser>
      keyExtractor={(col) => col.id.toString()}
      styleProps={{
        isSelectedEven: true
      }}
      defaultGridProps={{
        defaultSortDirection: 'desc',
        defaultPageSize: 25
      }}
      columns={[
        {
          field: 'id',
          title: 'ID',
          template: (col) => (
            <AdminLink
              url={book.admin.user(col.id)}
              title={col.id}
            />
          )
        },
        {
          field: 'firstName',
          title: 'First name',
        },
        {
          field: 'lastName',
          title: 'Last name'
        },
        {
          field: 'companyName',
          title: 'Company name',
        },
        {
          field: 'email',
          title: 'Email',
        },
        {
          field: 'createdDate',
          title: 'Created Date',
          template: (col) => <>{col.createdDate ? moment(col.createdDate).format('M/D/YYYY') : '-'}</>,
        },
        {
          field: 'isActive',
          title: 'Active',
          align: 'center',
          template: (col) => (<>{col.isActive ? <CheckIcon /> : ''}</>)
        }
      ]}
      apiProps={{
        api: userApi,
        apiMethodName: 'useLazyUsersQuery'
      }}
    />
  </CardWrapper>
);

export default Users;
