import { memo } from 'react';
import Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import clsx from 'clsx';

import { Typography } from '@mui/material';
import { useStyles } from './styles';

interface IEditorProps {
  onChange?: (res: string) => void,
  onBlur?: (res: string) => void,
  classNameForEditor?: string
  placeholder?: string,
  modules?: {
    [key: string]: any | { [key: string]: any }
  },
  fieldCommentTop?: string,
  fieldCommentBottom?: string,
  error?: string,
  value?: string,
}
const defaultModules: any = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const Editor = ({ onChange, classNameForEditor, placeholder, modules, onBlur, fieldCommentTop, fieldCommentBottom, error, value }: IEditorProps) => {
  const classes = useStyles();
  const handleChange = (result: string) => {
    if (onChange) {
      onChange(result);
    }
  };
  const handleBlur = (result: string) => {
    if (onBlur) {
      onBlur(result);
    }
  };

  return (
    <div className={classes.wrapper_editor}>
      {fieldCommentTop && (
        <Typography
          className={classes.fieldCommentTop}
          color="textSecondary"
          variant="body2"
        >
          {fieldCommentTop}
        </Typography>
      )}
      <Quill
        value={value && value}
        onBlur={(a, b, c) => {
          handleBlur(c.getHTML());
        }}
        onChange={(a) => {
          handleChange(a);
        }}
        theme="snow"
        modules={modules || defaultModules}
        placeholder={placeholder}
        className={(clsx(classes.editor, classNameForEditor, {
          [classes.error]: !!error
        }))}
      />
      {error && (
        <Typography
          color="error"
          variant="body2"
        >
          {error}
        </Typography>
      )}
      {fieldCommentBottom && (
        <Typography
          className={classes.fieldCommentBottom}
          color="textSecondary"
          variant="body2"
        >
          {fieldCommentBottom}
        </Typography>
      )}
    </div>
  );
};

export default memo(Editor);
