import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { TableSearch } from '@monorepo/common';
import { useGetMessageTemplatesBaseQuery } from '../../../services/messageTemplate';
import { ISelectListItem } from '../../../interfaces';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  messageTemplateTypeId: number,
  setMessageTemplateTypeId: (value: number) => void
}

const TableToolBar = ({ onSearch, messageTemplateTypeId, setMessageTemplateTypeId }: ITableToolBarProps) => {
  const { data } = useGetMessageTemplatesBaseQuery('');

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <FormControl fullWidth>
          <InputLabel id="messagetemplatetypes-select-label">Message Template Type</InputLabel>
          <Select
            labelId="salesreps-select-label"
            value={messageTemplateTypeId}
            label="Message Template Type"
            onChange={(event) => setMessageTemplateTypeId(+event.target.value)}
            sx={{ textAlign: 'left' }}
          >
            <MenuItem
              key="-1"
              value="-1"
            >
              All Message Template Types
            </MenuItem>
            {data && data.messageTemplateTypes.map((item: ISelectListItem) => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
