import { useState, useMemo } from 'react';
import { book, CardWrapper, DataGrid } from '@monorepo/common';
import { SelectChangeEvent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { debounce } from 'debounce';
import moment from 'moment';
import TableToolBar from './components/TableToolBar';
import { orderApi } from '../../services/order';
import { IOrder } from '../../services/order/interfaces';
import AdminLink from '../../components/AdminLink';

const Orders = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [salesRep, setSalesRep] = useState(-1);
  const [paymentType, setPaymentType] = useState(-1);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  const handleSalesRepIdChange = (event: SelectChangeEvent<number>) => {
    setSalesRep(+event.target.value);
  };

  const handlePaymentTypeIdChange = (event: SelectChangeEvent<number>) => {
    setPaymentType(+event.target.value);
  };

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value);
  };

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Orders"
      title="Orders"
      maxWidth="100%"
    >
      <DataGrid<IOrder>
        keyExtractor={(col) => col.id.toString()}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            salesRepId={salesRep}
            paymentTypeId={paymentType}
            startDate={startDate}
            endDate={endDate}
            handleSalesRepIdChange={handleSalesRepIdChange}
            handlePaymentTypeIdChange={handlePaymentTypeIdChange}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultSortDirection: 'desc',
          defaultPageSize: 25,
        }}
        columns={[
          {
            field: 'id',
            title: 'ID',
            template: (col) => (
              <AdminLink
                url={book.admin.order(col.id)}
                title={col.id}
              />
            )
          },
          {
            field: 'status',
            title: 'Status',
            hideCurrentSortable: true,
          },
          {
            field: 'orderDate',
            title: 'Order Date',
            template: (col) => <>{col.orderDate ? moment(col.orderDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'shipCompany',
            title: 'Ship Company',
            hideCurrentSortable: true,
          },
          {
            field: 'email',
            title: 'Email',
          },
          {
            field: 'billFullName',
            title: 'Bill Full Name',
            hideCurrentSortable: true,
          },
          {
            field: 'shipFullName',
            title: 'Ship Full Name',
            hideCurrentSortable: true,
          },
          {
            field: 'totalPrice',
            title: 'Total Price',
            isCurrency: true,
          },
          {
            field: 'salesRepName',
            title: 'Sales Rep',
            hideCurrentSortable: true,
          },
          {
            field: 'paymentType',
            title: 'Payment'
          },
          {
            field: 'isBogus',
            title: 'Bogus',
            hideCurrentSortable: true,
            template: (col) => <>{col.isBogus ? <CheckIcon /> : ''}</>
          }
        ]}
        apiProps={{
          api: orderApi,
          apiMethodName: 'useLazyGetOrdersQuery',
          apiParams: {
            searchText,
            salesRepId: salesRep === -1 ? undefined : salesRep,
            paymentTypeId: paymentType === -1 ? undefined : paymentType,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined
          },
        }}
      />
    </CardWrapper>
  );
};

export default Orders;
