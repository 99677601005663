import { memo } from 'react';
import { Button } from '@mui/material';
import { ModalWindow } from '@monorepo/common';

interface IConfirmModalProps {
  showModal: boolean,
  setShowModal: (value: boolean) => void,
  onConfirm: () => void,
  title?: string,
}

const ConfirmModal = ({
  showModal,
  setShowModal,
  onConfirm,
  title
}: IConfirmModalProps) => (
  <ModalWindow
    show={showModal}
    title={title || 'Are you sure?'}
    closeModal={() => setShowModal(false)}
  >
    <Button onClick={onConfirm}>Yes</Button>
    <Button onClick={() => setShowModal(false)}>No</Button>
  </ModalWindow>
);

export default memo(ConfirmModal);
