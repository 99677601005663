import { coreLogApi } from '../services/coreLog';
import { logApi } from '../services/log';
import { licenseApi } from '../services/license';
import { loginApi } from '../services/login';
import { reportApi } from '../services/report';
import { supportContractApi } from '../services/supportContract';
import { userApi } from '../services/user';
import { productApi } from '../services/product';
import { adminUserApi } from '../services/adminUser';
import { quoteApi } from '../services/quote';
import { orderApi } from '../services/order';
import { renewalQuoteApi } from '../services/renewalQuote';
import { messageTemplateApi } from '../services/messageTemplate';
import { onlineAccountApi } from '../services/onlineAccount';
import { applicationApi } from '../services/application';
import { releaseApi } from '../services/release';
import { salesRepApi } from '../services/salesRep';
import { mergeTextApi } from '../services/mergeText';

export const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [
    ...getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'ui/modalWindow/showModalWindow',
        ],
      }
    })
      .concat(loginApi.middleware)
      .concat(reportApi.middleware)
      .concat(supportContractApi.middleware)
      .concat(userApi.middleware)
      .concat(licenseApi.middleware)
      .concat(coreLogApi.middleware)
      .concat(logApi.middleware)
      .concat(productApi.middleware)
      .concat(adminUserApi.middleware)
      .concat(quoteApi.middleware)
      .concat(orderApi.middleware)
      .concat(renewalQuoteApi.middleware)
      .concat(messageTemplateApi.middleware)
      .concat(onlineAccountApi.middleware)
      .concat(applicationApi.middleware)
      .concat(releaseApi.middleware)
      .concat(salesRepApi.middleware)
      .concat(mergeTextApi.middleware)
  ];
  return middlewareList;
};
