import { makeStyles, Theme } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

const drawerWidth = '100%';
const activeColor:string = '#10b975';
const activeBg: string = 'rgba(255, 255, 255, .1)';
export const useStyles = makeStyles((theme: Theme) => ({
  appMenu: {
    width: '100%',
    padding: '1.5rem 10px',
    backgroundColor: '#111827',
    '& $menuItem': {
      width: drawerWidth,
      color: '#9ca3af',
      borderBottom: 5,
      borderRadius: theme.shape.borderRadius,
      paddingTop: 5,
      '&:hover': {
        color: activeColor,
        borderRadius: theme.shape.borderRadius,
        background: activeBg,
        '& *': {
          color: activeColor,
        },
        '& svg': {
          fill: activeColor,
        },
      }
    }
  },
  navList: {
    width: drawerWidth,
  },
  activeHeadItem: {
    '&:hover': {
      background: activeBg,
    },
    '& span': {
      color: activeColor,
      fontWeight: 'bold'
    },
    '& svg': {
      fill: activeColor,
    },
  },
  menuItem: {},
  subItem: {
    display: 'block',
    width: '90%',
    marginLeft: 'auto',
    marginBottom: 5,
    '& $menuItem': {
      marginBottom: 0
    }
  },
  wrapper_for_dropdown: {
    '&::-webkit-scrollbar': { width: '10px', borderRadius: '2rem' },
    '&::-webkit-scrollbar-track': {
      borderRadius: '2rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
    },
  },
  headSubMenu: {
    marginBottom: 5
  },
  link: {
    ...mixin.flex(),
    color: 'inherit',
    textDecoration: 'none',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 5,
    '&:hover': { backgroundColor: activeBg },
    '&.active': {
      color: activeColor,
      fontWeight: 'bold',
      borderRadius: theme.shape.borderRadius,
      background: activeBg,
      '& *': {
        color: activeColor,
        fontWeight: 'bold',
      }
    },
  },
  menuItemIcon: {
    color: '#6b778c',
    marginRight: '1.4rem',
  },
  menuItemText: {},
  list_subheader: {
    color: 'text.primary',
    fontSize: '0.75rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '0 0 0 1rem',
  },
  noIcon: {
    paddingLeft: '1rem',
  },
  wrapperIcon: {
    marginRight: '1rem',
    display: 'flex'
  },
  menu_group: {
    '& + $menu_group': {
      marginTop: 20
    }
  },
  menu_group_title: {
    color: '#65748B',
    textTransform: 'uppercase',
    fontSize: ' 0.75rem',
    fontWeight: 700,
    lineHeight: 2.5,
    padding: '0 14px'
  }
}));
