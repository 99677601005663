import React, { memo } from 'react';
import { List } from '@material-ui/core';

import { IChildrenProps } from '../../interfaces';
import MenuItemHeader from './components/MenuItemHeader';

import { useStyles } from './styles';

interface IMenu extends IChildrenProps {
  titlePart?: string,
}

const Menu = ({
  titlePart,
  children
}:IMenu) => {
  const classes = useStyles();

  return (
    <List
      component="nav"
      className={classes.appMenu}
      disablePadding
      subheader={titlePart ? (
        <MenuItemHeader title={titlePart} />
      ) : <></>}
    >
      {children}

    </List>
  );
};

export default memo(Menu);
