import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, CardWrapper, DataGrid } from '@monorepo/common';
import { debounce, IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { messageTemplateApi, useDeleteMessageTemplateMutation } from '../../services/messageTemplate';
import { IMessageTemplate } from '../../services/messageTemplate/interfaces';
import ConfirmModal from '../../components/ConfirmModal';
import { showNotification } from '../../features/ui/sliceNotification';
import TableToolBar from './components/TableToolBar';

const MessageTemplates = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState<string>('');
  const [messageTemplateTypeId, setMessageTemplateTypeId] = useState(-1);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [deleteMessageTemplate, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteMessageTemplateMutation();

  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  const deleteTemplate = (messageTemplate: IMessageTemplate) => {
    if (messageTemplate.isDefault) {
      dispatch(showNotification({
        text: 'Can\'t delete default template',
        type: 'error',
        show: true
      }));
    } else {
      setIdToDelete(messageTemplate.id);
    }
  };

  const deleteMessageTemplateConfirm = (id: number) => {
    deleteMessageTemplate(id);
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Message Template removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Message Templates"
      title="Message Templates"
      maxWidth="100%"
      isLoading={isDeletingData}
      childrenTitleRight={(
        <Btn link={`${book.admin.messageTemplate()}/new`}>
          Create
        </Btn>
      )}
    >
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteMessageTemplateConfirm(idToDelete)}
      />
      <DataGrid<IMessageTemplate>
        keyExtractor={(col) => col.id.toString()}
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            messageTemplateTypeId={messageTemplateTypeId}
            setMessageTemplateTypeId={setMessageTemplateTypeId}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultPageSize: 25
        }}
        columns={[
          {
            field: 'type',
            title: 'Type',
          },
          {
            field: 'name',
            title: 'Name',
          },
          {
            field: 'subject',
            title: 'Subject',
          },
          {
            field: 'isDefault',
            title: 'Default',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (<>{col.isDefault ? <CheckIcon /> : ''}</>)
          },
          {
            field: 'id',
            title: 'empty',
            align: 'center',
            template: (col) => (
              <>
                <Tooltip title="Edit">
                  <IconButton color="primary">
                    <Link to={book.admin.messageTemplate(col.id)}>
                      <EditIcon />
                    </Link>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => deleteTemplate(col)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
        ]}
        apiProps={{
          api: messageTemplateApi,
          apiMethodName: 'useLazyGetMessageTemplatesQuery',
          apiParams: {
            searchText,
            messageTemplateTypeId: messageTemplateTypeId === -1 ? undefined : messageTemplateTypeId
          },
        }}
      />
    </CardWrapper>
  );
};

export default MessageTemplates;
