import React, { memo } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import { IChildrenProps } from '../../interfaces';

interface IMenuGroupProps extends IChildrenProps {
  title?: string,
  classNameForTitle?: string,
  classNameForWrapper?: string,
}
const MenuGroup = ({ children, title, classNameForTitle, classNameForWrapper }:IMenuGroupProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.menu_group, classNameForWrapper)}>
      {title && <h3 className={clsx(classes.menu_group_title, classNameForTitle)}>{title}</h3>}
      {children}
    </div>
  );
};

export default memo(MenuGroup);
