import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Btn, CardWrapper, DataGrid } from '@monorepo/common';
import { ILog } from '../../services/log/interfaces';
import { showNotification } from '../../features/ui/sliceNotification';
import { logApi, usePostCeateTaskMutation } from '../../services/log';
import { useStyles } from './styles';

const Logs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [postCeateTask, { isLoading, isSuccess: isCreateSuccess }] = usePostCeateTaskMutation();

  const sendToAsana = (logId: string) => {
    postCeateTask({ logId });
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Task created',
        type: 'success',
        show: true
      }));
    }
  }, [isCreateSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      maxWidth="100%"
      helmet="Exception Logs"
      title="Exception Logs"
      isLoading={isLoading}
    >
      <DataGrid<ILog>
        keyExtractor={(col) => col.id}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        defaultGridProps={{
          defaultSortBy: 'date',
          defaultSortDirection: 'desc',
          defaultPageSize: 25
        }}
        collapseProps={{
          render: (item) => (
            <>
              <Btn
                className={classes.send_btn}
                click={() => sendToAsana(item.id)}
              >
                Send to Asana
              </Btn>
              {item.exception}
            </>
          )
        }}
        columns={[
          {
            field: 'message',
            title: 'Message',
            hideCurrentSortable: true,
          },
          {
            field: 'date',
            title: 'Date',
            template: (col) => <>{col.date ? moment(col.date).format('M/D/YYYY') : '-'}</>,
          }
        ]}
        apiProps={{
          api: logApi,
          apiMethodName: 'useLazyGetLogsQuery',
        }}
      />
    </CardWrapper>
  );
};

export default Logs;
