import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { book, Btn, CardWrapper, DataGrid } from '@monorepo/common';
import { IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { debounce } from 'debounce';
import { adminUserApi, useDeleteAdminUserMutation } from '../../services/adminUser';
import { IAdminUser } from '../../services/adminUser/interfaces';
import { showNotification } from '../../features/ui/sliceNotification';
import TableToolBar from './components/TableToolBar';
import ConfirmModal from '../../components/ConfirmModal';

const AdminUsers = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState<string>('');
  const [isActive, setIsActive] = useState(true);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [deleteAdmin, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteAdminUserMutation();

  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  const deleteAdminUserConfirm = (adminUserIdToDelete: number) => {
    deleteAdmin(adminUserIdToDelete);
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Admin User deleted',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Admin Users"
      title="Admin Users"
      maxWidth="100%"
      isLoading={isDeletingData}
      childrenTitleRight={(
        <Btn link={`${book.admin.adminUser()}/new`}>
          Create
        </Btn>
      )}
    >
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteAdminUserConfirm(idToDelete)}
      />
      <DataGrid<IAdminUser>
        keyExtractor={(col) => col.id.toString()}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            isActive={isActive}
            setIsActive={setIsActive}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'name',
          defaultPageSize: 25
        }}
        columns={[
          {
            field: 'name',
            title: 'Name',
          },
          {
            field: 'email',
            title: 'Email',
            hideCurrentSortable: true,
          },
          {
            field: 'isSuperUser',
            title: 'Super User',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (<>{col.isSuperUser ? <CheckIcon /> : ''}</>)
          },
          {
            field: 'hasSlackId',
            title: 'Slack',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (<>{col.hasSlackId ? <CheckIcon /> : ''}</>)
          },
          {
            field: 'hasAsanaId',
            title: 'Asana',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (<>{col.hasAsanaId ? <CheckIcon /> : ''}</>)
          },
          {
            field: 'createdDate',
            title: 'Created Date',
            template: (col) => <>{col.createdDate ? moment(col.createdDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'lastLoginDate',
            title: 'Last Login Date',
            template: (col) => <>{col.lastLoginDate ? moment(col.lastLoginDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'id',
            title: 'empty',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (
              <>
                <Tooltip title="Edit">
                  <IconButton color="primary">
                    <Link to={book.admin.adminUser(col.id)}>
                      <EditIcon />
                    </Link>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => setIdToDelete(col.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
        ]}
        apiProps={{
          api: adminUserApi,
          apiMethodName: 'useLazyGetAdminUsersQuery',
          apiParams: {
            searchText,
            isActive
          },
        }}
      />
    </CardWrapper>
  );
};

export default AdminUsers;
