import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import { IChildrenProps } from '@monorepo/common/src/interfaces';

interface IGridFieldProps extends IChildrenProps {
  label: string,
  labelxs?: number,
  labelmd?: number,
  fieldxs?: number,
  fieldmd?: number,
  wrap?: boolean,
}

const GridField = ({
  children,
  label,
  labelxs,
  labelmd,
  fieldxs,
  fieldmd,
  wrap = false,
}: IGridFieldProps) => (
  <>
    <Grid
      sx={{ fontWeight: 'bold', textAlign: 'right' }}
      item
      xs={labelxs || 4}
      md={labelmd || labelxs || 4}
    >
      <Box sx={{ mt: 1 }}>
        {label}
      </Box>
    </Grid>
    <Grid
      sx={{ textAlign: 'left' }}
      item
      xs={fieldxs || 8}
      md={fieldmd || fieldmd || 8}
    >
      <Box sx={wrap ? { mt: 1, height: '32px' } : {}}>
        {children}
      </Box>
    </Grid>
  </>
);

export default memo(GridField);
