import { useEffect } from 'react';
import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { cookieNames } from '@monorepo/common';
import useRoutes from './routes/RootRouters';
import { useAppSelector } from './app/hooks';
import Notification from './components/Notification';
import { changeStatusAuth } from './features/sliceAuth';

function App() {
  const dispatch = useDispatch();
  const { showNotification } = useAppSelector(({ notification }) => ({
    showNotification: notification.show,
  }));
  const routs = useRoutes();

  useEffect(() => {
    dispatch(changeStatusAuth(!!cookies.get(cookieNames.access_token)));
  }, []);

  return (
    <>
      {showNotification && <Notification />}
      {routs}
    </>
  );
}

export default App;
