import { ChangeEvent } from 'react';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { TableSearch } from '@monorepo/common';
import { useStyles } from '../styles';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  hideExpired: boolean,
  handleHideExpiredChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const TableToolBar = ({ onSearch, hideExpired, handleHideExpiredChange }: ITableToolBarProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        className={classes.switch_wrapper}
        item
        xs={12}
        md={6}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={hideExpired}
              onChange={handleHideExpiredChange}
              name="hideExpired"
            />
          )}
          label="Hide expired"
        />
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
