import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { ISupportContract, ISupportContractListRequest, ISupportContractRequest, ISupportContractDetails, ISupportContractUserRequest } from './interfaces';

export const supportContractApi = createApi({
  reducerPath: 'supportContractApi',
  tagTypes: ['supportContract'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getSupportContracts: build.query<IListResp<ISupportContract>, ISupportContractListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        hideExpired,
        userId
      }) => ({
        url: `${endpoints.supportContracts}`,
        params: { pageSize, page, searchText, sortingOrder, sortBy, hideExpired, userId }
      }),
    }),
    getSupportContract: build.query<ISupportContractDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.supportContract}/${id}`,
      }),
      providesTags: () => ['supportContract'],
    }),
    updateSupportContract: build.mutation<ISupportContractDetails, ISupportContractRequest>({
      query: ({ id, orderId, hostingAccountId, isActive, startDate, endDate }) => ({
        url: `${endpoints.supportContractEdit}/${id}`,
        method: 'PATCH',
        body: {
          orderId,
          hostingAccountId,
          isActive,
          startDate,
          endDate
        }
      }),
      invalidatesTags: ['supportContract'],
    }),
    createSupportContractUser: build.mutation<ISupportContractDetails, ISupportContractUserRequest>({
      query: ({ userId, supportContractId }) => ({
        url: endpoints.supportContractAddUser,
        method: 'POST',
        body: {
          userId,
          supportContractId
        }
      }),
      invalidatesTags: ['supportContract'],
    }),
    deleteSupportContractUser: build.mutation<ISupportContractDetails, number>({
      query: (id: number) => ({
        url: `${endpoints.supportContractDeleteUser}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['supportContract'],
    }),
  }),
});

export const {
  useLazyGetSupportContractsQuery,
  useGetSupportContractQuery,
  useUpdateSupportContractMutation,
  useCreateSupportContractUserMutation,
  useDeleteSupportContractUserMutation
} = supportContractApi;
