import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { book, Btn, CardWrapper } from '@monorepo/common';
import { Box, Grid, MenuItem, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import { useStyles } from './styles';
import { useCreateMessageTemplateMutation, useDeleteMessageTemplateMutation, useGetMessageTemplateQuery, useUpdateMessageTemplateMutation } from '../../services/messageTemplate';
import { IMessageTemplateDetails } from '../../services/messageTemplate/interfaces';
import GridField from '../../components/GridField';
import { showNotification } from '../../features/ui/sliceNotification';
import ConfirmModal from '../../components/ConfirmModal';
import Editor from '../../components/Editor';

const MessageTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id, copyId } = useParams();
  const isNew = id === 'new';

  const [fields, setFields] = useState<IMessageTemplateDetails | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data, isLoading: isLoadingData, refetch } = useGetMessageTemplateQuery(isNew ? copyId || '0' : id!);
  const [updateMessageTemplate, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateMessageTemplateMutation();
  const [createMessageTemplate, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateMessageTemplateMutation();
  const [deleteMessageTemplate, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteMessageTemplateMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create Message Template';
  } else if (data) {
    pageTitle = `Message Template #${data && data.id}`;
  } else {
    pageTitle = 'Message Template not found';
  }

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields!,
      [name]: value
    });
  };

  const save = () => {
    if (isNew) {
      createMessageTemplate(fields!);
    } else {
      updateMessageTemplate(fields!);
    }
  };

  const deleteTemplate = () => {
    if (data?.isDefault) {
      dispatch(showNotification({
        text: 'Can\'t delete default template',
        type: 'error',
        show: true
      }));
    } else {
      setShowDeleteModal(true);
    }
  };

  const deleteMessageTemplateConfirm = () => {
    deleteMessageTemplate(+id!);
    setShowDeleteModal(false);
    navigate(book.admin.messageTemplate());
  };

  useEffect(() => {
    if (data) {
      setFields(data);
    }
  }, [data]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Message Template created',
        type: 'success',
        show: true
      }));
      navigate(book.admin.messageTemplate());
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'Message Template updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Message Template removed',
        type: 'success',
        show: true
      }));
      navigate(book.admin.messageTemplate());
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Message Template"
      title={pageTitle}
      maxWidth="100%"
      isLoading={isLoadingData || isCreatingData || isUpdatingData || isDeletingData}
      childrenTitleRight={fields
        && (
          <Box sx={{ display: 'flex' }}>
            <Btn
              className={classes.inline_btn}
              click={save}
              disabled={!(fields.name?.trim() && fields.toEmail?.trim() && fields.subject?.trim() && fields.body?.trim())}
            >
              Save
            </Btn>
            {!isNew
              && (
                <>
                  <Btn
                    className={classes.inline_btn}
                    link={`${book.admin.messageTemplate()}/new/${id}`}
                  >
                    Copy
                  </Btn>
                  <Btn
                    className={classes.inline_btn}
                    click={deleteTemplate}
                  >
                    Delete
                  </Btn>
                </>
              )}
          </Box>
        )}
    >
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteMessageTemplateConfirm}
      />
      {fields && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Default"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={fields.isDefault || false}
                onChange={(event) => {
                  changeCellField('isDefault', event.target.checked);
                }}
                name="isDefault"
              />
            </GridField>
            <GridField
              label="Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.name || ''}
                fullWidth
                error={!fields.name?.trim()}
                helperText={!fields.name?.trim() && 'The name field is required'}
                onChange={(event) => {
                  changeCellField('name', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Type"
              labelmd={3}
              fieldmd={9}
            >
              <Select
                fullWidth
                value={fields.type}
                size="small"
                onChange={(event: SelectChangeEvent<number>) => changeCellField('type', +event.target.value)}
                sx={{ textAlign: 'left' }}
              >
                {data && data.messageTemplateTypes.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                  >
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </GridField>
            <GridField
              label="From Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.fromName || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('fromName', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="From Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.fromEmail || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('fromEmail', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Allowed message tokens"
              labelmd={3}
              fieldmd={9}
            >
              <Box sx={{ m: 1 }}>
                {data?.messageTemplateTokens.find((t) => t.value === fields.type.toString())?.text}
              </Box>
            </GridField>
            <GridField
              label="To Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.toEmail || ''}
                fullWidth
                error={!fields.toEmail?.trim()}
                helperText={!fields.toEmail?.trim() && 'The to email field is required'}
                onChange={(event) => {
                  changeCellField('toEmail', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Cc Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.ccEmail || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('ccEmail', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Bcc Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.bccEmail || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('bccEmail', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Subject"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.subject || ''}
                fullWidth
                error={!fields.subject?.trim()}
                helperText={!fields.subject?.trim() && 'The subject field is required'}
                onChange={(event) => {
                  changeCellField('subject', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Body"
              labelmd={3}
              fieldmd={9}
            >
              <Editor
                value={fields.body || ''}
                onChange={(res) => changeCellField('body', res)}
                error={fields.body?.trim() ? '' : 'The body field is required'}
              />
            </GridField>
          </Grid>
        </Box>
      )}
    </CardWrapper>
  );
};

export default MessageTemplate;
