import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Paper, Toolbar, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Autocomplete, debounce, Box, TextField, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Btn, Loader } from '@monorepo/common';
import { useStyles } from '../../Order/styles';
import { IOnlineAccountLimit, IProductPlanLimit } from '../../../services/onlineAccount/interfaces';
import { useCreateOnlineAccountLimitMutation, useDeleteOnlineAccountLimitMutation, useGetProductPlanLimitsQuery, useUpdateOnlineAccountLimitMutation } from '../../../services/onlineAccount';
import { showNotification } from '../../../features/ui/sliceNotification';
import ConfirmModal from '../../../components/ConfirmModal';

interface IOnlineAccountLimitsProps {
  region: string,
  id: number,
  items: IOnlineAccountLimit[],
}

const OnlineAccountLimits = ({ region, id, items }: IOnlineAccountLimitsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editableRow, setEditableRow] = useState<number>(-1);
  const [limitSearchText, setLimitSearchText] = useState<string>('');
  const [isLimitSearchModifyed, setIsLimitSearchModifyed] = useState<boolean>(false);
  const [limitNameToDelete, setLimitNameToDelete] = useState<string>('');

  const [limit, setLimit] = useState<IOnlineAccountLimit>({} as IOnlineAccountLimit);

  const { isFetching: isFetchingLimitsData, data: limits } = useGetProductPlanLimitsQuery(limitSearchText, { skip: !isLimitSearchModifyed || !limitSearchText });
  const [createLimit, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateOnlineAccountLimitMutation();
  const [updateLimit, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateOnlineAccountLimitMutation();
  const [deleteLimit, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteOnlineAccountLimitMutation();

  const onSearchLimitText = useMemo(() => debounce((value: string) => {
    setLimitSearchText(value);
    setIsLimitSearchModifyed(true);
  }, 800), []);

  const onSelectLimit = (value: IProductPlanLimit | string | null) => {
    if (value && typeof value !== 'string') {
      setLimitSearchText(value.limitName);
      setLimit({
        productPlanLimitId: value.id,
        limitName: value.limitName,
        limitValue: +value.limitValue,
        contextLimitValue: value.limitValue,
        contextLimitBase: value.limitValue,
      });
    }
  };

  const changeCellField = (name: string, value?: any) => {
    setLimit({
      ...limit,
      [name]: value
    });
  };

  const addEmptyRow = () => {
    setEditableRow(0);
    setLimitSearchText('');
    setLimit({
      productPlanLimitId: 0,
      limitName: '',
      limitValue: 0,
      contextLimitValue: '',
      contextLimitBase: '',
    });
  };

  const addLimit = () => {
    if (limit.limitName === '' || limitSearchText === '' || limit.contextLimitValue === '' || limit.contextLimitBase === '') {
      setIsLimitSearchModifyed(true);
      return;
    }

    createLimit({
      region,
      accountId: id,
      ...limit,
    });
  };

  const saveLimit = () => {
    if (limit.limitName === '' || limitSearchText === '' || limit.contextLimitValue === '' || limit.contextLimitBase === '') {
      setIsLimitSearchModifyed(true);
      return;
    }

    updateLimit({
      region,
      accountId: id,
      name: items[editableRow - 1].limitName,
      ...limit,
    });
  };

  const editLimit = (index: number) => {
    setLimit(items[index]);
    setLimitSearchText(items[index].limitName);
    setEditableRow(index + 1);
  };

  const deleteLimitConfirm = (name: string) => {
    deleteLimit({
      region,
      accountId: id,
      name,
    });
    setLimitNameToDelete('');
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Context Limit created',
        type: 'success',
        show: true
      }));
      setEditableRow(-1);
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Context Limit updated',
        type: 'success',
        show: true
      }));
      setEditableRow(-1);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Context Limit removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <>
      {(isCreatingData || isUpdatingData || isDeletingData) && (
        <Loader
          position="absolute"
          size={70}
          blur
        />
      )}
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Limits
        </Typography>
        <Btn
          className={classes.btn_add}
          click={addEmptyRow}
        >
          Add Context Limit
        </Btn>
      </Toolbar>
      <ConfirmModal
        showModal={!!limitNameToDelete}
        setShowModal={(value: boolean) => setLimitNameToDelete(value ? limitNameToDelete : '')}
        onConfirm={() => deleteLimitConfirm(limitNameToDelete)}
      />
      <TableContainer component={Paper}>
        <Table className={classes.striped_table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              <TableCell>Limit Name</TableCell>
              <TableCell>Limit Value</TableCell>
              <TableCell>Context Limit Value</TableCell>
              <TableCell>Context Limit Base</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editableRow === 0 && (
              <TableRow key={0}>
                <TableCell sx={{ width: '200px' }}>
                  <Autocomplete
                    sx={{ width: 200 }}
                    value={{ limitName: limitSearchText } as IProductPlanLimit}
                    getOptionLabel={(option) => option.limitName}
                    options={limits || []}
                    loading={isFetchingLimitsData}
                    onChange={(event, value) => onSelectLimit(value)}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === 'clear') {
                        onSearchLimitText(newInputValue);
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        key={option.limitName}
                      >
                        {option.limitName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={!limitSearchText.trim() && isLimitSearchModifyed}
                        helperText={!limitSearchText.trim() && isLimitSearchModifyed && 'The limit name field is required'}
                        onChange={(event) => onSearchLimitText(event.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isFetchingLimitsData ? (
                                <CircularProgress
                                  color="inherit"
                                  size={20}
                                  sx={{ mr: 4 }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ width: '180px' }}>
                  <TextField
                    value={limit.limitValue || ''}
                    size="small"
                    type="number"
                    onChange={(val) => changeCellField('limitValue', +val.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={limit.contextLimitValue || ''}
                    size="small"
                    error={!limit.contextLimitValue}
                    helperText={!limit.contextLimitValue && 'The context limit value field is required'}
                    onChange={(val) => changeCellField('contextLimitValue', val.target.value || '')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={limit.contextLimitBase || ''}
                    size="small"
                    error={!limit.contextLimitBase}
                    helperText={!limit.contextLimitBase && 'The context limit base field is required'}
                    onChange={(val) => changeCellField('contextLimitBase', val.target.value || '')}
                  />
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Tooltip title="Save">
                    <IconButton
                      onClick={() => addLimit()}
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => setEditableRow(-1)}
                      color="error"
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {items.map((col, index) => (
              <TableRow key={col.limitName}>
                <TableCell sx={{ width: '200px' }}>
                  {editableRow === index + 1 ? (
                    <Autocomplete
                      sx={{ width: 200 }}
                      value={{ limitName: limitSearchText } as IProductPlanLimit}
                      getOptionLabel={(option) => option.limitName}
                      options={limits || []}
                      loading={isFetchingLimitsData}
                      onChange={(event, value) => onSelectLimit(value)}
                      onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'clear') {
                          onSearchLimitText(newInputValue);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          key={option.limitName}
                        >
                          {option.limitName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={!limitSearchText.trim() && isLimitSearchModifyed}
                          helperText={!limitSearchText.trim() && isLimitSearchModifyed && 'The limit name field is required'}
                          onChange={(event) => onSearchLimitText(event.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isFetchingLimitsData ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                    sx={{ mr: 4 }}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    col.limitName
                  )}
                </TableCell>
                <TableCell sx={{ width: '180px' }}>
                  {editableRow === index + 1 ? (
                    <TextField
                      value={limit.limitValue || ''}
                      size="small"
                      type="number"
                      onChange={(val) => changeCellField('limitValue', +val.target.value)}
                    />
                  ) : (
                    col.limitValue
                  )}
                </TableCell>
                <TableCell>
                  {editableRow === index + 1 ? (
                    <TextField
                      fullWidth
                      value={limit.contextLimitValue || ''}
                      size="small"
                      error={!limit.contextLimitValue}
                      helperText={!limit.contextLimitValue && 'The context limit value field is required'}
                      onChange={(val) => changeCellField('contextLimitValue', val.target.value || '')}
                    />
                  ) : (
                    <>
                      {col.contextLimitValue}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {editableRow === index + 1 ? (
                    <TextField
                      fullWidth
                      value={limit.contextLimitBase || ''}
                      size="small"
                      error={!limit.contextLimitBase}
                      helperText={!limit.contextLimitBase && 'The context limit base field is required'}
                      onChange={(val) => changeCellField('contextLimitBase', val.target.value || '')}
                    />
                  ) : (
                    <>
                      {col.contextLimitBase}
                    </>
                  )}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {editableRow === index + 1 ? (
                    <>
                      <Tooltip title="Save">
                        <IconButton
                          onClick={() => saveLimit()}
                          color="primary"
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton
                          onClick={() => setEditableRow(-1)}
                          color="error"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => editLimit(index)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => setLimitNameToDelete(col.limitName)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OnlineAccountLimits;
