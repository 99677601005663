import React, { memo, ReactNode } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from '../styles';

interface MenuItemProps {
  title: string,
  subItem?: boolean,
  icon?: ReactNode,
  href?: string,
  click?: () => void,
  externalLink?: boolean,
  target?: '_blank' | '_self' | '_parent' | '_top',
  classNameFotItem?: string
}

const MenuItem = ({ href, icon, title, click, classNameFotItem, subItem, externalLink, target }: MenuItemProps) => {
  const classes = useStyles();
  const itemClick = () => {
    if (click) {
      click();
    }
  };

  if (href) {
    if (!externalLink) {
      return (
        <NavLink
          to={href}
          className={clsx(classes.link, classNameFotItem, {
            [classes.subItem]: subItem
          })}
        >
          <ListItem
            button
            className={clsx(classes.menuItem)}
          >
            {icon && (
            <div className={classes.wrapperIcon}>
              {icon}
            </div>
            )}
            <ListItemText
              primary={title}
              className={clsx(!icon && classes.menuItemText, classNameFotItem)}
            />
          </ListItem>
        </NavLink>
      );
    }
    return (
      <a
        target={target && target}
        href={href}
        className={clsx(classes.link, classNameFotItem, {
          [classes.subItem]: subItem
        })}
      >
        <ListItem
          button
          className={clsx(classes.menuItem)}
        >
          {icon && (
            <div className={classes.wrapperIcon}>
              {icon}
            </div>
          )}
          <ListItemText
            primary={title}
            className={clsx(!icon && classes.menuItemText, classNameFotItem)}
          />
        </ListItem>
      </a>
    );
  }

  return (
    <ListItem
      button
      onClick={itemClick}
      className={clsx(classes.menuItem)}
    >
      {icon && icon}
      <ListItemText
        primary={title}
        className={clsx(!icon && classes.menuItemText, classNameFotItem, {
          [classes.noIcon]: !icon
        })}
      />
    </ListItem>
  );
};

export default memo(MenuItem);
