import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { useStyles } from '../styles';

// interface IEmptyTableProps {}
const EmptyTable = () => {
  const classes = useStyles();
  return (
    <div className={classes.table_empty}>
      <Typography
        variant="h4"
        color="#a5a5a5"
        fontWeight={600}
      >
        No data
      </Typography>
    </div>
  );
};

export default memo(EmptyTable);
