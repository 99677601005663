import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { TableSearch } from '@monorepo/common';
import { ISelectListItem } from '../../../interfaces';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  site: string,
  handleSiteChange: (event: SelectChangeEvent<string>) => void
}

const TableToolBar = ({ onSearch, site, handleSiteChange }: ITableToolBarProps) => {
  const sites = [{ text: 'All sites', value: '-1' }, { text: 'Admin', value: '0' }, { text: 'Checkbox API', value: '1' }] as ISelectListItem[];

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <FormControl fullWidth>
          <InputLabel id="site-select-label">Site</InputLabel>
          <Select
            labelId="site-select-label"
            value={site}
            label="Site"
            onChange={handleSiteChange}
            sx={{ textAlign: 'left' }}
          >
            {sites && sites.map((item: ISelectListItem) => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
