import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, CardWrapper } from '@monorepo/common';
import { Autocomplete, Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { useStyles } from './styles';
import { useGetLicenseQuery, useGetLicenseLimitTypesQuery, useUpdateLicenseLimitMutation, useDeleteLicenseLimitMutation } from '../../services/license';
import { ILicenseLimit } from '../../services/license/interfaces';
import { ISelectListItem } from '../../interfaces';
import { showNotification } from '../../features/ui/sliceNotification';
import { Permission } from '../../constants/permission';
import GridField from '../../components/GridField';
import AdminLink from '../../components/AdminLink';
import ConfirmModal from '../../components/ConfirmModal';

const License = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { serialNumber } = useParams();

  const { data, isLoading: isLoadingData, refetch } = useGetLicenseQuery(serialNumber || '', { skip: !serialNumber });
  const { data: limitTypes, isLoading: isLoadingLimitTypesData } = useGetLicenseLimitTypesQuery('');
  const [putLicenseLimit, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateLicenseLimitMutation();
  const [deleteLicenseLimit, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteLicenseLimitMutation();

  const pageTitle = data ? `License #${data && data.serialNumber}` : 'License not found';
  const [licenseLimits, setLicenseLimits] = useState<ILicenseLimit[]>([]);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const saveLimit = (index: number) => {
    const limit = licenseLimits[index];

    putLicenseLimit({
      serialNumber: serialNumber!,
      id: limit.id,
      type: limit.type,
      value: limit.value
    });
  };

  const deleteLicenseLimitConfirm = (limitIdToDelete: number) => {
    deleteLicenseLimit(limitIdToDelete);
    setIdToDelete(0);
  };

  const getLicenseLimitType = (type: number) => limitTypes!.find((t) => t.value === `${type}`);

  const editLimit = (index: number, isEditable: boolean) => {
    const limits: ILicenseLimit[] = [];

    licenseLimits.forEach((l, i) => {
      const limit = { ...l };

      if (i === index) {
        limit.isEditable = isEditable;
      } else if (l.isEditable && i !== index) {
        limit.isEditable = !isEditable;
      }

      if (l.id !== 0) {
        limits.push(limit);
      }
    });

    setLicenseLimits(limits);
  };

  const modifyLimit = (index: number, obj: any) => {
    const limits = [...licenseLimits];
    const limit = limits[index];

    limits[index] = { ...limit, ...obj };
    setLicenseLimits(limits);
  };

  const addLimit = () => {
    const limits = [...licenseLimits];
    limits.forEach((l, i) => {
      if (l.isEditable) {
        const editableLimit = { ...licenseLimits[i], ...{ isEditable: false } };
        limits[i] = editableLimit;
      }
    });
    setLicenseLimits([{ id: 0, value: '', isEditable: true } as unknown as ILicenseLimit, ...limits]);
  };

  useEffect(() => {
    if (data) {
      setLicenseLimits(data.licenseLimits);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'License Limit updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'License Limit removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="License"
      title={pageTitle}
      isLoading={isLoadingData || isUpdatingData || isDeletingData || isLoadingLimitTypesData}
    >
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteLicenseLimitConfirm(idToDelete)}
      />
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Active"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data.isActive ? <CheckIcon /> : ''}
            </GridField>
            <GridField
              label="Product"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data.productName}
            </GridField>
            <GridField
              label="Customer"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {`${data.firstName} ${data.lastName} ${data.companyName}`}
            </GridField>
            <GridField
              label="Order"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              <AdminLink
                url={book.admin.order(data.orderId)}
                title={data.orderId}
                permissions={[Permission.Orders]}
              />
            </GridField>
            <GridField
              label="Contract"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              <AdminLink
                url={book.admin.supportContract(data.supportContractId)}
                title={data.supportContractId}
                permissions={[Permission.SupportContracts]}
              />
            </GridField>
            <GridField
              label="Order Date"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {moment(data.orderDate)?.format('YYYY-MM-DD')}
            </GridField>
            <GridField
              label="Start Date"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {moment(data.startDate)?.format('YYYY-MM-DD')}
            </GridField>
            <GridField
              label="End Date"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {moment(data.endDate)?.format('YYYY-MM-DD')}
            </GridField>
          </Grid>
          <Toolbar sx={{ mt: 2 }}>
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              component="div"
            >
              License Limits
            </Typography>
            <Btn
              className={classes.inline_btn}
              click={addLimit}
            >
              Add limit
            </Btn>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table className={classes.striped_table}>
              <TableHead className={classes.table_head}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licenseLimits.map((limit: ILicenseLimit, index: number) => (
                  <TableRow
                    key={limit.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ width: 40 }}>
                      {limit.isEditable ? (
                        <Autocomplete
                          disablePortal
                          size="small"
                          sx={{ width: 200 }}
                          value={getLicenseLimitType(limit.type)}
                          options={limitTypes!}
                          getOptionLabel={(o: ISelectListItem) => o.text}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(event, newValue) => {
                            modifyLimit(index, { type: +newValue!.value });
                          }}
                        />
                      ) : (
                        <Box>
                          {getLicenseLimitType(limit.type)?.text}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell style={{ width: 100 }}>
                      {limit.isEditable ? (
                        <TextField
                          value={limit.value || ''}
                          onChange={(event) => {
                            modifyLimit(index, { value: event.target.value });
                          }}
                          size="small"
                        />
                      ) : (
                        <Box>
                          {limit.value}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell style={{ width: 20 }}>
                      {limit.isEditable ? (
                        <>
                          <Button onClick={() => saveLimit(index)}>
                            Save
                          </Button>
                          <Button onClick={() => editLimit(index, false)}>
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => editLimit(index, true)}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => setIdToDelete(limit.id)}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </CardWrapper>
  );
};

export default License;
