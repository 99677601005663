import React, { useCallback, useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import MediaQuery from 'react-responsive';

import TableCustomHeadCell from './TableCustomHeadCell';
import { getRowId } from '../utils/getRowId';
import {
   ColSortDirections,
   ICollapseProps,
   IColumnsProps,
   ICurrentRowTotalValue,
   IEvents,
   IStyleProps,
   ITableChildren,
   ITablePropsPositionElement
} from '../gridInterfaces';
import { isEvenNumber } from '../utils/isEvenNumber';
import TableFooterMobile from './TableFooterMobile';
import TableCollapsePanel from './TableCollapsePanel';
import { useStyles } from '../styles';

interface IGridDesktopProps<T> extends ICurrentRowTotalValue, ITableChildren {
   collapseProps?: ICollapseProps<T>
   data: {
      result: T[],
      total: number
   } | null,
   events?: IEvents,
   sortable?: boolean;
   styleProps?: IStyleProps;
   globalId?: number | null;
   activeCol: keyof T | null;
   columns: IColumnsProps<T>[];
   isVisibleTableFooter?: boolean;
   activeColDirection: ColSortDirections;
   getCurrentCol: (el: keyof T) => void;
   tableProps?: ITablePropsPositionElement;
}
const GridMobile = <T extends unknown>({
   collapseProps,
   tableProps,
   children,
   data,
   events,
   activeCol,
   columns = [],
   getCurrentCol,
   styleProps = {
      isSelectedEven: false,
      isSelectedOdd: false,
      isHover: false
   },
   globalId = null,
   sortable = false,
   activeColDirection,
   isVisibleTableFooter = false,
   getCurrentRowTotalValue
}: IGridDesktopProps<T>) => {
   const classes = useStyles();
   const [activeId, setActiveId] = useState<number | null>(null);

   const toggle = useCallback((i: number | null) => {
      if (i === activeId) {
         setActiveId(null);
         return;
      }
      setActiveId(i);
   }, [activeId]);

   return (
      <MediaQuery maxWidth={1023}>
         {!tableProps?.hideTableBody && (
            <TableBody
               className={clsx(styleProps.classNameForTable)}
            >
               {/* for visible display total count */}
               {isVisibleTableFooter && data && (
                  <TableFooterMobile
                     getCurrentRowTotalValue={getCurrentRowTotalValue}
                     columns={columns}
                     data={data}
                  />
               )}
            </TableBody>
         )}

         {!tableProps?.hideTableBody && data?.result.map((item: T, index: number) => (
            <TableBody
               className={classes.table_body}
               key={`body-${index.toString()}`}
            >
               {columns.map((col, i) => {
                  if ((globalId && getRowId(columns, item)) && (getRowId(columns, item) === globalId)) {
                     return (
                        <TableRow
                           hover={styleProps.isHover}
                           key={`${index.toString()}-${i.toString()}`}
                           selected={events?.isRowSelected && events?.isRowSelected(item)}
                           onClick={() => {
                              // for outside click
                              if (events?.onRowClick) {
                                 events.onRowClick(item);
                              }
                           }}
                           className={clsx(classes.td_equal, {
                              [classes.selectedRow]: isEvenNumber(index)
                           })}
                        >
                           {col.title !== 'empty' && (
                              <TableCustomHeadCell<T>
                                 hideCurrentSortable={col.hideCurrentSortable}
                                 field={col.field}
                                 sortable={sortable}
                                 activeCol={activeCol}
                                 key={`${index.toString()}_${col.field}`}
                                 getCurrentCol={getCurrentCol}
                                 align="left"
                                 width={col.width && col.width}
                                 title={col.title !== 'empty' ? (col.title || col.field) : 'empty'}
                                 className={clsx(col.className)}
                                 activeColDirection={activeColDirection}
                              />
                           )}
                           <TableCell colSpan={(col.title === 'empty') ? 2 : 1}>
                              {col.editTemplate && col.editTemplate(item)}
                           </TableCell>
                        </TableRow>
                     );
                  }

                  return (
                     <TableRow
                        hover={styleProps.isHover}
                        key={`${index.toString()}-${i.toString()}`}
                        selected={events?.isRowSelected && events?.isRowSelected(item)}
                        onClick={() => {
                           // for outside click
                           if (events?.onRowClick) {
                              events.onRowClick(item);
                           }
                        }}
                        className={clsx(classes.td_equal, {
                           [classes.selectedRow]: isEvenNumber(index)
                        })}
                     >
                        {col.title !== 'empty' && (
                           <TableCustomHeadCell<T>
                              hideCurrentSortable={col.hideCurrentSortable}
                              field={col.field}
                              sortable={sortable}
                              activeCol={activeCol}
                              key={`${index.toString()}_${col.field}`}
                              getCurrentCol={getCurrentCol}
                              align="left"
                              width={col.width && col.width}
                              title={col.title !== 'empty' ? (col.title || col.field) : 'empty'}
                              className={clsx(col.className)}
                              activeColDirection={activeColDirection}
                           />
                        )}
                        <TableCell colSpan={(col.title === 'empty') ? 2 : 1}>
                           {typeof col.template === 'function' && col.template(item)}
                           {(typeof col.template !== 'function') && (col.field ? item[col.field] : '-')}
                        </TableCell>
                     </TableRow>
                  );
               })}
               {collapseProps && (
                  <TableCollapsePanel
                     colSpan={columns.length}
                     activeId={activeId}
                     toggle={toggle}
                     index={index}
                     renderElement={collapseProps.render(item)}
                  />
               )}
            </TableBody>
         ))}
         {children && (
            <TableBody
               className={clsx(styleProps.classNameForTable)}
            >
               <TableRow>
                  <TableCell
                     colSpan={columns.length}
                     style={{ padding: 0 }}
                  >
                     {children}
                  </TableCell>
               </TableRow>
            </TableBody>
         )}
      </MediaQuery>
   );
};

export default GridMobile;
