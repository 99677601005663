import { FormControlLabel, Grid, Switch } from '@mui/material';
import { TableSearch } from '@monorepo/common';
import { useStyles } from '../styles';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  isActive: boolean,
  setIsActive: (a: boolean) => void
}

const TableToolBar = ({ onSearch, isActive, setIsActive }: ITableToolBarProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        className={classes.switch_wrapper}
        item
        xs={12}
        md={6}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={isActive}
              onChange={(event) => setIsActive(event.target.checked)}
              name="isActive"
            />
          )}
          label="Active"
        />
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
