import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';

import clsx from 'clsx';
import { total } from '../utils/sumall';
import { ICurrentRowTotalValue, ITotal } from '../gridInterfaces';
import TableFooterCell from './TableFooterCell';
import { currencyFormatter } from '../../../utils/currencyFormatter';

interface ITableFooterDesktopProps<T> extends ITotal<T>, ICurrentRowTotalValue {}
type IAmount = {
  [key: string]: number
};
export const TableFooterDesktop = <T extends unknown>({ data, columns, getCurrentRowTotalValue }:ITableFooterDesktopProps<T>) => {
  const [isAllAmounts, setIsAllAmounts] = useState<IAmount>({});
  const getAmounts = (value:IAmount) => {
    setIsAllAmounts((el) => ({
      ...el,
      ...value
    }));
  };
  const finalFormat = ({ isQuantity, val }:{ val: number; isQuantity: boolean }):string => {
    if (isQuantity) {
      return val.toString();
    }
    return currencyFormatter.format(val) as string;
  };

  useEffect(() => {
    if (getCurrentRowTotalValue) {
      getCurrentRowTotalValue(isAllAmounts);
    }
  }, [isAllAmounts, getCurrentRowTotalValue]);

  return (
     <TableRow>
        {columns.map((col, index) => {
          if (col.footerProps) {
            return (
               <TableFooterCell
                  width={col?.width}
                  totalText={col.footerProps.totalText}
                  nameCol={col.field as string}
                  setAllAmounts={() => {
                    if (col.footerProps?.showTotalAmount) {
                      getAmounts({ [col.field as string]: total(data.result, col.field as string) as number });
                    }
                  }}
                  key={`footerCell-${index.toString()}`}
                  align={col.footerProps.align}
                  color={col.footerProps.color}
                  nativeValue={!col.footerProps?.customTotalAmount
                    ? (data && total(data.result, col.field as string))
                    : Number(col.footerProps.customTotalAmount)}
                  value={!col.footerProps?.customTotalAmount
                    ? (
                      data && finalFormat({ val: total(data.result, col.field as string), isQuantity: !!col.footerProps.isQuantity })
                    )
                    : (
                      finalFormat({ val: Number(col.footerProps.customTotalAmount), isQuantity: !!col.footerProps.isQuantity })
                    )}
                  colorIndicator={col.footerProps.colorIndicator}
                  customElement={col.footerProps.customElement}
               />
            );
          }
          return (
             <TableCell
                align={col?.align}
                {...(col?.width) && {
                  width: col.width
                }}
                className={clsx(col?.className)}
                key={`__${index.toString()}`}
                style={{ border: 0 }}
             />
          );
        })}
     </TableRow>
  );
};
