import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListRequest, IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IMergeText, IMergeTextDetails } from './interfaces';

export const mergeTextApi = createApi({
  reducerPath: 'mergeTextApi',
  tagTypes: ['mergeText'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getMergeTexts: build.query<IListResp<IMergeText>, IListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
      }) => ({
        url: endpoints.mergeTexts,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
        }
      }),
      providesTags: () => ['mergeText']
    }),
    getMergeText: build.query<IMergeTextDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.mergeText}/${id}`,
      }),
      providesTags: () => ['mergeText']
    }),
    updateMergeText: build.mutation<IMergeTextDetails, IMergeTextDetails>({
      query: ({
        id,
        type,
        label,
        text,
      }) => ({
        url: `${endpoints.mergeTextEdit}/${id}`,
        method: 'PATCH',
        body: {
          type,
          label,
          text,
        }
      }),
      invalidatesTags: ['mergeText']
    }),
  }),
});

export const {
  useLazyGetMergeTextsQuery,
  useGetMergeTextQuery,
  useUpdateMergeTextMutation,
} = mergeTextApi;
