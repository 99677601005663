interface IBook {
  readonly admin: {
    dashboard: string,
    account: {
      login: string,
    },
    orderExports: string,
    activeContractMailingList: string,
    supportContract: (id?: number) => string;
    adminUser: (id?: number) => string;
    user: (id?: number) => string;
    license: (serialNumber?: string) => string;
    product: (id?: number) => string,
    coreLog: string,
    log: string,
    quote: (id?: number) => string;
    renewalQuote: (id?: number) => string;
    upForRenewal: string,
    messageTemplate: (id?: number, copyId?: number) => string;
    renewalEmails: string;
    earlyRenewalEmails: string;
    expiredHostedEmails: string;
    expiredServerEmails: string;
    order: (id?: number) => string;
    licensingTool: string,
    onlineAccount: (region?: string, id?: number) => string;
    application: (id?: number) => string;
    release: (id?: number) => string;
    salesRep: (id?: number) => string;
    mergeText: (id?: number) => string;
  },
  readonly signup: {
    home: (trialGuid?: string, code?: string) => string;
  }
}

export const book: IBook = {
  admin: {
    dashboard: '/',
    account: {
      login: '/login',
    },
    orderExports: '/order-exports',
    activeContractMailingList: '/active-contract-mailing',
    supportContract: (id) => (id
      ? `/support-contract/${id}`
      : '/support-contract'),
    adminUser: (id) => (id
      ? `/admin-user/${id}`
      : '/admin-user'),
    user: (id) => (id
      ? `/user/${id}`
      : '/user'),
    license: (serialNumber) => (serialNumber
      ? `/license/${serialNumber}`
      : '/license'),
    product: (id) => (id
      ? `/product/${id}`
      : '/product'),
    coreLog: '/core-log',
    log: '/log',
    quote: (id) => (id
      ? `/quote/${id}`
      : '/quote'),
    renewalQuote: (id) => (id
      ? `/renewal-quote/${id}`
      : '/renewal-quote'),
    upForRenewal: '/up-for-renewal',
    messageTemplate: (id) => (id
      ? `/message-template/${id}`
      : '/message-template'),
    renewalEmails: '/renewal-emails',
    earlyRenewalEmails: '/early-renewal-emails',
    expiredHostedEmails: '/expired-hosted-emails',
    expiredServerEmails: '/expired-server-emails',
    order: (id) => (id
      ? `/order/${id}`
      : '/order'),
    licensingTool: 'license-tool',
    onlineAccount: (region, id) => (id
      ? `/online-account/${region}/${id}`
      : '/online-account'),
    application: (id) => (id
      ? `/application/${id}`
      : '/application'),
    release: (id) => (id
      ? `/release/${id}`
      : '/release'),
    salesRep: (id) => (id
      ? `/sales-rep/${id}`
      : '/sales-rep'),
    mergeText: (id) => (id
      ? `/merge-text/${id}`
      : '/merge-text'),
  },
  signup: {
    home: (trialGuid, code) => (trialGuid && code
      ? `/${trialGuid}/${code}`
      : '/'),
  }
};
