import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Paper, Toolbar, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Autocomplete, TextField, CircularProgress, createFilterOptions, debounce, Switch, Tooltip, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Btn, Loader } from '@monorepo/common';
import { currencyFormatter } from '@monorepo/common/src/utils/currencyFormatter';
import { IQuoteProductDetail } from '../../../services/quote/interfaces';
import { useStyles } from '../styles';
import { useGetProductsQuery } from '../../../services/product';
import { IProductItem } from '../../../services/product/interfaces';
import { useCreateQuoteDetailMutation, useDeleteQuoteDetailMutation, useUpdateQuoteDetailMutation } from '../../../services/quote';
import { showNotification } from '../../../features/ui/sliceNotification';
import ConfirmModal from '../../../components/ConfirmModal';

interface IQuoteDetailsProps {
  id: number,
  items: IQuoteProductDetail[],
  taxRate: number,
  quoteDetailsChange: (quoteDetails: IQuoteProductDetail[]) => void,
}

const QuoteDetails = ({ id, items, taxRate, quoteDetailsChange }: IQuoteDetailsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [productSearchText, setProductSearchText] = useState<string>('');
  const [isProductSearchModifyed, setIsProductSearchModifyed] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [quoteDetail, setQuoteDetail] = useState<IQuoteProductDetail>({} as IQuoteProductDetail);

  const { isFetching: isFetchingProductsData, data: products } = useGetProductsQuery({ searchText: productSearchText, page: 1, pageSize: 100, sortingOrder: 'desc', sortBy: 'id', isActive: true, includeDetails: true }, { skip: !isProductSearchModifyed || !productSearchText });
  const [createQuoteDetail, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateQuoteDetailMutation();
  const [updateQuoteDetail, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateQuoteDetailMutation();
  const [deleteQuoteDetail, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteQuoteDetailMutation();

  const onSearchProductText = useMemo(() => debounce((value: string) => {
    setProductSearchText(value);
    setIsProductSearchModifyed(true);
  }, 800), []);

  const onSelectProduct = (value: IProductItem | string | null) => {
    if (value && typeof value !== 'string') {
      setProductSearchText(value.partNumber);
      setQuoteDetail({
        ...quoteDetail,
        productId: value.id,
        productName: value.name,
        partNumber: value.partNumber,
        description: value.description || '',
        price: value.price,
        extPrice: value.price
      });
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: IProductItem) => option.partNumber + option.name,
  });

  const getEmptyQuoteDetail = () => ({
    id: 0,
    productId: 0,
    partNumber: '',
    productName: '',
    description: '',
    qty: 1,
    price: 0,
    extPrice: 0,
    showListPrice: false,
  } as IQuoteProductDetail);

  const changeCellField = (name: string, value?: any) => {
    setQuoteDetail({
      ...quoteDetail,
      [name]: value
    });
  };

  const calculateTotal = () => items.reduce((partialSum, d) => partialSum + d.extPrice, 0);

  const calculateSalesTax = () => (calculateTotal() * taxRate) / 100;

  const addEmptyRow = () => {
    setEditableRow(0);
    setQuoteDetail(getEmptyQuoteDetail());
    setProductSearchText('');
  };

  const saveQuoteDetail = () => {
    if (quoteDetail.partNumber === '' || !quoteDetail.productId) {
      setIsProductSearchModifyed(true);
      return;
    }

    if (id === 0) {
      quoteDetail.extPrice = (quoteDetail.price || 0) * quoteDetail.qty;

      if (quoteDetail.id) {
        const i = items.findIndex((d) => d.id === quoteDetail.id);
        items[i] = quoteDetail;
      } else {
        items.push({
          ...quoteDetail,
          id: ([...items].pop()?.id || 0) + 1,
        });
      }

      quoteDetailsChange(items);
      setEditableRow(-1);
      return;
    }

    if (quoteDetail.id) {
      updateQuoteDetail({
        ...quoteDetail,
        extPrice: (quoteDetail.price || 0) * quoteDetail.qty,
      });
    } else {
      createQuoteDetail({
        ...quoteDetail,
        extPrice: (quoteDetail.price || 0) * quoteDetail.qty,
        quoteId: id,
      });
    }
  };

  const editQuoteDetail = (index: number) => {
    setQuoteDetail(items[index]);
    setProductSearchText(items[index].partNumber);
    setEditableRow(index + 1);
  };

  const deleteQuoteDetailConfirm = (quoteDetailId: number) => {
    deleteQuoteDetail(quoteDetailId);
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Quote Detail created',
        type: 'success',
        show: true
      }));
      setEditableRow(-1);
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Quote Detail updated',
        type: 'success',
        show: true
      }));
      setEditableRow(-1);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Quote Detail removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <>
      {(isCreatingData || isUpdatingData || isDeletingData) && (
        <Loader
          position="absolute"
          size={70}
          blur
        />
      )}
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteQuoteDetailConfirm(idToDelete)}
      />
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Products
        </Typography>
        <Btn
          className={classes.btn_add}
          click={addEmptyRow}
        >
          Add product
        </Btn>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table className={classes.striped_table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              <TableCell>Part #</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Show List Price</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Ext Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editableRow === 0 && (
              <TableRow key={0}>
                <TableCell>
                  <Autocomplete
                    sx={{ width: 200 }}
                    filterOptions={filterOptions}
                    value={{ partNumber: productSearchText } as IProductItem}
                    getOptionLabel={(option) => option.partNumber}
                    options={products?.result || []}
                    loading={isFetchingProductsData}
                    onChange={(event, value) => onSelectProduct(value)}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === 'clear') {
                        onSearchProductText(newInputValue);
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        key={option.id}
                      >
                        {`${option.partNumber} - ${option.name} - ${currencyFormatter.format(option.price)}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={!productSearchText.trim() && isProductSearchModifyed}
                        helperText={!productSearchText.trim() && isProductSearchModifyed && 'The part number field is required'}
                        onChange={(event) => onSearchProductText(event.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isFetchingProductsData ? (
                                <CircularProgress
                                  color="inherit"
                                  size={20}
                                  sx={{ mr: 4 }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    multiline
                    rows={6}
                    value={quoteDetail.description || ''}
                    size="small"
                    onChange={(val) => changeCellField('description', val.target.value || '')}
                  />
                </TableCell>
                <TableCell sx={{ width: '100px' }}>
                  <TextField
                    fullWidth
                    value={quoteDetail.qty || ''}
                    size="small"
                    type="number"
                    onChange={(val) => changeCellField('qty', +val.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={quoteDetail.showListPrice}
                    onChange={(event) => {
                      changeCellField('showListPrice', event.target.checked);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: '146px' }}>
                  <TextField
                    fullWidth
                    value={quoteDetail.price}
                    size="small"
                    type="number"
                    onChange={(val) => changeCellField('price', +val.target.value)}
                  />
                </TableCell>
                <TableCell>
                  {currencyFormatter.format((quoteDetail.price || 0) * quoteDetail.qty)}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Tooltip title="Save">
                    <IconButton
                      onClick={() => saveQuoteDetail()}
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => setEditableRow(-1)}
                      color="error"
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {items.map((col, index) => (
              <TableRow key={col.id}>
                <TableCell>
                  {editableRow === index + 1 ? (
                    <Autocomplete
                      sx={{ width: 200 }}
                      filterOptions={filterOptions}
                      value={{ partNumber: productSearchText } as IProductItem}
                      getOptionLabel={(option) => option.partNumber}
                      options={products?.result || []}
                      loading={isFetchingProductsData}
                      onChange={(event, value) => onSelectProduct(value)}
                      onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'clear') {
                          onSearchProductText(newInputValue);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          key={option.id}
                        >
                          {`${option.partNumber} - ${option.name} - ${currencyFormatter.format(option.price)}`}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={!productSearchText.trim() && isProductSearchModifyed}
                          helperText={!productSearchText.trim() && isProductSearchModifyed && 'The part number field is required'}
                          onChange={(event) => onSearchProductText(event.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isFetchingProductsData ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                    sx={{ mr: 4 }}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    col.partNumber
                  )}
                </TableCell>
                <TableCell>
                  {editableRow === index + 1 ? (
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      value={quoteDetail.description || ''}
                      size="small"
                      onChange={(val) => changeCellField('description', val.target.value || '')}
                    />
                  ) : (
                    <>
                      <b>{col.productName}</b>
                      <br />
                      {col.description}
                    </>
                  )}
                </TableCell>
                <TableCell sx={{ width: 100 }}>
                  {editableRow === index + 1 ? (
                    <TextField
                      fullWidth
                      value={quoteDetail.qty || ''}
                      size="small"
                      type="number"
                      onChange={(val) => changeCellField('qty', +val.target.value)}
                    />
                  ) : (
                    col.qty
                  )}
                </TableCell>
                <TableCell>
                  {editableRow === index + 1 ? (
                    <Switch
                      checked={quoteDetail.showListPrice}
                      onChange={(event) => {
                        changeCellField('showListPrice', event.target.checked);
                      }}
                    />
                  ) : (
                    col.showListPrice && <CheckIcon />
                  )}
                </TableCell>
                <TableCell sx={{ width: 146 }}>
                  {editableRow === index + 1 ? (
                    <TextField
                      fullWidth
                      value={quoteDetail.price || 0}
                      size="small"
                      type="number"
                      onChange={(val) => changeCellField('price', +val.target.value)}
                    />
                  ) : (
                    <>
                      {currencyFormatter.format(col.price || 0)}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {editableRow === index + 1 ? (
                    currencyFormatter.format((quoteDetail.price || 0) * quoteDetail.qty)
                  ) : (
                    currencyFormatter.format(col.extPrice)
                  )}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {editableRow === index + 1 ? (
                    <>
                      <Tooltip title="Save">
                        <IconButton
                          onClick={() => saveQuoteDetail()}
                          color="primary"
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton
                          onClick={() => setEditableRow(-1)}
                          color="error"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => editQuoteDetail(index)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            if (id === 0) {
                              const details = [...items];
                              details.splice(index, 1);
                              quoteDetailsChange(details);
                            } else {
                              setIdToDelete(col.id);
                            }
                          }}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                SALES TAX
                <br />
                TOTAL
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  fontWeight={600}
                >
                  {currencyFormatter.format(calculateSalesTax())}
                  <br />
                  {currencyFormatter.format(calculateTotal() + calculateSalesTax())}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuoteDetails;
