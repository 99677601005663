import { useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { book, TableSearch } from '@monorepo/common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SendIcon from '@mui/icons-material/Send';
import { ISelectListItem } from '../../../interfaces';
import { useGetQuotesBaseQuery } from '../../../services/quote';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  salesRepId: number,
  startDate: Date | null,
  endDate: Date | null,
  handleSalesRepIdChange: (event: SelectChangeEvent<number>) => void,
  handleStartDateChange: (event: any) => void,
  handleEndDateChange: (event: any) => void,
}

const TableToolBar = ({ onSearch, salesRepId, startDate, endDate, handleSalesRepIdChange, handleStartDateChange, handleEndDateChange }: ITableToolBarProps) => {
  const navigate = useNavigate();
  const [quoteId, setQuoteId] = useState<string>('');

  const { data } = useGetQuotesBaseQuery('');

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={8}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <TextField
          fullWidth
          label="Quote ID"
          value={quoteId}
          onChange={(event) => setQuoteId(event.target.value)}
          InputProps={{
            endAdornment: (
              <Button onClick={() => navigate(book.admin.quote(+quoteId))}>
                <SendIcon />
              </Button>
            )
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <FormControl fullWidth>
          <InputLabel id="salesreps-select-label">Sales Rep Name</InputLabel>
          <Select
            labelId="salesreps-select-label"
            value={salesRepId}
            label="Sales Rep Name"
            onChange={handleSalesRepIdChange}
            sx={{ textAlign: 'left' }}
          >
            <MenuItem
              key="-1"
              value="-1"
            >
              All Sales Reps
            </MenuItem>
            {data && data.salesReps.map((item: ISelectListItem) => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
