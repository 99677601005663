import cookies from 'js-cookie';
import { baseUrls, book, cookieNames } from '@monorepo/common';

export const downloadFile = async (url: string, setIsLoading: (arg0: boolean) => void) => {
  setIsLoading(true);

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${cookies.get(cookieNames.access_token)}` }
  });

  if (!response.ok) {
    if (response.status === 401) {
      if (cookies.get(cookieNames.refresh_token)) {
        const refreshResult = await fetch(`${baseUrls.adminApi}/account/RefreshToken?refreshToken=${cookies.get(cookieNames.refresh_token)}`);

        if (refreshResult.ok) {
          const refreshResultJson = await refreshResult.json();
          // @ts-ignore
          await cookies.set(cookieNames.access_token, refreshResultJson.access_token);
          // @ts-ignore
          await cookies.set(cookieNames.refresh_token, refreshResultJson.refresh_token);

          await downloadFile(url, setIsLoading);
        }
      } else {
        cookies.remove(cookieNames.access_token);
        window.location.href = book.admin.account.login;
      }
    } else {
      console.error(response);
      setIsLoading(false);
    }

    return false;
  }

  const contentDispositionHeader = response.headers.get('Content-Disposition');
  const fileType = response.headers.get('Content-Type');
  const parts = contentDispositionHeader!.split(';');
  const [, part] = parts;
  const [, fileName] = part.split('=');

  const blob = await response.blob();

  const data = new Blob([blob], { type: fileType || '' });
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName || '');
  tempLink.click();
  setIsLoading(false);

  return true;
};
