import { useState, useEffect } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { book, Btn, CardWrapper, cookieNames, DataGrid, ModalWindow } from '@monorepo/common';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import { useStyles } from '../styles';
import { renewalQuoteApi, useGetEmailPreviewQuery, useGetEmailsPreviewQuery, useSendRenewalEmailMutation, useSendRenewalEmailsMutation } from '../../../services/renewalQuote';
import { IRenewalQuoteItem } from '../../../services/renewalQuote/interfaces';
import GridField from '../../../components/GridField';
import { ISelectListItem, RenewalEmailsType } from '../../../interfaces';
import { showNotification } from '../../../features/ui/sliceNotification';
import AdminLink from '../../../components/AdminLink';

interface IRenewalEmailsProps {
  type?: RenewalEmailsType
}

const RenewalEmails = ({ type = 'regular' }: IRenewalEmailsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const upForRenewalDates = (cookies.get(cookieNames.up_for_renewal_dates) || '').split('-');

  const [renewalId, setRenewalId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [templateId, setTemplateId] = useState(-1);

  let templateType = -1;
  let title = '';
  let hasEarlyQuotes: boolean | undefined;
  let hasServerProducts: boolean | undefined;
  switch (type) {
    case 'early': {
      title = 'Early Renewal Emails';
      hasEarlyQuotes = true;
      templateType = 2;
      break;
    }
    case 'regular': {
      title = 'Regular Renewal Emails';
      hasEarlyQuotes = false;
      templateType = 3;
      break;
    }
    case 'expiredServer': {
      title = 'Invoices to Server';
      hasServerProducts = true;
      templateType = 9;
      break;
    }
    case 'expiredHosted': {
      title = 'Invoices to Hosted';
      hasServerProducts = false;
      templateType = 10;
      break;
    }
    default: {
      console.error('no type detected');
    }
  }

  const { isLoading: isLoadingEmailsPreview, data: messageTemplateTypes } = useGetEmailsPreviewQuery(templateType);
  const { isLoading: isLoadingEmailPreview, data: previewData } = useGetEmailPreviewQuery({ renewalId: renewalId!, templateId }, { skip: !renewalId });
  const [sendRenewalEmail, { isLoading: isSendingEmail, isSuccess: isSendEmailSuccess }] = useSendRenewalEmailMutation();
  const [sendRenewalEmails, { isLoading: isSendingEmails }] = useSendRenewalEmailsMutation();

  const previewEmail = (renewalQuote: IRenewalQuoteItem) => {
    setRenewalId(renewalQuote.id);
    setShowModal(true);
  };

  const sendTestEmail = () => {
    sendRenewalEmail({
      renewalId: renewalId!,
      templateId,
      email,
    });
  };

  const sendEmails = () => {
    sendRenewalEmails({
      startDate: moment.utc(upForRenewalDates[0]).toDate(),
      endDate: moment.utc(upForRenewalDates[1]).toDate(),
      templateId,
      hasEarlyQuotes,
      hasServerProducts,
    }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      } else if (res.data !== undefined) {
        dispatch(showNotification({
          text: `${res.data} emails was sent`,
          type: 'success',
          show: true
        }));
      }
    });
  };

  useEffect(() => {
    if (messageTemplateTypes && messageTemplateTypes.length) {
      setTemplateId(+messageTemplateTypes[0].value);
    }
  }, [messageTemplateTypes]);

  useEffect(() => {
    if (isSendEmailSuccess) {
      dispatch(showNotification({
        text: 'Email sent',
        type: 'success',
        show: true
      }));
    }
  }, [isSendEmailSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet={title}
      title={`${title} ${upForRenewalDates[0]} - ${upForRenewalDates[1]}`}
      maxWidth="100%"
      isLoading={isLoadingEmailPreview || isLoadingEmailsPreview || isSendingEmail || isSendingEmails}
      childrenTitleRight={(
        <Box>
          <FormControl sx={{ width: '300px' }}>
            <InputLabel id="messagetemplatetypes-select-label">Message Template</InputLabel>
            <Select
              labelId="salesreps-select-label"
              value={templateId}
              size="small"
              label="Message Template"
              onChange={(event) => setTemplateId(+event.target.value)}
              sx={{ textAlign: 'left' }}
            >
              {messageTemplateTypes && messageTemplateTypes.map((item: ISelectListItem) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                >
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Btn
            click={sendEmails}
            className={classes.inline_btn}
          >
            Send
          </Btn>
        </Box>
      )}
    >
      <ModalWindow
        show={showModal}
        title="Preview"
        closeModal={() => setShowModal(false)}
      >
        <Grid
          sx={{ mt: 2 }}
          container
          spacing={2}
        >
          <GridField
            label="From"
            labelmd={2}
            fieldmd={10}
          >
            <Box sx={{ m: 1 }}>
              {previewData && `${previewData.fromName} ${previewData.fromEmail}`}
            </Box>
          </GridField>
          <GridField
            label="To"
            labelmd={2}
            fieldmd={10}
          >
            <Box sx={{ m: 1 }}>
              {previewData && previewData.toEmail}
            </Box>
          </GridField>
          <GridField
            label="Cc"
            labelmd={2}
            fieldmd={10}
          >
            <Box sx={{ m: 1 }}>
              {previewData && previewData.ccEmail}
            </Box>
          </GridField>
          <GridField
            label="Bcc"
            labelmd={2}
            fieldmd={10}
          >
            <Box sx={{ m: 1 }}>
              {previewData && previewData.bccEmail}
            </Box>
          </GridField>
          <GridField
            label="Subject"
            labelmd={2}
            fieldmd={10}
          >
            <Box sx={{ m: 1 }}>
              {previewData && previewData.subject}
            </Box>
          </GridField>
          <GridField
            label="Body"
            labelmd={2}
            fieldmd={10}
          >
            <Box sx={{ m: 1 }}>
              {previewData && (
                // eslint-disable-next-line
                <div dangerouslySetInnerHTML={{ __html: previewData.body }} />
              )}
            </Box>
          </GridField>
        </Grid>
        <TextField
          label="Send to email"
          value={email}
          sx={{ mt: 2 }}
          onChange={(event) => setEmail(event.target.value)}
          InputProps={{
            endAdornment: (
              <Button onClick={sendTestEmail}>
                <SendIcon />
              </Button>
            )
          }}
        />
      </ModalWindow>
      <DataGrid<IRenewalQuoteItem>
        keyExtractor={(col) => col.id.toString()}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultSortDirection: 'desc',
          defaultPageSize: 25,
        }}
        columns={[
          {
            rowId: (col) => col.id,
            field: 'id',
            title: 'ID',
            template: (col) => (
              <AdminLink
                url={book.admin.renewalQuote(col.id)}
                title={col.id || ''}
              />
            ),
          },
          {
            field: 'email',
            title: 'Email',
            hideCurrentSortable: true,
          },
          {
            field: 'firstName',
            title: 'First Name',
            hideCurrentSortable: true,
          },
          {
            field: 'lastName',
            title: 'Last Name',
            hideCurrentSortable: true,
          },
          {
            field: 'contractEndDate',
            title: 'Contract End Date',
            hideCurrentSortable: true,
            template: (col) => <>{col.contractEndDate ? moment(col.contractEndDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'partNumber1Year',
            title: 'Product Part # 1 Year',
            hideCurrentSortable: true,
          },
          {
            field: 'partNumber2Year',
            title: 'Product Part # 2 Year',
            hideCurrentSortable: true,
          },
          {
            field: 'lastCol',
            title: 'empty',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (
              <Button
                onClick={() => previewEmail(col)}
                color="primary"
              >
                Preview
              </Button>
            ),
          },
        ]}
        apiProps={{
          api: renewalQuoteApi,
          apiMethodName: 'useLazyGetRenewalQuotesQuery',
          apiParams: {
            startDate: upForRenewalDates[0],
            endDate: upForRenewalDates[1],
            hasEarlyQuotes,
            hasServerProducts,
            isOptedOut: false,
          },
        }}
      />
    </CardWrapper>
  );
};

export default RenewalEmails;
