import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IOnlineAccount, IOnlineAccountListRequest, IOnlineAccountRequest, IOnlineAccountDetails, IOnlineAccountUpdateRequest, IProductPlanLimit, IOnlineAccountLimit, IOnlineAccountCreateLimitRequest, IOnlineAccountUpdateLimitRequest, IOnlineAccountDeleteLimitRequest, IOnlineAccountSenderRequest, IOnlineAccountUpdateSenderRequest, IOnlineAccountDeleteSenderRequest, IOnlineAccountLogin7Request, IOnlineAccountUser } from './interfaces';

export const onlineAccountApi = createApi({
  reducerPath: 'onlineAccountApi',
  tagTypes: ['onlineAccount'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getOnlineAccounts: build.query<IListResp<IOnlineAccount>, IOnlineAccountListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        region,
        startDate,
        endDate,
        isActive,
        isTrial,
      }) => ({
        url: `${endpoints.onlineAccounts}/${region}`,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          startDate,
          endDate,
          isActive,
          isTrial,
        }
      }),
      providesTags: () => ['onlineAccount']
    }),
    getProductPlanLimits: build.query<IProductPlanLimit[], string>({
      query: (searchText) => ({
        url: endpoints.onlineAccountProductPlanLimits,
        params: { searchText }
      }),
    }),
    getOnlineAccountUsers: build.query<IOnlineAccountUser[], IOnlineAccountRequest>({
      query: ({
        region,
        id
      }) => ({
        url: `${endpoints.onlineAccountUsers}/${region}/${id}`,
      }),
    }),
    getOnlineAccount: build.query<IOnlineAccountDetails, IOnlineAccountRequest>({
      query: ({ region, id }) => ({
        url: `${endpoints.onlineAccount}/${region}/${id}`,
      }),
      providesTags: () => ['onlineAccount']
    }),
    getOnlineAccountLogin7Link: build.query<string, IOnlineAccountLogin7Request>({
      query: ({
        region,
        id,
        userName,
      }) => ({
        url: `${endpoints.onlineAccountLogin7}/${region}/${id}/${userName}`,
      }),
    }),
    createOnlineAccountLimit: build.mutation<IOnlineAccountLimit, IOnlineAccountCreateLimitRequest>({
      query: ({
        region,
        accountId,
        productPlanLimitId,
        limitName,
        limitValue,
        contextLimitValue,
        contextLimitBase
      }) => ({
        url: `${endpoints.onlineAccountCreateLimit}/${region}/${accountId}`,
        method: 'POST',
        body: {
          productPlanLimitId,
          limitName,
          limitValue,
          contextLimitValue,
          contextLimitBase,
        }
      }),
      invalidatesTags: () => ['onlineAccount']
    }),
    createOnlineAccountSender: build.mutation<string, IOnlineAccountSenderRequest>({
      query: ({
        region,
        accountId,
        verifiedSender,
      }) => ({
        url: `${endpoints.onlineAccountCreateSender}/${region}/${accountId}`,
        method: 'POST',
        body: {
          verifiedSender,
        }
      }),
      invalidatesTags: () => ['onlineAccount']
    }),
    updateOnlineAccount: build.mutation<IOnlineAccountDetails, IOnlineAccountUpdateRequest>({
      query: ({
        region,
        id,
        isActive,
        startDate,
        endDate,
        supportContractId,
        contextType,
        contextUrl,
      }) => ({
        url: `${endpoints.onlineAccountEdit}/${region}/${id}`,
        method: 'PATCH',
        body: {
          isActive,
          startDate,
          endDate,
          supportContractId,
          contextType,
          contextUrl,
        }
      }),
      invalidatesTags: () => ['onlineAccount']
    }),
    updateOnlineAccountLimit: build.mutation<IOnlineAccountLimit, IOnlineAccountUpdateLimitRequest>({
      query: ({
        region,
        accountId,
        name,
        productPlanLimitId,
        limitName,
        limitValue,
        contextLimitValue,
        contextLimitBase
      }) => ({
        url: `${endpoints.onlineAccountEditLimit}/${region}/${accountId}/${name}`,
        method: 'PATCH',
        body: {
          productPlanLimitId,
          limitName,
          limitValue,
          contextLimitValue,
          contextLimitBase,
        }
      }),
      invalidatesTags: () => ['onlineAccount']
    }),
    updateOnlineAccountSender: build.mutation<string, IOnlineAccountUpdateSenderRequest>({
      query: ({
        region,
        accountId,
        sender,
        verifiedSender,
      }) => ({
        url: `${endpoints.onlineAccountEditSender}/${region}/${accountId}/${sender}`,
        method: 'PATCH',
        body: {
          verifiedSender,
        }
      }),
      invalidatesTags: () => ['onlineAccount']
    }),
    deleteOnlineAccountLimit: build.mutation<IOnlineAccountLimit, IOnlineAccountDeleteLimitRequest>({
      query: ({
        region,
        accountId,
        name,
      }) => ({
        url: `${endpoints.onlineAccountDeleteLimit}/${region}/${accountId}/${name}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['onlineAccount']
    }),
    deleteOnlineAccountSender: build.mutation<string, IOnlineAccountDeleteSenderRequest>({
      query: ({
        region,
        accountId,
        sender,
      }) => ({
        url: `${endpoints.onlineAccountDeleteSender}/${region}/${accountId}/${sender}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['onlineAccount']
    }),
  }),
});

export const {
  useLazyGetOnlineAccountsQuery,
  useGetProductPlanLimitsQuery,
  useGetOnlineAccountUsersQuery,
  useGetOnlineAccountQuery,
  useGetOnlineAccountLogin7LinkQuery,
  useCreateOnlineAccountLimitMutation,
  useCreateOnlineAccountSenderMutation,
  useUpdateOnlineAccountMutation,
  useUpdateOnlineAccountLimitMutation,
  useUpdateOnlineAccountSenderMutation,
  useDeleteOnlineAccountLimitMutation,
  useDeleteOnlineAccountSenderMutation,
} = onlineAccountApi;
