import { memo, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, Button, Grid, Paper, TableContainer, TextField, Toolbar, Typography } from '@mui/material';
import moment from 'moment';
import { book, CardWrapper, DataGrid } from '@monorepo/common';
import { currencyFormatter } from '@monorepo/common/src/utils/currencyFormatter';
import { reportApi, useGetSalesByProductGroupsQuery } from '../../services/report';
import { IMostRecentOrder, ITotalSalesByProductGroup } from '../../services/report/interfaces';
import { useAppSelector } from '../../app/hooks';
import { searchForCommonItems } from '../../utils/searchForCommonItems';
import { Permission } from '../../constants/permission';
import AdminLink from '../../components/AdminLink';
import { useStyles } from './styles';

const Dashboard = () => {
  const classes = useStyles();

  const nowDate = new Date();

  const [reportStartDate, setReportStartDate] = useState<Date | null>(new Date(nowDate.getFullYear(), nowDate.getMonth(), 1));
  const [reportEndDate, setReportEndDate] = useState<Date | null>(nowDate);

  const [startDate, setStartDate] = useState<string>(moment(reportStartDate).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string>(moment(reportEndDate).format('YYYY-MM-DD'));
  const [salesReportGroupId, setSalesReportGroupId] = useState<number>(-1);

  const { userRoles, isSuperUser } = useAppSelector(({ profile }) => ({
    userRoles: profile.UserRoles,
    isSuperUser: profile.IsSuper
  }));

  const hasReportsPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Reports].map(String));

  const { data: salesByProductGroups, isLoading: isLoadingSalesByProductGroups } = useGetSalesByProductGroupsQuery('', { skip: !hasReportsPermission });

  const isRowSelected = (item: ITotalSalesByProductGroup) => item.salesGroupId === salesReportGroupId;

  const onRowClick = (item: ITotalSalesByProductGroup) => {
    setSalesReportGroupId(item.salesGroupId === salesReportGroupId ? -1 : item.salesGroupId);
  };

  const changeDate = () => {
    setStartDate(moment(reportStartDate)?.format('YYYY-MM-DD'));
    setEndDate(moment(reportEndDate)?.format('YYYY-MM-DD'));
  };

  const options: ApexOptions = {
    chart: {
      id: 'bar'
    },
    title: {
      text: 'Sales Breakdown / Grouped by month',
      align: 'center'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: (salesByProductGroups && salesByProductGroups.months) || [],
    },
    yaxis: {
      title: {
        text: 'Sales in USD',
        style: {
          fontSize: '14px',
        },
      },
      labels: {
        formatter: (val: number) => currencyFormatter.format(val)
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: (val: number) => currencyFormatter.format(val)
      }
    }
  };

  return (
    <>
      {hasReportsPermission && (
        <CardWrapper
          centerText
          helmet="Dashboard"
          title="Dashboard"
          maxWidth="100%"
          isLoading={isLoadingSalesByProductGroups}
        >
          <Chart
            options={options}
            series={(salesByProductGroups && salesByProductGroups.items) || []}
            type="bar"
            width="100%"
            height="300"
          />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              md={5}
            >
              <Toolbar>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="h6"
                  component="div"
                >
                  Sales Totals
                </Typography>
              </Toolbar>
              <TableContainer
                component={Paper}
                sx={{ mb: 2 }}
              >
                <DataGrid<ITotalSalesByProductGroup>
                  keyExtractor={(col) => col.salesGroupId.toString()}
                  styleProps={{
                    isSelectedEven: true
                  }}
                  searchableProps={{
                    showSearchField: false,
                  }}
                  pageable={false}
                  events={{ onRowClick, isRowSelected }}
                  defaultGridProps={{
                    scrollUpAfterRequest: false,
                  }}
                  columns={[
                    {
                      field: 'salesGroupName',
                      title: 'Description',
                      footerProps: {
                        customElement: {
                          title: 'Total',
                        }
                      }
                    },
                    {
                      field: 'quantity',
                      title: 'Quantity',
                      footerProps: {
                        totalText: null,
                        isQuantity: true,
                        showTotalAmount: true,
                      },
                    },
                    {
                      field: 'amount',
                      title: 'Total',
                      isCurrency: true,
                      footerProps: {
                        totalText: null,
                        showTotalAmount: true,
                      },
                    }
                  ]}
                  apiProps={{
                    api: reportApi,
                    apiMethodName: 'useLazyGetTotalSalesByProductGroupsQuery',
                    apiParams: {
                      startDate,
                      endDate
                    },
                  }}
                />
              </TableContainer>
              <Box className={classes.date_range_picker_wrapper}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={reportStartDate}
                    onChange={(event) => setReportStartDate(event)}
                    disableFuture
                    shouldDisableDate={(d) => !!(reportEndDate && d! > reportEndDate!)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Start date'
                        }}
                      />
                    )}
                  />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <DatePicker
                    value={reportEndDate}
                    onChange={(event) => setReportEndDate(event)}
                    disableFuture
                    shouldDisableDate={(d) => !!(reportStartDate && moment(d) < moment(reportStartDate).startOf('day'))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'End date'
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Button
                onClick={changeDate}
                variant="outlined"
                sx={{ mt: 2 }}
              >
                Get Sales
              </Button>
            </Grid>
            <Grid
              item
              md={7}
            >
              <Toolbar>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="h6"
                  component="div"
                >
                  Most Recent Orders
                </Typography>
              </Toolbar>
              <DataGrid<IMostRecentOrder>
                keyExtractor={(col) => `${col.orderId}_${col.productName}`}
                sortable
                styleProps={{
                  isSelectedEven: true
                }}
                searchableProps={{
                  showSearchField: false,
                }}
                defaultGridProps={{
                  defaultPageSize: 10,
                  scrollUpAfterRequest: false,
                }}
                columns={[
                  {
                    field: 'orderId',
                    title: 'Order',
                    template: (col) => (
                      <AdminLink
                        url={book.admin.order(col.orderId)}
                        title={col.orderId}
                        permissions={[Permission.Orders]}
                      />
                    ),
                  },
                  {
                    field: 'email',
                    title: 'Email',
                  },
                  {
                    field: 'productName',
                    title: 'Product Name'
                  },
                  {
                    field: 'total',
                    title: 'Total',
                    isCurrency: true,
                  }
                ]}
                apiProps={{
                  api: reportApi,
                  apiMethodName: 'useLazyGetMostRecentOrdersListQuery',
                  apiParams: {
                    startDate,
                    endDate,
                    salesReportGroupId: salesReportGroupId === -1 ? undefined : salesReportGroupId
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardWrapper>
      )}
    </>
  );
};

export default memo(Dashboard);
