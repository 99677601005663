import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, CardWrapper, ModalWindow, regExp } from '@monorepo/common';
import { Autocomplete, Box, Button, CircularProgress, createFilterOptions, debounce, Grid, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { showNotification } from '../../features/ui/sliceNotification';
import { useCopyQuoteMutation, useCreateQuoteMutation, useDeleteQuoteMutation, useGetQuoteQuery, useProcessQuoteMutation, useUpdateQuoteMutation } from '../../services/quote';
import { IQuoteDetails, IQuoteProcessResponse, IQuoteProductDetail } from '../../services/quote/interfaces';
import { useGetBuyerBillingAutocompleteQuery, useGetBuyerShipmentAutocompleteQuery } from '../../services/order';
import { IBuyerAutocomplete } from '../../services/order/interfaces';
import GridField from '../../components/GridField';
import HeaderToolBar from './components/HeaderToolBar';
import QuoteDetails from './components/QuoteDetails';
import ConfirmModal from '../../components/ConfirmModal';

const Quote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isNew = id === 'new';

  const { data, isLoading: isLoadingData } = useGetQuoteQuery(isNew ? '0' : id!);

  const [fields, setFields] = useState<IQuoteDetails>({
    id: 0,
    salesRepId: 0,
    billingEmail: '',
    billingAddress1: '',
    billingAddress2: '',
    billingCity: '',
    billingCompany: '',
    billingCountry: '',
    billingFirstName: '',
    billingLastName: '',
    billingPhone: '',
    billingProvince: '',
    billingState: '',
    billingZip: '',
    shipmentEmail: '',
    shipmentAddress1: '',
    shipmentAddress2: '',
    shipmentCity: '',
    shipmentCompany: '',
    shipmentCountry: '',
    shipmentFirstName: '',
    shipmentLastName: '',
    shipmentPhone: '',
    shipmentProvince: '',
    shipmentState: '',
    shipmentZip: '',
    paymentMethod: 1,
    creditCardNumber: '',
    creditCardExp: '',
    creditCardCSC: '',
    isTaxExempt: false,
    isOrdered: false,
    createdDate: new Date(),
    expireDate: new Date(),
    contextId: null,
    hostedRegion: '',
    discountCode: '',
    salesReps: [],
    countries: [],
    states: [],
    regions: [],
    paymentMethods: [],
    discountCodes: [],
    quoteDetails: [],
  });
  const [billingSearchText, setBillingSearchText] = useState<string>('');
  const [shipmentSearchText, setShipmentSearchText] = useState<string>('');
  const [isBillingSearchModifyed, setIsBillingSearchModifyed] = useState<boolean>(false);
  const [isShipmentSearchModifyed, setIsShipmentSearchModifyed] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showProcessModal, setShowProcessModal] = useState<boolean>(false);
  const [doNotEmailCustomer, setDoNotEmailCustomer] = useState<boolean>(false);
  const [doNotEmailCompany, setDoNotEmailCompany] = useState<boolean>(false);
  const [newQuoteDetails, setNewQuoteDetails] = useState<IQuoteProductDetail[]>([]);

  const { isFetching: isFetchingBuyerBillingData, data: buyerBillingData } = useGetBuyerBillingAutocompleteQuery(billingSearchText, { skip: !isBillingSearchModifyed || !billingSearchText });
  const { isFetching: isFetchingBuyerShipmentData, data: buyerShipmentData } = useGetBuyerShipmentAutocompleteQuery(shipmentSearchText, { skip: !isShipmentSearchModifyed || !shipmentSearchText });
  const [createQuote, { isLoading: isCreatingData }] = useCreateQuoteMutation();
  const [copyQuote, { isLoading: isCopyingData }] = useCopyQuoteMutation();
  const [updateQuote, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateQuoteMutation();
  const [processQuote, { isLoading: isProcessingData }] = useProcessQuoteMutation();
  const [deleteQuote, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteQuoteMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create Quote';
  } else if (data) {
    pageTitle = `Quote #${data && data.id}`;
  } else {
    pageTitle = 'Quote not found';
  }

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const onSearchBillingText = useMemo(() => debounce((value: string) => {
    setBillingSearchText(value);
    setIsBillingSearchModifyed(true);
  }, 800), []);

  const onSearchShipmentText = useMemo(() => debounce((value: string) => {
    setShipmentSearchText(value);
    setIsShipmentSearchModifyed(true);
  }, 800), []);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: IBuyerAutocomplete) => option.email + option.company + option.orderId,
  });

  const onSelectBillingInfo = (value: IBuyerAutocomplete | string | null) => {
    if (typeof value !== 'string' && value !== null) {
      setIsBillingSearchModifyed(false);
      setBillingSearchText(value.email);
      setFields({
        ...fields,
        billingEmail: value.email,
        billingFirstName: value.firstName,
        billingLastName: value.lastName,
        billingPhone: value.phone,
        billingCompany: value.company,
        billingAddress1: value.address1,
        billingAddress2: value.address2,
        billingCountry: value.country,
        billingProvince: value.province,
        billingState: value.state,
        billingCity: value.city,
        billingZip: value.zip,
      });
    }
  };

  const onSelectShipmentInfo = (value: IBuyerAutocomplete | string | null) => {
    if (typeof value !== 'string' && value !== null) {
      setIsShipmentSearchModifyed(false);
      setShipmentSearchText(value.email);
      setFields({
        ...fields,
        shipmentEmail: value.email,
        shipmentFirstName: value.firstName,
        shipmentLastName: value.lastName,
        shipmentPhone: value.phone,
        shipmentCompany: value.company,
        shipmentAddress1: value.address1,
        shipmentAddress2: value.address2,
        shipmentCountry: value.country,
        shipmentProvince: value.province,
        shipmentState: value.state,
        shipmentCity: value.city,
        shipmentZip: value.zip,
      });
    }
  };

  const copyBillingAddress = () => {
    setShipmentSearchText(billingSearchText || '');
    setFields({
      ...fields,
      shipmentEmail: billingSearchText,
      shipmentFirstName: fields.billingFirstName,
      shipmentLastName: fields.billingLastName,
      shipmentPhone: fields.billingPhone,
      shipmentCompany: fields.billingCompany,
      shipmentAddress1: fields.billingAddress1,
      shipmentAddress2: fields.billingAddress2,
      shipmentCountry: fields.billingCountry,
      shipmentCity: fields.billingCity,
      shipmentProvince: fields.billingProvince,
      shipmentState: fields.billingState,
      shipmentZip: fields.billingZip,
    });
  };

  const isTextValid = (text: string, regExpression: RegExp, isRequired: boolean = false) => {
    if (!isRequired && (text === null || text === '')) {
      return true;
    }

    const result = text?.match(regExpression);

    return result && result[0] === text;
  };

  const getTaxRate = () => data?.states.find((s) => s.initials === fields.shipmentState)?.taxRate || 0;

  const processConfirm = () => {
    if (fields.paymentMethod === 1
      && (!fields.creditCardNumber
        || fields.creditCardNumber.includes('X')
        || !fields.creditCardExp
        || !fields.creditCardCSC)) {
      dispatch(showNotification({
        text: 'Credit Card information not provided',
        type: 'error',
        show: true
      }));

      return;
    }

    processQuote({
      ...fields,
      billingEmail: billingSearchText,
      shipmentEmail: shipmentSearchText,
      creditCardNumber: fields.paymentMethod === 1 ? fields.creditCardNumber : null,
      creditCardExp: fields.paymentMethod === 1 ? fields.creditCardExp : null,
      creditCardCSC: fields.paymentMethod === 1 ? fields.creditCardCSC : null,
      doNotEmailCustomer,
      doNotEmailCompany,
    }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      } else {
        const processResponse = res.data as IQuoteProcessResponse;

        if (!processResponse.error && !processResponse.transactionError) {
          dispatch(showNotification({
            text: 'Quote process success',
            type: 'success',
            show: true
          }));
          setShowProcessModal(false);
        }

        if (processResponse.error) {
          dispatch(showNotification({
            text: processResponse.error,
            type: 'error',
            show: true
          }));
        }

        if (processResponse.transactionError) {
          dispatch(showNotification({
            text: processResponse.transactionError,
            type: 'error',
            show: true
          }));
        }
      }
    });
  };

  const onSubmit = (process: boolean) => {
    if (isNew) {
      createQuote({
        ...fields,
        billingEmail: billingSearchText,
        shipmentEmail: shipmentSearchText,
        quoteDetails: newQuoteDetails,
      }).then((res: any) => {
        if (res.error) {
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: res.error?.data || 'Error'
          }));
        } else if (res.data) {
          dispatch(showNotification({
            text: 'Quote created',
            type: 'success',
            show: true
          }));
          navigate(book.admin.quote());
        }
      });
    } else if (process) {
      processConfirm();
    } else {
      updateQuote({
        ...fields,
        billingEmail: billingSearchText,
        shipmentEmail: shipmentSearchText,
      }).then((res: any) => {
        if (res.error) {
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: res.error?.data || 'Error'
          }));
        }
      });
    }
  };

  const isValid = () => !!(
    billingSearchText.trim()
    && shipmentSearchText.trim()
    && fields.expireDate
    && !((fields.billingCountry === 'United States' || fields.billingCountry === 'Canada') && !isTextValid(fields.billingZip, regExp.zip, true))
    && !((fields.shipmentCountry === 'United States' || fields.shipmentCountry === 'Canada') && !isTextValid(fields.shipmentZip, regExp.zip, true))
    && isTextValid(fields.billingPhone, regExp.phone)
    && isTextValid(fields.shipmentPhone, regExp.phone)
  );

  const save = (process: boolean = false) => {
    setIsBillingSearchModifyed(true);
    setIsShipmentSearchModifyed(true);

    if (isValid()) {
      onSubmit(process);
    }
  };

  const copy = () => {
    copyQuote(+id!)
      .then((res: any) => {
        if (res.data) {
          const newId = res.data as number;

          dispatch(showNotification({
            text: 'Quote copied',
            type: 'success',
            show: true
          }));

          window.location.href = `${book.admin.quote()}/${newId}`;
        }
      });
  };

  const deleteQuoteConfirm = () => {
    deleteQuote(+id!);
    setShowDeleteModal(false);
    navigate(book.admin.quote());
  };

  useEffect(() => {
    if (data) {
      setBillingSearchText(data.billingEmail || '');
      setShipmentSearchText(data.shipmentEmail || '');
      setFields(data);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Quote updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Quote removed',
        type: 'success',
        show: true
      }));
      navigate(book.admin.quote());
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Quote"
      title={pageTitle}
      maxWidth="100%"
      isLoading={isLoadingData || isCreatingData || isCopyingData || isUpdatingData || isProcessingData || isDeletingData}
      childrenTitleRight={data && (
        <HeaderToolBar
          id={id!}
          isNew={isNew}
          isValid={isValid()}
          showProcessBtn={!data.isOrdered}
          save={save}
          copy={copy}
          processQuotePopup={() => setShowProcessModal(true)}
          deleteQuotePopup={() => setShowDeleteModal(true)}
        />
      )}
    >
      <ModalWindow
        show={showProcessModal}
        title="Confirm process order"
        closeModal={() => setShowProcessModal(false)}
      >
        <Grid
          sx={{ mt: 2 }}
          container
          spacing={2}
        >
          <GridField label="Do not email customer">
            <Switch
              checked={doNotEmailCustomer}
              onChange={(event) => setDoNotEmailCustomer(event.target.checked)}
            />
          </GridField>
          <GridField label="Do not email company">
            <Switch
              checked={doNotEmailCompany}
              onChange={(event) => setDoNotEmailCompany(event.target.checked)}
            />
          </GridField>
        </Grid>
        <Button onClick={() => save(true)}>Yes</Button>
        <Button onClick={() => setShowProcessModal(false)}>No</Button>
      </ModalWindow>
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteQuoteConfirm}
      />
      {data && (
        <Box>
          <form>
            <Grid
              sx={{ mt: 2 }}
              container
              spacing={2}
            >

              <GridField
                label="Sales Rep"
                labelmd={2}
                labelxs={4}
                fieldmd={4}
                fieldxs={8}
              >
                <Select
                  fullWidth
                  value={fields.salesRepId || -1}
                  size="small"
                  onChange={(event: SelectChangeEvent<number>) => changeCellField('salesRepId', +event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  <MenuItem
                    key="-1"
                    value="-1"
                  >
                    &nbsp;
                  </MenuItem>
                  {data && data.salesReps.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridField>
              <Grid
                item
                md={6}
                xs={0}
              />

              <Grid
                item
                md={6}
                xs={12}
                container
                spacing={1}
              >
                <Grid
                  sx={{ fontWeight: 'bold' }}
                  item
                  xs={12}
                >
                  <Typography variant="h6">
                    Billing Info
                  </Typography>
                </Grid>

                <GridField label="Email">
                  <Autocomplete
                    freeSolo
                    fullWidth
                    filterOptions={filterOptions}
                    value={{ email: billingSearchText } as IBuyerAutocomplete}
                    getOptionLabel={(option) => option.email || ''}
                    options={buyerBillingData || []}
                    loading={isFetchingBuyerBillingData}
                    onChange={(event, value) => onSelectBillingInfo(value)}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === 'clear') {
                        onSearchBillingText(newInputValue);
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        key={option.orderId}
                      >
                        <Box>
                          {`Order ID: ${option.orderId}`}
                          <br />
                          {`Email: ${option.email}`}
                          <br />
                          {`Company: ${option.company}`}
                        </Box>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={!billingSearchText.trim()}
                        helperText={!billingSearchText.trim() && 'The email field is required'}
                        onChange={(event) => onSearchBillingText(event.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          inputProps: {
                            ...params.inputProps,
                            maxLength: 250
                          },
                          endAdornment: (
                            <>
                              {isFetchingBuyerBillingData ? (
                                <CircularProgress
                                  color="inherit"
                                  size={20}
                                  sx={{ mr: 4 }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </GridField>

                <GridField label="First Name">
                  <TextField
                    fullWidth
                    value={fields.billingFirstName || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('billingFirstName', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Last Name">
                  <TextField
                    fullWidth
                    value={fields.billingLastName || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('billingLastName', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Phone">
                  <TextField
                    fullWidth
                    value={fields.billingPhone || ''}
                    size="small"
                    inputProps={{ maxLength: 50 }}
                    error={!isTextValid(fields.billingPhone, regExp.phone)}
                    helperText={!isTextValid(fields.billingPhone, regExp.phone) && 'Billing phone number field is not valid'}
                    onChange={(val) => changeCellField('billingPhone', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Company">
                  <TextField
                    fullWidth
                    value={fields.billingCompany || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('billingCompany', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Billing Address">
                  <TextField
                    fullWidth
                    value={fields.billingAddress1 || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('billingAddress1', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Billing Address 2">
                  <TextField
                    fullWidth
                    value={fields.billingAddress2 || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('billingAddress2', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Country">
                  <Select
                    fullWidth
                    value={fields.billingCountry || ''}
                    size="small"
                    onChange={(event) => changeCellField('billingCountry', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.countries.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.text}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>

                {fields.billingCountry === 'United States' ? (
                  <GridField label="State">
                    <Select
                      fullWidth
                      value={fields.billingState || ''}
                      size="small"
                      onChange={(event) => changeCellField('billingState', event.target.value)}
                      sx={{ textAlign: 'left' }}
                    >
                      <MenuItem
                        key=""
                        value=""
                      >
                        &nbsp;
                      </MenuItem>
                      {data && data.states.map((item) => (
                        <MenuItem
                          key={item.initials}
                          value={item.initials}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </GridField>
                ) : (
                  <GridField label="Province">
                    <TextField
                      fullWidth
                      value={fields.billingProvince || ''}
                      size="small"
                      inputProps={{ maxLength: 250 }}
                      onChange={(val) => changeCellField('billingProvince', val.target.value || '')}
                    />
                  </GridField>
                )}

                <GridField label="City">
                  <TextField
                    fullWidth
                    value={fields.billingCity || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('billingCity', val.target.value || '')}
                  />
                </GridField>

                <GridField label="ZIP/Postal">
                  <TextField
                    fullWidth
                    value={fields.billingZip || ''}
                    size="small"
                    inputProps={{ maxLength: 10 }}
                    error={(fields.billingCountry === 'United States' || fields.billingCountry === 'Canada') && !isTextValid(fields.billingZip, regExp.zip, true)}
                    helperText={(fields.billingCountry === 'United States' || fields.billingCountry === 'Canada') && !isTextValid(fields.billingZip, regExp.zip, true) && 'Billing ZIP field is not valid'}
                    onChange={(val) => changeCellField('billingZip', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Payment Type">
                  <Select
                    fullWidth
                    value={fields.paymentMethod}
                    size="small"
                    onChange={(event) => changeCellField('paymentMethod', +event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    {data && data.paymentMethods.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>

                {fields.paymentMethod === 1 ? (
                  <>
                    <GridField label="Credit Card Number">
                      <TextField
                        fullWidth
                        value={fields.creditCardNumber || ''}
                        size="small"
                        inputProps={{ maxLength: 40 }}
                        onChange={(val) => changeCellField('creditCardNumber', val.target.value || '')}
                      />
                    </GridField>

                    <GridField label="Expiration (mmyy)">
                      <TextField
                        fullWidth
                        value={fields.creditCardExp || ''}
                        size="small"
                        inputProps={{ maxLength: 4 }}
                        onChange={(val) => changeCellField('creditCardExp', val.target.value || '')}
                      />
                    </GridField>

                    <GridField label="CVC">
                      <TextField
                        fullWidth
                        value={fields.creditCardCSC || ''}
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        onChange={(val) => changeCellField('creditCardCSC', val.target.value || '')}
                      />
                    </GridField>
                  </>
                ) : (
                  <Grid
                    item
                    md={12}
                    xs={0}
                    sx={{ height: '144px' }}
                  />
                )}

                <Grid
                  item
                  md={12}
                  xs={0}
                  sx={{ height: '146px' }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                container
                spacing={1}
              >
                <Grid
                  sx={{ fontWeight: 'bold' }}
                  item
                  xs={12}
                >
                  <Typography variant="h6">
                    End User / Shipping Info
                    <Button
                      sx={{ height: '28px', textTransform: 'none' }}
                      onClick={copyBillingAddress}
                    >
                      Copy from billing
                    </Button>
                  </Typography>
                </Grid>

                <GridField label="Email">
                  <Autocomplete
                    freeSolo
                    fullWidth
                    filterOptions={filterOptions}
                    value={{ email: shipmentSearchText } as IBuyerAutocomplete}
                    getOptionLabel={(option) => option.email || ''}
                    options={buyerShipmentData || []}
                    loading={isFetchingBuyerShipmentData}
                    onChange={(event, value) => onSelectShipmentInfo(value)}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === 'clear') {
                        onSearchShipmentText(newInputValue);
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        component="li"
                        key={option.orderId}
                      >
                        <Box>
                          {`Order ID: ${option.orderId}`}
                          <br />
                          {`Email: ${option.email}`}
                          <br />
                          {`Company: ${option.company}`}
                        </Box>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={!shipmentSearchText.trim()}
                        helperText={!shipmentSearchText.trim() && 'The email field is required'}
                        onChange={(event) => onSearchShipmentText(event.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          inputProps: {
                            ...params.inputProps,
                            maxLength: 250
                          },
                          endAdornment: (
                            <>
                              {isFetchingBuyerShipmentData ? (
                                <CircularProgress
                                  color="inherit"
                                  size={20}
                                  sx={{ mr: 4 }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </GridField>

                <GridField label="First Name">
                  <TextField
                    fullWidth
                    value={fields.shipmentFirstName || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('shipmentFirstName', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Last Name">
                  <TextField
                    fullWidth
                    value={fields.shipmentLastName || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('shipmentLastName', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Phone">
                  <TextField
                    fullWidth
                    value={fields.shipmentPhone || ''}
                    size="small"
                    inputProps={{ maxLength: 50 }}
                    error={!isTextValid(fields.shipmentPhone, regExp.phone)}
                    helperText={!isTextValid(fields.shipmentPhone, regExp.phone) && 'Shipping phone number field is not valid'}
                    onChange={(val) => changeCellField('shipmentPhone', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Company">
                  <TextField
                    fullWidth
                    value={fields.shipmentCompany || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('shipmentCompany', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Shipping Address">
                  <TextField
                    fullWidth
                    value={fields.shipmentAddress1 || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('shipmentAddress1', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Shipping Address 2">
                  <TextField
                    fullWidth
                    value={fields.shipmentAddress2 || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('shipmentAddress2', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Country">
                  <Select
                    fullWidth
                    value={fields.shipmentCountry || ''}
                    size="small"
                    onChange={(event) => changeCellField('shipmentCountry', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.countries.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.text}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>

                {fields.shipmentCountry === 'United States' ? (
                  <GridField label="State">
                    <Select
                      fullWidth
                      value={fields.shipmentState || ''}
                      size="small"
                      onChange={(event) => changeCellField('shipmentState', event.target.value)}
                      sx={{ textAlign: 'left' }}
                    >
                      <MenuItem
                        key=""
                        value=""
                      >
                        &nbsp;
                      </MenuItem>
                      {data && data.states.map((item) => (
                        <MenuItem
                          key={item.initials}
                          value={item.initials}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </GridField>
                ) : (
                  <GridField label="Province">
                    <TextField
                      fullWidth
                      value={fields.shipmentProvince || ''}
                      size="small"
                      inputProps={{ maxLength: 250 }}
                      onChange={(val) => changeCellField('shipmentProvince', val.target.value || '')}
                    />
                  </GridField>
                )}

                <GridField label="City">
                  <TextField
                    fullWidth
                    value={fields.shipmentCity || ''}
                    size="small"
                    inputProps={{ maxLength: 250 }}
                    onChange={(val) => changeCellField('shipmentCity', val.target.value || '')}
                  />
                </GridField>

                <GridField label="ZIP/Postal">
                  <TextField
                    fullWidth
                    value={fields.shipmentZip || ''}
                    size="small"
                    inputProps={{ maxLength: 10 }}
                    error={(fields.shipmentCountry === 'United States' || fields.shipmentCountry === 'Canada') && !isTextValid(fields.shipmentZip, regExp.zip, true)}
                    helperText={(fields.shipmentCountry === 'United States' || fields.shipmentCountry === 'Canada') && !isTextValid(fields.shipmentZip, regExp.zip, true) && 'Shipping ZIP field is not valid'}
                    onChange={(val) => changeCellField('shipmentZip', val.target.value || '')}
                  />
                </GridField>

                <GridField label="Tax Exempt">
                  <Switch
                    checked={fields.isTaxExempt}
                    onChange={(event) => changeCellField('isTaxExempt', event.target.checked)}
                  />
                </GridField>

                <GridField
                  label="Created Date"
                  wrap
                >
                  {data && data.createdDate && moment(data.createdDate).format('YYYY-MM-DD')}
                </GridField>

                <GridField label="Expires">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      value={fields.expireDate}
                      onChange={(event) => changeCellField('expireDate', event)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!fields.expireDate}
                          helperText={!fields.expireDate && 'The Expire Date field is required'}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </GridField>

                <Grid
                  sx={{ fontWeight: 'bold', textAlign: 'right' }}
                  item
                  xs={12}
                >
                  The following fields only apply to quotes for Checkbox Online subscription product codes. The values are ignored for any other product types.
                </Grid>

                <GridField label="Online Account ID">
                  <TextField
                    fullWidth
                    value={fields.contextId || ''}
                    size="small"
                    type="number"
                    inputProps={{ maxLength: 10 }}
                    onChange={(val) => changeCellField('contextId', +val.target.value)}
                  />
                </GridField>

                <GridField label="Hosted Region">
                  <Select
                    fullWidth
                    value={fields.hostedRegion || ''}
                    size="small"
                    onChange={(event) => changeCellField('hostedRegion', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.regions.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.text}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>

                <GridField label="Discount Code">
                  <Select
                    fullWidth
                    value={fields.discountCode || ''}
                    size="small"
                    onChange={(event) => changeCellField('discountCode', event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                      &nbsp;
                    </MenuItem>
                    {data && data.discountCodes.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.text}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </GridField>
              </Grid>
            </Grid>
          </form>
          <QuoteDetails
            id={+id! || 0}
            items={data.quoteDetails || newQuoteDetails}
            taxRate={fields.isTaxExempt || fields.shipmentCountry !== 'United States' ? 0 : getTaxRate()}
            quoteDetailsChange={(quoteDetails) => setNewQuoteDetails(quoteDetails)}
          />
        </Box>
      )}
    </CardWrapper>
  );
};

export default Quote;
