import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, DataGrid, ModalWindow, Scrollbar } from '@monorepo/common';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCreateSupportContractUserMutation, useDeleteSupportContractUserMutation } from '../../../services/supportContract';
import { useStyles } from '../styles';
import { showNotification } from '../../../features/ui/sliceNotification';
import { userApi } from '../../../services/user';
import { IUser } from '../../../services/user/interfaces';
import { useAppSelector } from '../../../app/hooks';
import { searchForCommonItems } from '../../../utils/searchForCommonItems';
import { Permission } from '../../../constants/permission';
import ConfirmModal from '../../../components/ConfirmModal';
import { ISupportContractUser } from '../../../services/supportContract/interfaces';

interface ISupportContractUsersProps {
  items: ISupportContractUser[],
  setIsLoading: (value: boolean) => void,
}

const SupportContractUsers = ({ items, setIsLoading }: ISupportContractUsersProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [createSupportContractUser, { isLoading: isAddingUserData }] = useCreateSupportContractUserMutation();
  const [deleteSupportContractUser, { isLoading: isDeletingUserData, isSuccess: isDeleteUserSuccess }] = useDeleteSupportContractUserMutation();

  const { userRoles, isSuperUser } = useAppSelector(({ profile }) => ({
    userRoles: profile.UserRoles,
    isSuperUser: profile.IsSuper
  }));

  const hasUsersPermission = isSuperUser || searchForCommonItems(userRoles, [Permission.Users].map(String));

  const deleteUserConfirm = (userIdToDelete: number) => {
    deleteSupportContractUser(userIdToDelete);
    setIdToDelete(0);
  };

  const addUser = (userId: number) => {
    setShowAddUserModal(false);
    createSupportContractUser({
      userId: +userId!,
      supportContractId: +id!
    }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      } else {
        dispatch(showNotification({
          text: 'Support Contract User added',
          type: 'success',
          show: true
        }));
      }
    });
  };

  useEffect(() => {
    if (isDeleteUserSuccess) {
      dispatch(showNotification({
        text: 'Support Contract User removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteUserSuccess]);

  useEffect(() => {
    setIsLoading(isAddingUserData && isDeletingUserData);
  }, [isAddingUserData, isDeletingUserData]);

  return (
    <>
      <ModalWindow
        show={showAddUserModal}
        title="Add User"
        closeModal={() => setShowAddUserModal(false)}
      >
        <DataGrid<IUser>
          keyExtractor={(col) => col.id.toString()}
          styleProps={{
            isSelectedEven: true
          }}
          events={{ onRowClick: (col) => addUser(col.id) }}
          defaultGridProps={{
            defaultSortDirection: 'desc',
            defaultPageSize: 6
          }}
          columns={[
            {
              field: 'firstName',
              title: 'First name',
            },
            {
              field: 'lastName',
              title: 'Last name'
            },
            {
              field: 'companyName',
              title: 'Company name',
            },
            {
              field: 'email',
              title: 'Email',
            },
          ]}
          apiProps={{
            api: userApi,
            apiMethodName: 'useLazyUsersQuery'
          }}
        />
      </ModalWindow>
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteUserConfirm(idToDelete)}
      />
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Users
        </Typography>
        {hasUsersPermission && (
          <>
            <Btn
              className={classes.inline_btn}
              link={`${book.admin.user()}/new?supportContract=${id}`}
            >
              Create new user
            </Btn>
            <Btn
              className={classes.inline_btn}
              click={() => setShowAddUserModal(true)}
            >
              Add user
            </Btn>
          </>
        )}
      </Toolbar>
      <TableContainer component={Paper}>
        <Scrollbar>
          <Table className={classes.striped_table}>
            <TableHead className={classes.table_head}>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Phone</TableCell>
                {hasUsersPermission && (
                  <TableCell>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((user: ISupportContractUser) => (
                <TableRow
                  key={user.userContractMapId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {user.email}
                  </TableCell>
                  <TableCell>
                    {user.firstName}
                  </TableCell>
                  <TableCell>
                    {user.lastName}
                  </TableCell>
                  <TableCell>
                    {user.companyName}
                  </TableCell>
                  <TableCell>
                    {user.phone}
                  </TableCell>
                  {hasUsersPermission && (
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton color="primary">
                          <Link to={book.admin.user(user.userId)}>
                            <EditIcon />
                          </Link>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => setIdToDelete(user.userContractMapId)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
};

export default SupportContractUsers;
