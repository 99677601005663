import React, { memo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Avatar } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Logout } from '@mui/icons-material';
import cookies from 'js-cookie';
import MediaQuery from 'react-responsive';

import { cookieNames } from '@monorepo/common';

import { useDispatch } from 'react-redux';
import IconLogo from '../Icons/IconLogo';
import { useAppSelector } from '../../app/hooks';
import BurgerMenu from '../BurgerMenu';
import { changeStatusAuth } from '../../features/sliceAuth';

import { useStyles } from './styles';

// interface IHeaderProps  {}

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { photo, userName } = useAppSelector(({ profile }) => ({
    userName: profile.UserName,
    photo: profile.UserAvatar,
  }));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    cookies.remove(cookieNames.access_token);
    cookies.remove(cookieNames.refresh_token);
    dispatch(changeStatusAuth(false));
  };
  const forSrc = photo ? { src: photo } : {};

  return (
    <AppBar
      position="sticky"
      className={classes.header}
    >
      <Toolbar>
        <BurgerMenu />
        <IconLogo className={classes.logo} />

        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          style={{ marginLeft: 'auto' }}
        >
          <Avatar
            {...forSrc}
            className={classes.avatar}
          >
            {!photo && (userName ? userName.charAt(0).toUpperCase() : '-')}
          </Avatar>
          <MediaQuery minWidth={992}>
            {userName && <h5 className={classes.userName}>{userName}</h5>}
          </MediaQuery>
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
          }}
          classes={{ paper: classes.dropdown_styles }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MediaQuery maxWidth={992}>
            <MenuItem>
              {userName && <h5 className={classes.userName}>{userName}</h5>}
            </MenuItem>
          </MediaQuery>

          <MenuItem>
            <ListItemIcon onClick={logOut}>
              <Logout fontSize="small" />
              <span
                className={classes.text_item}
                role="presentation"
              >
                Logout
              </span>
            </ListItemIcon>
          </MenuItem>
        </Menu>

      </Toolbar>
    </AppBar>
  );
};

export default memo(Header);
