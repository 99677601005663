import React, { ReactChild, ReactElement, ReactNode } from 'react';
import { CardContent, CardHeader } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from '../styles';
import ErrorBoundary from '../../ErrorBoundary';

interface ICardWrapperTopPanelProps {
  title?: string,
  titleCenter?: boolean,
  classNameFotTitle?: string,
  childrenTitleRight?: ReactChild | ReactNode | ReactElement,
}
const CardWrapperTopPanel = ({ titleCenter, childrenTitleRight, title, classNameFotTitle }:ICardWrapperTopPanelProps) => {
  const classes = useStyles();
  if (!title) {
    return null;
  }
  return (
    <ErrorBoundary>
      <CardContent className={clsx({
        [classes.titleCenter]: titleCenter
      })}
      >
        {!childrenTitleRight ? (
          <CardHeader
            title={title}
            style={{ padding: 0 }}
            className={clsx(classes.headerTitle, classNameFotTitle, {
              [classes.titleCenter]: titleCenter
            })}
          />
        ) : (
          <>
            <div className={classes.wrapper_for_head}>
              <CardHeader
                title={title}
                style={{ padding: 0 }}
                className={clsx(classes.headerTitle, classNameFotTitle, {
                  [classes.titleCenter]: titleCenter
                })}
              />
              {childrenTitleRight}
            </div>

          </>
        )}
      </CardContent>
    </ErrorBoundary>
  );
};

export default CardWrapperTopPanel;
