import { baseUrls } from '@monorepo/common';

export const endpoints = {
  dashboardUrl: baseUrls.adminApi,
  login: '/Account/Login',
  googleLogin: '/Account/GoogleLogin',
  exportSalesOrders: '/Report/ExportSalesOrders',
  exportSalesPayments: '/Report/ExportSalesPayments',
  exportRenewalQuotes: '/RenewalQuote/Export',
  activeContractMailingList: '/Report/ActiveContractMailingList',
  salesByProductGroups: '/Report/SalesByProductGroups',
  totalSalesByProductGroups: '/Report/TotalSalesByProductGroups',
  mostRecentOrdersList: '/Report/MostRecentOrdersList',
  supportContracts: '/SupportContract/List',
  supportContract: '/SupportContract/Details',
  supportContractEdit: '/SupportContract/Edit',
  supportContractAddUser: '/SupportContract/CreateUserContractMap',
  supportContractDeleteUser: '/SupportContract/DeleteUserContractMap',
  users: '/User/List',
  user: '/User/Details',
  userCreate: '/User/Create',
  userEdit: '/User/Edit',
  userLoginReset: '/User/LoginReset',
  licensesBase: '/License',
  licenses: '/License/List',
  license: '/License/Details',
  licenseLimitTypes: '/License/LimitTypesList',
  licenseHash: '/License/LicenseHash',
  licenseEditLimit: '/License/EditLicenseLimit',
  licenseDeleteLimit: '/License/DeleteLicenseLimit',
  coreLogs: '/CoreLog/List',
  coreLogCreateTask: '/CoreLog/CreateTask',
  logs: '/Log/List',
  logCreateTask: '/Log/CreateTask',
  products: '/Product/List',
  product: '/Product/Details',
  productCreate: '/Product/Create',
  productEdit: '/Product/Edit',
  productDelete: '/Product/Delete',
  adminUsers: '/AdminUser/List',
  adminUser: '/AdminUser/Details',
  adminUserCreate: '/AdminUser/Create',
  adminUserEdit: '/AdminUser/Edit',
  adminUserDelete: '/AdminUser/Delete',
  quotesBase: '/Quote',
  quotes: '/Quote/List',
  quote: '/Quote/Details',
  quoteCreate: '/Quote/Create',
  quoteCreateQuoteDetail: '/Quote/CreateQuoteDetail',
  quoteCopy: '/Quote/Copy',
  quoteEdit: '/Quote/Edit',
  quoteEditQuoteDetail: '/Quote/EditQuoteDetail',
  quoteProcess: '/Quote/ProcessQuote',
  quoteDelete: '/Quote/Delete',
  quoteDeleteQuoteDetail: '/Quote/DeleteQuoteDetail',
  ordersBase: '/Order',
  orders: '/Order/List',
  orderBuyerBillingAutocomplete: '/Order/BuyerBillingAutocomplete',
  orderBuyerShipmentAutocomplete: '/Order/BuyerShipmentAutocomplete',
  order: '/Order/Details',
  orderEdit: '/Order/Edit',
  orderSendCustomerReceiptEmail: '/Order/SendCustomerReceiptEmail',
  orderDelete: '/Order/Delete',
  renewalQuotes: '/RenewalQuote/List',
  renewalQuote: '/RenewalQuote/Details',
  renewalQuoteEmailsPreview: '/RenewalQuote/EmailsPreview',
  renewalQuoteEmailPreview: '/RenewalQuote/EmailPreview',
  renewalQuoteEdit: '/RenewalQuote/Edit',
  renewalQuoteGenerator: '/RenewalQuote/GenerateQuotes',
  renewalQuoteSendEmail: '/RenewalQuote/SendRenewalEmail',
  renewalQuoteSendEmails: '/RenewalQuote/SendRenewalEmails',
  renewalQuoteDelete: '/RenewalQuote/Delete',
  messageTemplatesBase: '/MessageTemplate',
  messageTemplates: '/MessageTemplate/List',
  messageTemplate: '/MessageTemplate/Details',
  messageTemplateCreate: '/MessageTemplate/Create',
  messageTemplateEdit: '/MessageTemplate/Edit',
  messageTemplateDelete: '/MessageTemplate/Delete',
  onlineAccounts: '/OnlineAccount/List',
  onlineAccount: '/OnlineAccount/Details',
  onlineAccountEdit: '/OnlineAccount/Edit',
  onlineAccountCreateLimit: '/OnlineAccount/CreateLimit',
  onlineAccountEditLimit: '/OnlineAccount/EditLimit',
  onlineAccountDeleteLimit: '/OnlineAccount/DeleteLimit',
  onlineAccountCreateSender: '/OnlineAccount/CreateSender',
  onlineAccountEditSender: '/OnlineAccount/EditSender',
  onlineAccountDeleteSender: '/OnlineAccount/DeleteSender',
  onlineAccountProductPlanLimits: '/OnlineAccount/ProductPlanLimits',
  onlineAccountUsers: '/OnlineAccount/Users',
  onlineAccountLogin7: '/OnlineAccount/Login7Url',
  applications: '/Application/List',
  application: '/Application/Details',
  applicationCreate: '/Application/Create',
  applicationEdit: '/Application/Edit',
  applicationProdToApps: '/Application/ProdToAppsList',
  applicationCreateProdToApp: '/Application/CreateProdToApp',
  applicationEditProdToApp: '/Application/EditProdToApp',
  applicationDeleteProdToApp: '/Application/DeleteProdToApp',
  applicationCreateMaintMap: '/Application/CreateApplicationMaintMap',
  applicationEditMaintMap: '/Application/EditApplicationMaintMap',
  applicationDeleteMaintMap: '/Application/DeleteApplicationMaintMap',
  applicationCreateUpgradeMap: '/Application/CreateApplicationUpgradeMap',
  applicationEditUpgradeMap: '/Application/EditApplicationUpgradeMap',
  applicationDeleteUpgradeMap: '/Application/DeleteApplicationUpgradeMap',
  releases: '/Release/List',
  release: '/Release/Details',
  releaseCreate: '/Release/Create',
  releaseEdit: '/Release/Edit',
  releaseDelete: '/Release/Delete',
  salesReps: '/SalesRep/List',
  salesRep: '/SalesRep/Details',
  salesRepCreate: '/SalesRep/Create',
  salesRepEdit: '/SalesRep/Edit',
  salesRepDelete: '/SalesRep/Delete',
  mergeTexts: '/MergeText/List',
  mergeText: '/MergeText/Details',
  mergeTextEdit: '/MergeText/Edit',
};
