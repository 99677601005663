import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inline_btn: {
    display: 'inline-block',
    marginLeft: 8,
    overflow: 'unset',
    fontWeight: 'bold',
  },
  popup_btn: {
    margin: 'auto',
  },
}));
