import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { book, Btn, CardWrapper } from '@monorepo/common';
import { Box, Grid, TextField } from '@mui/material';
import { useStyles } from './styles';
import GridField from '../../components/GridField';
import { showNotification } from '../../features/ui/sliceNotification';
import ConfirmModal from '../../components/ConfirmModal';
import { useCreateSalesRepMutation, useDeleteSalesRepMutation, useGetSalesRepQuery, useUpdateSalesRepMutation } from '../../services/salesRep';
import { ISalesRepDetails } from '../../services/salesRep/interfaces';

const SalesRep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const isNew = id === 'new';

  const [fields, setFields] = useState<ISalesRepDetails>({
    id: 0,
    name: '',
    email: '',
    phoneNumber: '',
    initials: '',
  });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data, isLoading: isLoadingData, refetch } = useGetSalesRepQuery(id!, { skip: isNew });
  const [updateSalesRep, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateSalesRepMutation();
  const [createSalesRep, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateSalesRepMutation();
  const [deleteSalesRep, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteSalesRepMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create Sales Rep';
  } else if (data) {
    pageTitle = `Sales Rep #${data && data.id}`;
  } else {
    pageTitle = 'Sales Rep not found';
  }

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields!,
      [name]: value
    });
  };

  const save = () => {
    if (isNew) {
      createSalesRep(fields!);
    } else {
      updateSalesRep(fields!);
    }
  };

  const deleteConfirm = () => {
    deleteSalesRep(+id!);
    setShowDeleteModal(false);
    navigate(book.admin.salesRep());
  };

  useEffect(() => {
    if (data) {
      setFields(data);
    }
  }, [data]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Sales Rep created',
        type: 'success',
        show: true
      }));
      navigate(book.admin.salesRep());
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'Sales Rep updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Sales Rep removed',
        type: 'success',
        show: true
      }));
      navigate(book.admin.salesRep());
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Sales Rep"
      title={pageTitle}
      maxWidth="100%"
      isLoading={isLoadingData || isCreatingData || isUpdatingData || isDeletingData}
      childrenTitleRight={fields
        && (
          <Box>
            <Btn
              className={classes.inline_btn}
              click={save}
              disabled={!(fields.adminUserId && fields.name?.trim() && fields.email?.trim())}
            >
              Save
            </Btn>
            {!isNew
              && (
                <Btn
                  className={classes.inline_btn}
                  click={() => setShowDeleteModal(true)}
                >
                  Delete
                </Btn>
              )}
          </Box>
        )}
    >
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteConfirm}
      />
      {fields && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Admin User ID"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                inputProps={{ inputMode: 'numeric' }}
                value={fields.adminUserId}
                error={!fields.adminUserId}
                helperText={!fields.adminUserId && 'Admin User ID field is required'}
                onChange={(event) => {
                  changeCellField('adminUserId', +event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.name || ''}
                fullWidth
                error={!fields.name?.trim()}
                helperText={!fields.name?.trim() && 'The Name field is required'}
                onChange={(event) => {
                  changeCellField('name', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.email || ''}
                fullWidth
                error={!fields.email?.trim()}
                helperText={!fields.email?.trim() && 'The Email field is required'}
                onChange={(event) => {
                  changeCellField('email', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Phone number"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.phoneNumber || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('phoneNumber', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Initials"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.initials || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('initials', event.target.value);
                }}
                size="small"
              />
            </GridField>
          </Grid>
        </Box>
      )}
    </CardWrapper>
  );
};

export default SalesRep;
