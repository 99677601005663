import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline'
  },
  link_wrapper: {
    border: 0,
    whiteSpace: 'nowrap',
  },
}));
