import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IBuyerAutocomplete, IOrder, IOrderDetails, IOrderListRequest, IOrdersBase } from './interfaces';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  tagTypes: ['order'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getOrdersBase: build.query<IOrdersBase, string>({
      query: () => ({
        url: endpoints.ordersBase,
      }),
    }),
    getOrders: build.query<IListResp<IOrder>, IOrderListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        salesRepId,
        paymentTypeId,
        startDate,
        endDate
      }) => ({
        url: endpoints.orders,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          salesRepId,
          paymentTypeId,
          startDate,
          endDate
        }
      }),
    }),
    getBuyerBillingAutocomplete: build.query<IBuyerAutocomplete[], string>({
      query: (searchText) => ({
        url: endpoints.orderBuyerBillingAutocomplete,
        params: { searchText }
      }),
    }),
    getBuyerShipmentAutocomplete: build.query<IBuyerAutocomplete[], string>({
      query: (searchText) => ({
        url: endpoints.orderBuyerShipmentAutocomplete,
        params: { searchText }
      }),
    }),
    getOrder: build.query<IOrderDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.order}/${id}`,
      }),
    }),
    updateOrder: build.mutation<IOrderDetails, IOrderDetails>({
      query: ({
        id,
        salesRepId,
        status,
        paymentType,
        isBogus,
        orderDate,
        userIp,
        billEmail,
        billAddress1,
        billAddress2,
        billCity,
        billCompany,
        billCountry,
        billFirstName,
        billLastName,
        billPhone,
        billProvince,
        billState,
        billZip,
        shipEmail,
        shipAddress1,
        shipAddress2,
        shipCity,
        shipCompany,
        shipCountry,
        shipFirstName,
        shipLastName,
        shipPhone,
        shipProvince,
        shipState,
        shipZip,
      }) => ({
        url: `${endpoints.orderEdit}/${id}`,
        method: 'PATCH',
        body: {
          id,
          salesRepId,
          status,
          paymentType,
          isBogus,
          orderDate,
          userIp,
          billEmail,
          billAddress1,
          billAddress2,
          billCity,
          billCompany,
          billCountry,
          billFirstName,
          billLastName,
          billPhone,
          billProvince,
          billState,
          billZip,
          shipEmail,
          shipAddress1,
          shipAddress2,
          shipCity,
          shipCompany,
          shipCountry,
          shipFirstName,
          shipLastName,
          shipPhone,
          shipProvince,
          shipState,
          shipZip,
        }
      }),
    }),
    sendCustomerReceiptEmail: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.orderSendCustomerReceiptEmail}/${id}`,
        method: 'POST',
      }),
    }),
    deleteOrder: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.orderDelete}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetOrdersBaseQuery,
  useLazyGetOrdersQuery,
  useGetBuyerBillingAutocompleteQuery,
  useGetBuyerShipmentAutocompleteQuery,
  useGetOrderQuery,
  useUpdateOrderMutation,
  useSendCustomerReceiptEmailMutation,
  useDeleteOrderMutation,
} = orderApi;
