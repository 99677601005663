import { HelmetProvider, Helmet } from 'react-helmet-async';

interface ICardWrapperTitleProps {
  title?: string
}
const CardWrapperTitle = ({ title }:ICardWrapperTitleProps) => (
  <HelmetProvider>
    {title && (
    <Helmet>
      <title>
        {title}
        {' '}
        | Checkbox
      </title>
    </Helmet>
    )}
  </HelmetProvider>
);

export default CardWrapperTitle;
