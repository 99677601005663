import React, { memo } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { book } from '@monorepo/common';
import Login from '../Pages/Login/Login';

const PublicRoutes = () => (
  <Routes>
    <Route
      path={book.admin.account.login}
      element={<Login />}
    />
    <Route
      path="*"
      element={(
        <Navigate
          replace
          to={book.admin.account.login}
        />
      )}
    />
  </Routes>
);

export default memo(PublicRoutes);
