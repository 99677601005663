import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, MenuItem, Select, TableCell, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Btn, DataGrid, Loader } from '@monorepo/common';
import { IApplicationProductMap, IApplicationProductMapItem } from '../../../services/application/interfaces';
import { applicationApi, useCreateApplicationProdToAppMutation, useDeleteApplicationProdToAppMutation, useUpdateApplicationProdToAppMutation } from '../../../services/application';
import { ISelectListItem } from '../../../interfaces';
import ConfirmModal from '../../../components/ConfirmModal';
import { showNotification } from '../../../features/ui/sliceNotification';

interface IApplicationProdToAppsProps {
  applicationId: number,
  applicationName: string,
  applications: ISelectListItem[],
  products: ISelectListItem[],
}

const ApplicationProdToApps = ({ applicationId, applicationName, applications, products }: IApplicationProdToAppsProps) => {
  const dispatch = useDispatch();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [fields, setFields] = useState({} as IApplicationProductMap);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [createProdToApp, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateApplicationProdToAppMutation();
  const [updateProdToApp, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateApplicationProdToAppMutation();
  const [deleteProdToApp, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteApplicationProdToAppMutation();

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const addProdToApp = () => {
    setActiveRow(0);
    setFields({
      applicationId,
      productId: +products[0].value,
    });
  };

  const editProdToApp = (prodToApp: IApplicationProductMap) => {
    setActiveRow(prodToApp.productId);
    setFields({
      applicationId,
      productId: prodToApp.productId,
    });
  };

  const saveProdToApp = (prodToApp?: IApplicationProductMap) => {
    if (prodToApp) {
      updateProdToApp({
        applicationId,
        productId: prodToApp.productId,
        newApplicationId: fields.applicationId,
        newProductId: fields.productId,
      });
    } else {
      createProdToApp(fields);
    }
  };

  const deleteProdToAppConfirm = (productId: number) => {
    deleteProdToApp({
      applicationId,
      productId,
    });
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      setActiveRow(null);
      dispatch(showNotification({
        text: 'Prod To App created',
        type: 'success',
        show: true
      }));
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setActiveRow(null);
      dispatch(showNotification({
        text: 'Prod To App updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Prod To App deleted',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Prod To App
        </Typography>
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Btn click={addProdToApp}>
            Add prod to app
          </Btn>
        </Box>
      </Toolbar>
      {(isCreatingData || isUpdatingData || isDeletingData) && (
        <Loader
          position="absolute"
          size={70}
          blur
        />
      )}
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteProdToAppConfirm(idToDelete)}
      />
      <DataGrid<IApplicationProductMapItem>
        keyExtractor={(col) => col.productId.toString()}
        globalId={activeRow}
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        defaultGridProps={{
          defaultPageSize: 10
        }}
        tableProps={{
          toTheTopOfTable: activeRow === 0 && (
            <TableRow>
              <TableCell>
                <Select
                  value={fields.applicationId}
                  size="small"
                  onChange={(event) => changeCellField('applicationId', +event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  {applications.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  value={fields.productId}
                  size="small"
                  onChange={(event) => changeCellField('productId', +event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  {products.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Tooltip title="Save">
                  <IconButton
                    onClick={() => saveProdToApp()}
                    color="primary"
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={() => setActiveRow(null)}
                    color="error"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ),
        }}
        columns={[
          {
            field: 'applicationId',
            title: 'Application',
            template: () => applicationName,
            editTemplate: () => (
              <Select
                value={fields.applicationId}
                size="small"
                onChange={(event) => changeCellField('applicationId', +event.target.value)}
                sx={{ textAlign: 'left' }}
              >
                {applications.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                  >
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            )
          },
          {
            rowId: (col) => col.productId,
            field: 'productId',
            title: 'Product',
            template: (col) => {
              const product = products.find((p) => p.value === col.productId.toString());
              return product?.text;
            },
            editTemplate: () => (
              <Select
                value={fields.productId}
                size="small"
                onChange={(event) => changeCellField('productId', +event.target.value)}
                sx={{ textAlign: 'left' }}
              >
                {products.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                  >
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            ),
          },
          {
            field: 'lastCol',
            title: 'empty',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => editProdToApp(col)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => setIdToDelete(col.productId)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
            editTemplate: (col) => (
              <>
                <Tooltip title="Save">
                  <IconButton
                    onClick={() => saveProdToApp(col)}
                    color="primary"
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={() => setActiveRow(null)}
                    color="error"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          },
        ]}
        apiProps={{
          api: applicationApi,
          apiMethodName: 'useLazyGetApplicationProdToAppsQuery',
          apiParams: {
            applicationId
          }
        }}
      />
    </>
  );
};

export default ApplicationProdToApps;
