import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, CardWrapper } from '@monorepo/common';
import { Box, Grid, Select, Switch, TextField, MenuItem, SelectChangeEvent } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useGetAdminUserQuery, useCreateAdminUserMutation, useUpdateAdminUserMutation, useDeleteAdminUserMutation } from '../../services/adminUser';
import { showNotification } from '../../features/ui/sliceNotification';
import { IAdminUserDetails } from '../../services/adminUser/interfaces';
import { useStyles } from './styles';
import GridField from '../../components/GridField';
import { Permission } from '../../constants/permission';
import ConfirmModal from '../../components/ConfirmModal';

const AdminUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const isNew = id === 'new';

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [asanaUserId, setAsanaUserId] = useState('');
  const [slackUserId, setSlackUserId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [permissions, setPermissions] = useState<number[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const permissionsList = Object.entries(Permission).filter(([, value]) => typeof value === 'string').map(([value, text]) => ({ value, text }));

  const getNewAdminUser = () => {
    const adminUser = {
      email,
      name,
      userName,
      isActive,
      isSuperUser,
      isGoogleUser,
      asanaUserId,
      slackUserId,
      phoneNumber,
      permissions,
      createdDate: Date.now(),
    } as unknown as IAdminUserDetails;

    return {
      data: adminUser,
      isLoading: false,
      refetch: () => { }
    };
  };

  const { data, isLoading: isLoadingData, refetch } = isNew ? getNewAdminUser() : useGetAdminUserQuery(id || '', { skip: !id });
  const [createAdminUser, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateAdminUserMutation();
  const [updateAdminUser, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateAdminUserMutation();
  const [deleteAdminUser, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteAdminUserMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create Admin User';
  } else if (data) {
    pageTitle = `Admin User #${data && data.id}`;
  } else {
    pageTitle = 'Admin User not found';
  }

  const save = () => {
    const adminUser = {
      id: +id!,
      email,
      name,
      userName,
      isActive,
      isSuperUser,
      isGoogleUser,
      asanaUserId,
      slackUserId,
      phoneNumber,
      permissions
    };

    if (isNew) {
      createAdminUser(adminUser);
    } else {
      updateAdminUser(adminUser);
    }
  };

  const deleteAdminUserConfirm = () => {
    deleteAdminUser(+id!);
    setShowDeleteModal(false);
    navigate(book.admin.adminUser());
  };

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const result: string[] = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

    setPermissions(result.map((p) => +p));
  };

  useEffect(() => {
    if (data) {
      setEmail(data.email || '');
      setName(data.name || '');
      setUserName(data.userName || '');
      setIsActive(data.isActive);
      setIsSuperUser(data.isSuperUser);
      setIsGoogleUser(data.isGoogleUser);
      setAsanaUserId(data.asanaUserId || '');
      setSlackUserId(data.slackUserId || '');
      setPhoneNumber(data.phoneNumber || '');
      setPermissions(data.permissions || []);
    }
  }, [data]);

  useEffect(() => {
    if (isCreateSuccess) {
      navigate(book.admin.adminUser());
      dispatch(showNotification({
        text: 'Admin User created',
        type: 'success',
        show: true
      }));
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'Admin User updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Admin User deleted',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Admin User"
      title={pageTitle}
      maxWidth="100%"
      isLoading={isLoadingData || isCreatingData || isUpdatingData || isDeletingData}
      childrenTitleRight={data
        && (
          <Box>
            <Btn
              className={classes.inline_btn}
              click={save}
            >
              Save
            </Btn>
            {!isNew
              && (
                <Btn
                  className={classes.inline_btn}
                  click={() => setShowDeleteModal(true)}
                >
                  Delete
                </Btn>
              )}
          </Box>
        )}
    >
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteAdminUserConfirm}
      />
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Active"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={isActive}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setIsActive(event.target.checked);
                }}
                name="isActive"
              />
            </GridField>
            <GridField
              label="Is Super User"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={isSuperUser}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setIsSuperUser(event.target.checked);
                }}
                name="isSuperUser"
              />
            </GridField>
            <GridField
              label="Is Google user"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={isGoogleUser}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setIsGoogleUser(event.target.checked);
                }}
                name="isGoogleUser"
              />
            </GridField>
            <GridField
              label="Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Username"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={userName}
                onChange={(event) => {
                  setUserName(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Phone Number"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={phoneNumber}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Asana User ID"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={asanaUserId}
                onChange={(event) => {
                  setAsanaUserId(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Slack User ID"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={slackUserId}
                onChange={(event) => {
                  setSlackUserId(event.target.value);
                }}
                size="small"
              />
            </GridField>
            {!isSuperUser && (
              <GridField
                label="Permissions"
                labelmd={3}
                fieldmd={9}
              >
                <Select
                  multiple
                  size="small"
                  value={permissions.map((p) => p.toString())}
                  onChange={handleChange}
                >
                  {permissionsList && permissionsList.map((permission) => (
                    <MenuItem
                      key={permission.value}
                      value={permission.value}
                    >
                      {permission.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridField>
            )}
            <GridField
              label="Created Date"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data && data.createdDate && moment(data.createdDate).format('YYYY-MM-DD')}
            </GridField>
            <GridField
              label="Last Login Date"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data && data.lastLoginDate && moment(data.lastLoginDate).format('YYYY-MM-DD')}
            </GridField>
          </Grid>
        </Box>
      )}
    </CardWrapper>
  );
};

export default AdminUser;
