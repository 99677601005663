import { book, Btn, CardWrapper, DataGrid } from '@monorepo/common';
import CheckIcon from '@mui/icons-material/Check';
import AdminLink from '../../components/AdminLink';
import { IApplication } from '../../services/application/interfaces';
import { applicationApi } from '../../services/application';

const Applications = () => (
  <CardWrapper
    titleCenter
    centerText
    helmet="Applications"
    title="Applications"
    maxWidth="100%"
    childrenTitleRight={(
      <Btn link={`${book.admin.application()}/new`}>
        Create
      </Btn>
    )}
  >
    <DataGrid<IApplication>
      keyExtractor={(col) => col.id.toString()}
      sortable
      styleProps={{
        isSelectedEven: true
      }}
      defaultGridProps={{
        defaultSortBy: 'id',
        defaultSortDirection: 'desc',
        defaultPageSize: 25
      }}
      columns={[
        {
          field: 'id',
          title: 'ID',
          template: (col) => (
            <AdminLink
              url={book.admin.application(col.id)}
              title={col.id}
            />
          )
        },
        {
          field: 'name',
          title: 'Name',
        },
        {
          field: 'isActive',
          title: 'Active',
          align: 'center',
          hideCurrentSortable: true,
          template: (col) => (
            <>
              {col.isActive ? <CheckIcon /> : ''}
            </>
          )
        },
      ]}
      apiProps={{
        api: applicationApi,
        apiMethodName: 'useLazyGetApplicationsQuery',
      }}
    />
  </CardWrapper>
);

export default Applications;
