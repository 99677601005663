import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useStyles } from '../styles';
import { toggleMobileMenu } from '../../../features/ui/sliceMobileMenu';

const GeneralOverlay = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShow(true);
    }, 1);
  }, []);

  const closeMenu = () => {
    dispatch(toggleMobileMenu(false));
  };

  return (
    <div
      role="presentation"
      onClick={closeMenu}
      className={clsx(classes.overlay, {
        [classes.active]: isShow
      })}
    />
  );
};

export default memo(GeneralOverlay);
