import React from 'react';

import { ICardWrapperModalWindow } from '../interfaceCardWrapper';
import ModalWindow from '../../../components/ModalWindow';

interface ICardWrapperModalWindowProps {
  modalWindowOptions?: ICardWrapperModalWindow
}
const CardWrapperModalWindow = ({ modalWindowOptions }:ICardWrapperModalWindowProps) => (
  <ModalWindow
    text={modalWindowOptions?.text}
    errorMode={modalWindowOptions?.errorMode}
    show={!!modalWindowOptions?.show}
    title={modalWindowOptions?.title}
    closeModal={() => modalWindowOptions?.closeModal()}
  >
    {modalWindowOptions?.modalBody}
  </ModalWindow>
);

export default CardWrapperModalWindow;
