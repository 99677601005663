import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Btn, CardWrapper, DataGrid, book } from '@monorepo/common';
import { debounce, IconButton, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useStyles } from './styles';
import { productApi, useDeleteProductMutation, useUpdateProductMutation } from '../../services/product';
import { IProduct, IProductItem } from '../../services/product/interfaces';
import { showNotification } from '../../features/ui/sliceNotification';
import TableToolBar from './components/TableToolBar';
import AdminLink from '../../components/AdminLink';
import ConfirmModal from '../../components/ConfirmModal';

interface IParam {
  name: string,
  value?: any
}

const Products = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState<string>('');
  const [isActive, setIsActive] = useState(true);
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [fields, setFields] = useState<IProduct>({ id: 0, name: '', price: 0, partNumber: '' });
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [updateProduct, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateProductMutation();
  const [deleteProduct, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteProductMutation();

  const changeCellField = ({ name, value }: IParam) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  const editProduct = (product: IProduct) => {
    setActiveRow(product.id);
    setFields(product);
    setIsError(false);
  };

  const saveProduct = (product: IProduct) => {
    if (fields.name === '' || fields.price === 0 || fields.partNumber === '') {
      setIsError(true);
      return;
    }

    updateProduct({ ...fields, id: product.id, description: null, isActive: false });
  };

  const deleteProductConfirm = (productIdToDelete: number) => {
    deleteProduct(productIdToDelete);
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      setActiveRow(null);
      dispatch(showNotification({
        text: 'Product updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Product deleted',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Products"
      title="Products"
      maxWidth="100%"
      isLoading={isDeletingData || isUpdatingData}
      childrenTitleRight={(
        <Btn link={`${book.admin.product()}/new`}>
          Create
        </Btn>
      )}
    >
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteProductConfirm(idToDelete)}
      />
      <DataGrid<IProductItem>
        keyExtractor={(col) => col.id.toString()}
        globalId={activeRow}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            isActive={isActive}
            setIsActive={setIsActive}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultSortDirection: 'desc',
          defaultPageSize: 25
        }}
        columns={[
          {
            rowId: (col) => col.id,
            field: 'id',
            title: 'ID',
            template: (col) => (
              <AdminLink
                url={book.admin.product(col.id)}
                title={col.id}
              />
            ),
            editTemplate: (col) => (<>{col.id}</>)
          },
          {
            field: 'name',
            title: 'Name',
            editTemplate: () => (
              <TextField
                value={fields.name || ''}
                className={classes.field}
                error={isError}
                size="small"
                onChange={(val) => {
                  changeCellField({
                    name: 'name',
                    value: val.target.value || ''
                  });
                }}
              />
            )
          },
          {
            field: 'price',
            title: 'Price',
            hideCurrentSortable: true,
            editTemplate: () => (
              <TextField
                value={fields.price}
                className={classes.field}
                error={isError}
                type="number"
                size="small"
                onChange={(val) => {
                  changeCellField({
                    name: 'price',
                    value: +val.target.value
                  });
                }}
              />
            )
          },
          {
            field: 'partNumber',
            title: 'Part #',
            editTemplate: () => (
              <TextField
                value={fields.partNumber}
                className={classes.field}
                error={isError}
                size="small"
                onChange={(val) => {
                  changeCellField({
                    name: 'partNumber',
                    value: val.target.value
                  });
                }}
              />
            )
          },
          {
            field: 'lastCol',
            title: 'empty',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => editProduct(col)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => setIdToDelete(col.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
            editTemplate: (col) => (
              <>
                <Tooltip title="Save">
                  <IconButton
                    onClick={() => saveProduct(col)}
                    color="primary"
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={() => setActiveRow(null)}
                    color="error"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          },
        ]}
        apiProps={{
          api: productApi,
          apiMethodName: 'useLazyGetProductsQuery',
          apiParams: {
            searchText,
            isActive
          },
        }}
      />
    </CardWrapper>
  );
};

export default Products;
