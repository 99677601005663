import { useState } from 'react';
import { Box } from '@mui/material';
import { baseUrls, Btn } from '@monorepo/common';
import { useStyles } from '../styles';
import ConfirmModal from '../../../components/ConfirmModal';

interface IHeaderToolBarProps {
  guid: string,
  isValid: boolean,
  save: () => void,
  sendCustomerReceiptEmail: () => void,
  deleteOrderConfirm: () => void,
}

const HeaderToolBar = ({ guid, isValid, save, sendCustomerReceiptEmail, deleteOrderConfirm }: IHeaderToolBarProps) => {
  const classes = useStyles();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteOrderConfirm}
      />
      <Btn
        className={classes.inline_btn}
        disabled={!isValid}
        click={save}
      >
        Save
      </Btn>
      <Btn
        className={classes.inline_btn}
        click={() => setShowDeleteModal(true)}
      >
        Delete
      </Btn>
      <Btn
        className={classes.inline_btn}
        link={`${baseUrls.checkbox}/mvc/api/order/${guid}/receipt-pdf`}
        external
        target="_blank"
      >
        Generate Receipt PDF
      </Btn>
      <Btn
        className={classes.inline_btn}
        click={() => sendCustomerReceiptEmail()}
      >
        Send Customer Receipt
      </Btn>
    </Box>
  );
};

export default HeaderToolBar;
