import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  switch_wrapper: {
    textAlign: 'left',
    '& label': {
      marginTop: 5
    }
  },
  field: {
    marginBottom: 0,
    background: '#fff'
  }
}));
