import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, CardWrapper, DataGrid } from '@monorepo/common';
import { Box, Grid, Paper, Switch, TableContainer, TextField, Toolbar, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useCreateUserMutation, useGetUserQuery, useUpdateUserLoginMutation, useUpdateUserMutation } from '../../services/user';
import { showNotification } from '../../features/ui/sliceNotification';
import { IUserResp } from '../../services/user/interfaces';
import { supportContractApi, useCreateSupportContractUserMutation } from '../../services/supportContract';
import { useStyles } from './styles';
import { ISupportContract } from '../../services/supportContract/interfaces';
import { Permission } from '../../constants/permission';
import GridField from '../../components/GridField';
import AdminLink from '../../components/AdminLink';

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const isNew = id === 'new';

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [isActive, setIsActive] = useState(false);

  const [searchParams] = useSearchParams();
  const supportContractId = searchParams.get('supportContract');

  const getNewUser = () => {
    const user = {
      email,
      firstName,
      lastName,
      companyName,
      address1,
      address2,
      city,
      state,
      zip,
      province,
      country,
      phone,
      createdDate: Date.now(),
      isActive: true
    } as unknown as IUserResp;

    return {
      data: user,
      isLoading: false,
      refetch: () => { }
    };
  };

  const { data, isLoading: isLoadingData, refetch } = isNew ? getNewUser() : useGetUserQuery(id || '', { skip: !id });
  const [createUser, { data: createData, isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateUserMutation();
  const [putUser, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateUserMutation();
  const [createSupportContractUser, { isLoading: isAddingUserData, isSuccess: isSupportContractCreateSuccess }] = useCreateSupportContractUserMutation();
  const [updateUserLogin, { isLoading: isUpdatingUserLoginData, isSuccess: isUpdateUserLoginSuccess }] = useUpdateUserLoginMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create User';
  } else if (data) {
    pageTitle = `User #${data && data.id}`;
  } else {
    pageTitle = 'User not found';
  }

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const save = () => {
    const user = {
      id: +id!,
      email,
      firstName,
      lastName,
      companyName,
      address1,
      address2,
      city,
      state,
      zip,
      province,
      country,
      phone,
      isActive
    };

    if (isNew) {
      createUser(user);
    } else {
      putUser(user);
    }
  };

  useEffect(() => {
    if (data) {
      setEmail(data.email || '');
      setFirstName(data.firstName || '');
      setLastName(data.lastName || '');
      setCompanyName(data.companyName || '');
      setAddress1(data.address1 || '');
      setAddress2(data.address2 || '');
      setCity(data.city || '');
      setState(data.state || '');
      setZip(data.zip || '');
      setProvince(data.province || '');
      setCountry(data.country || '');
      setPhone(data.phone || '');
      setIsActive(data.isActive);
    }
  }, [data]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'User created',
        type: 'success',
        show: true
      }));

      if (supportContractId && createData) {
        createSupportContractUser({
          userId: createData,
          supportContractId: +supportContractId!
        });
      } else {
        navigate(book.admin.user());
      }
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'User updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isSupportContractCreateSuccess) {
      dispatch(showNotification({
        text: 'Support Contract User added',
        type: 'success',
        show: true
      }));
      navigate(book.admin.supportContract(+supportContractId!));
    }
  }, [isSupportContractCreateSuccess]);

  useEffect(() => {
    if (isUpdateUserLoginSuccess) {
      dispatch(showNotification({
        text: 'Activation email sent',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateUserLoginSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="User"
      title={pageTitle}
      maxWidth="100%"
      isLoading={isLoadingData || isCreatingData || isUpdatingData || isAddingUserData || isUpdatingUserLoginData}
      childrenTitleRight={data
        && (
          <Box sx={{ whiteSpace: 'nowrap' }}>
            {(!isNew && (
              <Btn
                className={classes.inline_btn}
                click={() => updateUserLogin(+id!)}
              >
                Send Activation Email
              </Btn>
            ))}
            <Btn
              className={classes.inline_btn}
              click={save}
            >
              Save
            </Btn>
          </Box>
        )}
    >
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Active"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={isActive}
                onChange={handleIsActiveChange}
                name="isActive"
              />
            </GridField>
            <GridField
              label="GUID"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data && data.guid}
            </GridField>
            <GridField
              label="Email"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="First Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Last Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Company Name"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={companyName}
                onChange={(event) => {
                  setCompanyName(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Address1"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={address1}
                onChange={(event) => {
                  setAddress1(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Address2"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={address2}
                onChange={(event) => {
                  setAddress2(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="City"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="State"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={state}
                onChange={(event) => {
                  setState(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Zip"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={zip}
                onChange={(event) => {
                  setZip(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Province"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={province}
                onChange={(event) => {
                  setProvince(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Country"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={country}
                onChange={(event) => {
                  setCountry(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Phone"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Created Date"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data && data.createdDate && moment(data.createdDate).format('YYYY-MM-DD')}
            </GridField>
          </Grid>
          {!isNew && (
            <Box>
              <Toolbar sx={{ mt: 2 }}>
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="h6"
                  component="div"
                >
                  Support Contracts
                </Typography>
              </Toolbar>
              <TableContainer component={Paper}>
                <DataGrid<ISupportContract>
                  keyExtractor={(col) => col.mapId.toString()}
                  sortable
                  styleProps={{
                    isSelectedEven: true
                  }}
                  searchableProps={{
                    showSearchField: false,
                  }}
                  defaultGridProps={{
                    defaultSortBy: 'supportContractId',
                    defaultSortDirection: 'desc',
                    defaultPageSize: 25
                  }}
                  columns={[
                    {
                      field: 'supportContractId',
                      title: 'ID',
                      template: (col) => (
                        <AdminLink
                          url={book.admin.supportContract(col.supportContractId)}
                          title={col.supportContractId}
                          permissions={[Permission.SupportContracts]}
                        />
                      )
                    },
                    {
                      field: 'orderId',
                      title: 'Order'
                    },
                    {
                      field: 'firstName',
                      title: 'First name',
                    },
                    {
                      field: 'lastName',
                      title: 'Last name'
                    },
                    {
                      field: 'companyName',
                      title: 'Company name',
                    },
                    {
                      field: 'name',
                      title: 'Name',
                    },
                    {
                      field: 'startDate',
                      title: 'Start date',
                      template: (col) => <>{col.startDate ? moment(col.startDate).format('M/D/YYYY') : '-'}</>,
                    },
                    {
                      field: 'endDate',
                      title: 'End date',
                      template: (col) => <>{col.endDate ? moment(col.endDate).format('M/D/YYYY') : '-'}</>,
                    }
                  ]}
                  apiProps={{
                    api: supportContractApi,
                    apiMethodName: 'useLazyGetSupportContractsQuery',
                    apiParams: {
                      userId: isNew ? undefined : +id!
                    },
                  }}
                />
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </CardWrapper>
  );
};

export default User;
