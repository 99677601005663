import { useState, ChangeEvent, useMemo } from 'react';
import { book, CardWrapper, DataGrid } from '@monorepo/common';
import { debounce } from '@mui/material';
import moment from 'moment';
import { supportContractApi } from '../../services/supportContract';
import { ISupportContract } from '../../services/supportContract/interfaces';
import TableToolBar from './components/TableToolBar';
import AdminLink from '../../components/AdminLink';
import { Permission } from '../../constants/permission';

const SupportContracts = () => {
  const [hideExpired, setHideExpired] = useState(true);
  const [searchText, setSearchText] = useState<string>('');

  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  const handleHideExpiredChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHideExpired(event.target.checked);
  };

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Support Contracts"
      title="Support Contracts"
      maxWidth="100%"
    >
      <DataGrid<ISupportContract>
        keyExtractor={(col) => col.mapId.toString()}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            hideExpired={hideExpired}
            handleHideExpiredChange={handleHideExpiredChange}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'supportContractId',
          defaultSortDirection: 'desc',
          defaultPageSize: 25
        }}
        columns={[
          {
            field: 'supportContractId',
            title: 'ID',
            template: (col) => (
              <AdminLink
                url={book.admin.supportContract(col.supportContractId)}
                title={col.supportContractId}
              />
            )
          },
          {
            field: 'orderId',
            title: 'Order',
            template: (col) => (
              <AdminLink
                url={book.admin.order(col.orderId)}
                title={col.orderId}
                permissions={[Permission.Orders]}
              />
            )
          },
          {
            field: 'firstName',
            title: 'First name',
            hideCurrentSortable: true
          },
          {
            field: 'lastName',
            title: 'Last name',
            hideCurrentSortable: true
          },
          {
            field: 'companyName',
            title: 'Company name',
            hideCurrentSortable: true
          },
          {
            field: 'name',
            title: 'Name',
            hideCurrentSortable: true
          },
          {
            field: 'startDate',
            title: 'Start date',
            template: (col) => <>{col.startDate ? moment(col.startDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'endDate',
            title: 'End date',
            template: (col) => <>{col.endDate ? moment(col.endDate).format('M/D/YYYY') : '-'}</>,
          }
        ]}
        apiProps={{
          api: supportContractApi,
          apiMethodName: 'useLazyGetSupportContractsQuery',
          apiParams: {
            searchText,
            hideExpired
          },
        }}
      />
    </CardWrapper>
  );
};

export default SupportContracts;
