import { book, Scrollbar } from '@monorepo/common';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { currencyFormatter } from '@monorepo/common/src/utils/currencyFormatter';
import { useStyles } from '../styles';
import { Permission } from '../../../constants/permission';
import AdminLink from '../../../components/AdminLink';
import { IOrderProductDetails } from '../../../services/order/interfaces';

interface IOrderProductDetailsProps {
  salesTax: number,
  items: IOrderProductDetails[],
}

const OrderProductDetails = ({ salesTax, items }: IOrderProductDetailsProps) => {
  const classes = useStyles();

  const extPriceSum = items.reduce((prev, current) => prev + current.extPrice, 0);

  return (
    <>
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Products
        </Typography>
      </Toolbar>
      <TableContainer component={Paper}>
        <Scrollbar>
          <Table className={classes.striped_table}>
            <TableHead className={classes.table_head}>
              <TableRow>
                <TableCell>Part #</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Support Contract</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Ext Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((detail: IOrderProductDetails) => (
                <TableRow
                  key={detail.orderDetailId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {detail.partNumber}
                  </TableCell>
                  <TableCell>
                    <b>{detail.productName}</b>
                    <br />
                    {detail.productDescription}
                  </TableCell>
                  <TableCell>
                    {detail.qty}
                  </TableCell>
                  <TableCell>
                    {detail.supportContractId && (
                      <AdminLink
                        url={book.admin.supportContract(detail.supportContractId)}
                        title={detail.supportContractId}
                        permissions={[Permission.SupportContracts]}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(detail.price)}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(detail.extPrice)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  SUB TOTAL
                  <br />
                  SALES TAX
                  <br />
                  TOTAL
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                  >
                    {currencyFormatter.format(extPriceSum)}
                    <br />
                    {currencyFormatter.format(salesTax)}
                    <br />
                    {currencyFormatter.format(extPriceSum + salesTax)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
};

export default OrderProductDetails;
