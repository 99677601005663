import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '@monorepo/common';
import Dashboard from '../Pages/Dashboard/Dashboard';
import OrderExports from '../Pages/OrderExports/OrderExports';
import ActiveContractMailingList from '../Pages/ActiveContractMailingList';
import SupportContracts from '../Pages/SupportContracts';
import SupportContract from '../Pages/SupportContract';
import Users from '../Pages/Users';
import User from '../Pages/User';
import License from '../Pages/License';
import CoreLogs from '../Pages/CoreLogs';
import Logs from '../Pages/Logs';
import Licenses from '../Pages/Licenses';
import Products from '../Pages/Products';
import Product from '../Pages/Product';
import AdminUsers from '../Pages/AdminUsers';
import AdminUser from '../Pages/AdminUser';
import Quotes from '../Pages/Quotes';
import Quote from '../Pages/Quote';
import RenewalQuotes from '../Pages/RenewalQuotes';
import RenewalQuote from '../Pages/RenewalQuote';
import Orders from '../Pages/Orders';
import Order from '../Pages/Order';
import UpForRenewal from '../Pages/UpForRenewal';
import MessageTemplates from '../Pages/MessageTemplates';
import MessageTemplate from '../Pages/MessageTemplate';
import RenewalEmails from '../Pages/UpForRenewal/components/RenewalEmails';
import LicensingTool from '../Pages/LicensingTool';
import OnlineAccounts from '../Pages/OnlineAccounts';
import OnlineAccount from '../Pages/OnlineAccount';
import Applications from '../Pages/Applications';
import Application from '../Pages/Application';
import Release from '../Pages/Release';
import SalesReps from '../Pages/SalesReps';
import SalesRep from '../Pages/SalesRep';
import MergeTexts from '../Pages/MergeTexts';
import MergeText from '../Pages/MergeText';

const PrivateRoutes = () => (
  <Routes>
    <Route
      path={book.admin.dashboard}
      element={<Dashboard />}
    />
    <Route
      path={book.admin.orderExports}
      element={<OrderExports />}
    />
    <Route
      path={book.admin.activeContractMailingList}
      element={<ActiveContractMailingList />}
    />
    <Route
      path={book.admin.supportContract()}
      element={<SupportContracts />}
    />
    <Route
      path={`${book.admin.supportContract()}/:id`}
      element={<SupportContract />}
    />
    <Route
      path={book.admin.adminUser()}
      element={<AdminUsers />}
    />
    <Route
      path={`${book.admin.adminUser()}/:id`}
      element={<AdminUser />}
    />
    <Route
      path={book.admin.user()}
      element={<Users />}
    />
    <Route
      path={`${book.admin.user()}/:id`}
      element={<User />}
    />
    <Route
      path={book.admin.license()}
      element={<Licenses />}
    />
    <Route
      path={`${book.admin.license()}/:serialNumber`}
      element={<License />}
    />
    <Route
      path={book.admin.coreLog}
      element={<CoreLogs />}
    />
    <Route
      path={book.admin.product()}
      element={<Products />}
    />
    <Route
      path={`${book.admin.product()}/:id`}
      element={<Product />}
    />
    <Route
      path={book.admin.log}
      element={<Logs />}
    />
    <Route
      path={book.admin.quote()}
      element={<Quotes />}
    />
    <Route
      path={`${book.admin.quote()}/:id`}
      element={<Quote />}
    />
    <Route
      path={book.admin.renewalQuote()}
      element={<RenewalQuotes />}
    />
    <Route
      path={`${book.admin.renewalQuote()}/:id`}
      element={<RenewalQuote />}
    />
    <Route
      path={book.admin.order()}
      element={<Orders />}
    />
    <Route
      path={`${book.admin.order()}/:id`}
      element={<Order />}
    />
    <Route
      path={book.admin.upForRenewal}
      element={<UpForRenewal />}
    />
    <Route
      path={book.admin.messageTemplate()}
      element={<MessageTemplates />}
    />
    <Route
      path={`${book.admin.messageTemplate()}/:id`}
      element={<MessageTemplate />}
    />
    <Route
      path={`${book.admin.messageTemplate()}/:id/:copyId`}
      element={<MessageTemplate />}
    />
    <Route
      path={book.admin.earlyRenewalEmails}
      element={<RenewalEmails type="early" />}
    />
    <Route
      path={book.admin.renewalEmails}
      element={<RenewalEmails type="regular" />}
    />
    <Route
      path={book.admin.expiredHostedEmails}
      element={<RenewalEmails type="expiredHosted" />}
    />
    <Route
      path={book.admin.expiredServerEmails}
      element={<RenewalEmails type="expiredServer" />}
    />
    <Route
      path={book.admin.licensingTool}
      element={<LicensingTool />}
    />
    <Route
      path={`${book.admin.onlineAccount()}/:region`}
      element={<OnlineAccounts />}
    />
    <Route
      path={`${book.admin.onlineAccount()}/:region/:id`}
      element={<OnlineAccount />}
    />
    <Route
      path={book.admin.application()}
      element={<Applications />}
    />
    <Route
      path={`${book.admin.application()}/:id`}
      element={<Application />}
    />
    <Route
      path={`${book.admin.release()}/:id`}
      element={<Release />}
    />
    <Route
      path={`${book.admin.salesRep()}`}
      element={<SalesReps />}
    />
    <Route
      path={`${book.admin.salesRep()}/:id`}
      element={<SalesRep />}
    />
    <Route
      path={`${book.admin.mergeText()}`}
      element={<MergeTexts />}
    />
    <Route
      path={`${book.admin.mergeText()}/:id`}
      element={<MergeText />}
    />
  </Routes>
);

export default memo(PrivateRoutes);
