import { useState, useMemo } from 'react';
import { book, Btn, CardWrapper, DataGrid } from '@monorepo/common';
import { SelectChangeEvent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { debounce } from 'debounce';
import moment from 'moment';
import TableToolBar from './components/TableToolBar';
import { quoteApi } from '../../services/quote';
import { IQuote } from '../../services/quote/interfaces';
import AdminLink from '../../components/AdminLink';

const Quotes = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [salesRep, setSalesRep] = useState(-1);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  const handleSalesRepIdChange = (event: SelectChangeEvent<number>) => {
    setSalesRep(+event.target.value);
  };

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value);
  };

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Quotes"
      title="Quotes"
      maxWidth="100%"
      childrenTitleRight={(
        <Btn link={`${book.admin.quote()}/new`}>
          Create
        </Btn>
      )}
    >
      <DataGrid<IQuote>
        keyExtractor={(col) => col.id.toString()}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            salesRepId={salesRep}
            startDate={startDate}
            endDate={endDate}
            handleSalesRepIdChange={handleSalesRepIdChange}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultSortDirection: 'desc',
          defaultPageSize: 25,
        }}
        columns={[
          {
            field: 'id',
            title: 'ID',
            template: (col) => (
              <AdminLink
                url={book.admin.quote(col.id)}
                title={col.id}
              />
            )
          },
          {
            field: 'salesRepName',
            title: 'Sales Rep'
          },
          {
            field: 'quoteTotal',
            title: 'Quote Total',
            isCurrency: true,
          },
          {
            field: 'quoteExpiredDateTime',
            title: 'Expired Date',
            template: (col) => <>{col.quoteExpiredDateTime ? moment(col.quoteExpiredDateTime).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'shipEmail',
            title: 'Ship Email',
          },
          {
            field: 'shipFullName',
            title: 'Ship Full Name',
          },
          {
            field: 'shipCompany',
            title: 'Ship Company',
          },
          {
            field: 'billEmail',
            title: 'Bill Email',
          },
          {
            field: 'billFullName',
            title: 'Bill Full Name',
          },
          {
            field: 'isOrdered',
            title: 'Is Ordered',
            template: (col) => <>{col.isOrdered ? <CheckIcon /> : ''}</>
          }
        ]}
        apiProps={{
          api: quoteApi,
          apiMethodName: 'useLazyGetQuotesQuery',
          apiParams: {
            searchText,
            salesRepId: salesRep === -1 ? undefined : salesRep,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined
          },
        }}
      />
    </CardWrapper>
  );
};

export default Quotes;
