import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Btn, Loader } from '@monorepo/common';
import { IApplicationProductMap, IApplicationProductMapItem } from '../../../services/application/interfaces';
import { useCreateApplicationUpgradeMapMutation, useDeleteApplicationUpgradeMapMutation, useUpdateApplicationUpgradeMapMutation } from '../../../services/application';
import { ISelectListItem } from '../../../interfaces';
import ConfirmModal from '../../../components/ConfirmModal';
import { showNotification } from '../../../features/ui/sliceNotification';
import { useStyles } from '../styles';

interface IApplicationUpgradesProps {
  applicationId: number,
  applicationName: string,
  items: IApplicationProductMapItem[],
  applications: ISelectListItem[],
  products: ISelectListItem[],
}

const ApplicationUpgrades = ({ applicationId, applicationName, items, applications, products }: IApplicationUpgradesProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [fields, setFields] = useState({} as IApplicationProductMap);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [createUpgradeMap, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateApplicationUpgradeMapMutation();
  const [updateUpgradeMap, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateApplicationUpgradeMapMutation();
  const [deleteUpgradeMap, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteApplicationUpgradeMapMutation();

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const addUpgrade = () => {
    setActiveRow(0);
    setFields({
      applicationId,
      productId: +products[0].value,
    });
  };

  const editUpgrade = (index: number) => {
    const upgrade = items[index];

    setActiveRow(index + 1);
    setFields({
      applicationId,
      productId: upgrade.productId,
    });
  };

  const saveUpgrade = (upgrade?: IApplicationProductMap) => {
    if (upgrade) {
      updateUpgradeMap({
        applicationId,
        productId: upgrade.productId,
        newApplicationId: fields.applicationId,
        newProductId: fields.productId,
      });
    } else {
      createUpgradeMap(fields);
    }
  };

  const deleteUpgradeConfirm = (productId: number) => {
    deleteUpgradeMap({
      applicationId,
      productId,
    });
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      setActiveRow(null);
      dispatch(showNotification({
        text: 'Upgrade created',
        type: 'success',
        show: true
      }));
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setActiveRow(null);
      dispatch(showNotification({
        text: 'Upgrade updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Upgrade deleted',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Upgrades
        </Typography>
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Btn click={addUpgrade}>
            Add upgrade
          </Btn>
        </Box>
      </Toolbar>
      {(isCreatingData || isUpdatingData || isDeletingData) && (
        <Loader
          position="absolute"
          size={70}
          blur
        />
      )}
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteUpgradeConfirm(idToDelete)}
      />
      <TableContainer component={Paper}>
        <Table className={classes.striped_table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              <TableCell>Application</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeRow === 0 && (
              <TableRow key={0}>
                <TableCell>
                  <Select
                    value={fields.applicationId}
                    size="small"
                    onChange={(event) => changeCellField('applicationId', +event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    {applications.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={fields.productId}
                    size="small"
                    onChange={(event) => changeCellField('productId', +event.target.value)}
                    sx={{ textAlign: 'left' }}
                  >
                    {products.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Tooltip title="Save">
                    <IconButton
                      onClick={() => saveUpgrade()}
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => setActiveRow(null)}
                      color="error"
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {items.map((col, index) => (
              <TableRow key={col.productId}>
                <TableCell>
                  {activeRow === index + 1 ? (
                    <Select
                      value={fields.applicationId}
                      size="small"
                      onChange={(event) => changeCellField('applicationId', +event.target.value)}
                      sx={{ textAlign: 'left' }}
                    >
                      {applications.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    applicationName
                  )}
                </TableCell>
                <TableCell>
                  {activeRow === index + 1 ? (
                    <Select
                      value={fields.productId}
                      size="small"
                      onChange={(event) => changeCellField('productId', +event.target.value)}
                      sx={{ textAlign: 'left' }}
                    >
                      {products.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    products.find((p) => p.value === col.productId.toString())?.text
                  )}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {activeRow === index + 1 ? (
                    <>
                      <Tooltip title="Save">
                        <IconButton
                          onClick={() => saveUpgrade(col)}
                          color="primary"
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton
                          onClick={() => setActiveRow(null)}
                          color="error"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => editUpgrade(index)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => setIdToDelete(col.productId)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ApplicationUpgrades;
