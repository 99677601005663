import React, { memo } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';

import { IChildrenProps } from '@monorepo/common/src/interfaces';

import { useAppSelector } from '../../../app/hooks';

import { useStyles } from '../styles';

interface LeftSideBarProps extends IChildrenProps{}

const LeftSideBar = ({ children }:LeftSideBarProps) => {
  const classes = useStyles();
  const { isMobileMenu } = useAppSelector(({ ui }) => ({
    isMobileMenu: ui.isMobileMenu
  }));

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.active]: isMobileMenu
      })}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true
      }}
    >
      {/* <Toolbar /> */}
      <div className={classes.drawerContainer}>
        {children}
      </div>
    </Drawer>

  );
};

export default memo(LeftSideBar);
