import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inline_btn: {
    display: 'inline-block',
    marginLeft: 8
  },
  field_wrapper: {
    marginBottom: 0
  },
}));
