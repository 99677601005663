import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  table_head: {
    background: 'rgb(243, 244, 246)',
  },
  striped_table: {
    '& tr:nth-child(even) {background-color: #f2f2f2;}': {
      backgroundColor: '#F2F2F2'
    }
  },
}));
