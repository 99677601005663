import moment from 'moment';
import { book, CardWrapper, DataGrid } from '@monorepo/common';
import { reportApi } from '../../services/report';
import { IActiveContractMailing } from '../../services/report/interfaces';
import { Permission } from '../../constants/permission';
import AdminLink from '../../components/AdminLink';

const ActiveContractMailingList = () => (
  <CardWrapper
    titleCenter
    centerText
    helmet="Active Contract Mailing List"
    title="Active Contract Mailing List"
    maxWidth="100%"
  >
    <DataGrid<IActiveContractMailing>
      keyExtractor={(col) => `${col.supportContractId}-${col.userId}`}
      sortable
      styleProps={{
        isSelectedEven: true
      }}
      defaultGridProps={{
        defaultSortBy: 'endDate',
        defaultSortDirection: 'desc',
        defaultPageSize: 25
      }}
      columns={[
        {
          field: 'companyName',
          title: 'Company name',
          hideCurrentSortable: true,
        },
        {
          field: 'firstName',
          title: 'First name',
          hideCurrentSortable: true,
        },
        {
          field: 'lastName',
          title: 'Last name',
          hideCurrentSortable: true,
        },
        {
          field: 'email',
          title: 'Email',
          hideCurrentSortable: true,
        },
        {
          field: 'phone',
          title: 'Phone',
          hideCurrentSortable: true,
        },
        {
          field: 'userId',
          title: 'User',
          hideCurrentSortable: true,
          template: (col) => (
            <AdminLink
              url={book.admin.user(col.userId)}
              title={col.userId}
              permissions={[Permission.Users]}
            />
          )
        },
        {
          field: 'supportContractId',
          title: 'Support contract',
          hideCurrentSortable: true,
          template: (col) => (
            <AdminLink
              url={book.admin.supportContract(col.supportContractId)}
              title={col.supportContractId}
              permissions={[Permission.SupportContracts]}
            />
          )
        },
        {
          field: 'startDate',
          title: 'Start date',
          template: (col) => <>{col.startDate ? moment(col.startDate).format('M/D/YYYY') : '-'}</>,
        },
        {
          field: 'endDate',
          title: 'End date',
          template: (col) => <>{col.endDate ? moment(col.endDate).format('M/D/YYYY') : '-'}</>,
        }
      ]}
      apiProps={{
        api: reportApi,
        apiMethodName: 'useLazyGetActiveContractMailingListQuery',
      }}
    />
  </CardWrapper>
);

export default ActiveContractMailingList;
