import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Btn, CardWrapper } from '@monorepo/common';
import { Box, Grid, Switch, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useGetSupportContractQuery, useUpdateSupportContractMutation } from '../../services/supportContract';
import { showNotification } from '../../features/ui/sliceNotification';
import GridField from '../../components/GridField';
import SupportContractLicenses from './components/SupportContractLicenses';
import SupportContractUsers from './components/SupportContractUsers';
import { useStyles } from './styles';

const SupportContract = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isLoading: isLoadingData, refetch } = useGetSupportContractQuery(id || '', { skip: !id });

  const [orderId, setOrderId] = useState('');
  const [hostingAccountId, setHostingAccountId] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [putSupportContract, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateSupportContractMutation();
  const pageTitle = data ? `Support Contract #${data && data.supportContractId}` : 'Support Contract not found';

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const save = () => {
    putSupportContract({
      id: +id!,
      orderId: orderId ? +orderId : null,
      hostingAccountId: hostingAccountId ? +hostingAccountId : null,
      isActive,
      startDate: startDate ? moment(startDate)?.format('YYYY-MM-DD') : null,
      endDate: endDate ? moment(endDate)?.format('YYYY-MM-DD') : null
    });
  };

  useEffect(() => {
    if (data) {
      setOrderId(data.orderId?.toString() || '');
      setHostingAccountId(data.hostingAccountId?.toString() || '');
      setIsActive(data.isActive);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'Support Contract updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Support Contract"
      title={pageTitle}
      isLoading={isLoadingData || isUpdatingData || isLoading}
      childrenTitleRight={data && <Btn click={save}>Save</Btn>}
    >
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Active"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={isActive}
                onChange={handleIsActiveChange}
                name="isActive"
              />
            </GridField>
            <GridField
              label="Application Name"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {data && data.applicationName}
            </GridField>
            <GridField
              label="Order ID"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                inputProps={{ inputMode: 'numeric' }}
                value={orderId}
                onChange={(event) => {
                  setOrderId(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Hosting Account ID"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                inputProps={{ inputMode: 'numeric' }}
                value={hostingAccountId}
                onChange={(event) => {
                  setHostingAccountId(event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Date Period"
              labelmd={3}
              fieldmd={9}
            >
              <Box className={classes.date_range_picker_wrapper}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={startDate}
                    onChange={(event) => setStartDate(event)}
                    shouldDisableDate={(d) => !!(endDate && d! > endDate!)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Start date'
                        }}
                      />
                    )}
                  />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <DatePicker
                    value={endDate}
                    onChange={(event) => setEndDate(event)}
                    shouldDisableDate={(d) => !!(startDate && moment(d) < moment(startDate).startOf('day'))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'End date'
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </GridField>
          </Grid>
          <SupportContractUsers
            items={data.supportContractUsers}
            setIsLoading={setIsLoading}
          />
          <SupportContractLicenses
            items={data.supportContractLicenses}
          />
        </Box>
      )}
    </CardWrapper>
  );
};

export default SupportContract;
