import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IProductListRequest, IProduct, IProductItem, IProductDetails, IProductRequest } from './interfaces';

export const productApi = createApi({
  reducerPath: 'productApi',
  tagTypes: ['product'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getProducts: build.query<IListResp<IProductItem>, IProductListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        isActive,
        includeDetails
      }) => ({
        url: endpoints.products,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          isActive,
          includeDetails
        }
      }),
      providesTags: () => ['product']
    }),
    getProduct: build.query<IProductDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.product}/${id}`,
      }),
      providesTags: () => ['product']
    }),
    createProduct: build.mutation<IProduct, IProductRequest>({
      query: ({
        name,
        price,
        partNumber,
        description,
        isActive
      }) => ({
        url: endpoints.productCreate,
        method: 'POST',
        body: {
          name,
          price,
          partNumber,
          description,
          isActive
        }
      }),
      invalidatesTags: ['product']
    }),
    updateProduct: build.mutation<IProduct, IProductRequest>({
      query: ({
        id,
        name,
        price,
        partNumber,
        description,
        isActive,
        includeDetails
      }) => ({
        url: `${endpoints.productEdit}/${id}`,
        method: 'PATCH',
        body: {
          name,
          price,
          partNumber,
          description,
          isActive
        },
        params: {
          includeDetails
        }
      }),
      invalidatesTags: ['product']
    }),
    deleteProduct: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.productDelete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['product']
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductQuery,
  useUpdateProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation
} = productApi;
