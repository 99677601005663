import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  form_wrapper: {
    maxWidth: '27rem',
    margin: 'auto'
  },
  googleBtn: {
    marginTop: '1rem',
    width: '100%',
    justifyContent: 'center'
  }
}));
