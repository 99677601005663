import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { IChildrenProps } from '@monorepo/common/src/interfaces';
import { useStyles } from './styles';
import { Permission } from '../../constants/permission';
import { useAppSelector } from '../../app/hooks';
import { searchForCommonItems } from '../../utils/searchForCommonItems';

interface IAdminLinkProps extends IChildrenProps {
  url: string,
  title: string | number,
  permissions?: Permission[],
}

const AdminLink = ({
  url,
  title,
  permissions = [],
}: IAdminLinkProps) => {
  const classes = useStyles();

  const { userRoles, isSuperUser } = useAppSelector(({ profile }) => ({
    userRoles: profile.UserRoles,
    isSuperUser: profile.IsSuper
  }));

  const hasPermission = permissions ? isSuperUser || searchForCommonItems(userRoles, permissions.map(String)) : true;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <Box sx={{ ml: 1 }}>
        {hasPermission ? (
          <Link
            className={classes.link_wrapper}
            to={url}
          >
            <Typography
              color="primary"
              variant="subtitle2"
              className={classes.link}
            >
              {title}
            </Typography>
          </Link>
        ) : (
          <>
            {title}
          </>
        )}
      </Box>
    </Box>
  );
};

export default memo(AdminLink);
