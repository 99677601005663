import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListRequest, IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { ILog, ICreateTaskReq } from './interfaces';

export const logApi = createApi({
  reducerPath: 'logApi',
  tagTypes: ['log'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getLogs: build.query<IListResp<ILog>, IListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy
      }) => ({
        url: endpoints.logs,
        params: { pageSize, page, searchText, sortingOrder, sortBy }
      }),
    }),
    postCeateTask: build.mutation<string, ICreateTaskReq>({
      query: ({ logId }) => ({
        url: endpoints.logCreateTask,
        params: { logId },
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLazyGetLogsQuery,
  usePostCeateTaskMutation,
} = logApi;
