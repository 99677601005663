import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import ui from '../features/ui/sliceMobileMenu';
import notification from '../features/ui/sliceNotification';
import auth from '../features/sliceAuth';
import profile from '../features/sliceUserProfile';
import globalError from '../features/sliceGlobalError';
import { loginApi } from '../services/login';
import { middlewareHandler } from './middlewareList';
import { reportApi } from '../services/report';
import { supportContractApi } from '../services/supportContract';
import { userApi } from '../services/user';
import { licenseApi } from '../services/license';
import { coreLogApi } from '../services/coreLog';
import { logApi } from '../services/log';
import { productApi } from '../services/product';
import { adminUserApi } from '../services/adminUser';
import { quoteApi } from '../services/quote';
import { orderApi } from '../services/order';
import { renewalQuoteApi } from '../services/renewalQuote';
import { messageTemplateApi } from '../services/messageTemplate';
import { onlineAccountApi } from '../services/onlineAccount';
import { applicationApi } from '../services/application';
import { releaseApi } from '../services/release';
import { salesRepApi } from '../services/salesRep';
import { mergeTextApi } from '../services/mergeText';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
  reducer: {
    ui,
    auth,
    profile,
    globalError,
    notification,
    [loginApi.reducerPath]: loginApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [supportContractApi.reducerPath]: supportContractApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [licenseApi.reducerPath]: licenseApi.reducer,
    [coreLogApi.reducerPath]: coreLogApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [adminUserApi.reducerPath]: adminUserApi.reducer,
    [quoteApi.reducerPath]: quoteApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [renewalQuoteApi.reducerPath]: renewalQuoteApi.reducer,
    [messageTemplateApi.reducerPath]: messageTemplateApi.reducer,
    [onlineAccountApi.reducerPath]: onlineAccountApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [releaseApi.reducerPath]: releaseApi.reducer,
    [salesRepApi.reducerPath]: salesRepApi.reducer,
    [mergeTextApi.reducerPath]: mergeTextApi.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
