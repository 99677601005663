import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cookies from 'js-cookie';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormField, Btn, book, cookieNames, CardWrapper } from '@monorepo/common';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { schema } from './schema';
import { loginApi } from '../../services/login';
import { showNotification } from '../../features/ui/sliceNotification';
import { changeStatusAuth } from '../../features/sliceAuth';
import { useAppSelector } from '../../app/hooks';

import { useStyles } from './styles';

interface IConfirmEmailFormProps {
  Email: string,
  Password: string,
}

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthUser } = useAppSelector(({ auth }) => ({
    isAuthUser: auth.isAuth
  }));
  const [post, { data: result, isLoading }] = loginApi.usePostMutation();
  const [googlePost, { data: googleResult, isLoading: googleIsLoading }] = loginApi.useGooglePostMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IConfirmEmailFormProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IConfirmEmailFormProps) => {
    post({ ...data }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      }
    });
  };

  const onGoogleSuccess = (req: any) => {
    googlePost({ JwtFromGoogle: req.credential }).then((res: any) => {
      console.log('-', res);
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.error || 'Error'
        }));
      }
    });
  };

  const onGoogleFail = (errorResult: any) => {
    console.log(`google login error: ${errorResult.error} ${errorResult.details}`);
  };

  useEffect(() => {
    if (result) {
      cookies.set(cookieNames.access_token, result.access_token);
      cookies.set(cookieNames.refresh_token, result.refresh_token);
      dispatch(changeStatusAuth(true));
    }
  }, [result]);

  useEffect(() => {
    if (googleResult) {
      cookies.set(cookieNames.access_token, googleResult.access_token);
      cookies.set(cookieNames.refresh_token, googleResult.refresh_token);
      dispatch(changeStatusAuth(true));
    }
  }, [googleResult]);

  useEffect(() => {
    if (isAuthUser) {
      navigate(book.admin.dashboard);
    }
  }, [isAuthUser]);

  return (
    <CardWrapper
      centerText
      helmet="Log In"
      title="Log In"
      classnameForWrapper={classes.form_wrapper}
      isLoading={isLoading || googleIsLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <FormField
            extraProps={{ ...register('Email') }}
            errors={errors}
            label="Login *"
          />

          <FormField
            extraProps={{ ...register('Password') }}
            errors={errors}
            label="Password *"
            type="password"
          />

          <Btn
            fullWidth
            type="submit"
          >
            Log In
          </Btn>
        </>

        <GoogleLogin
          text="signin"
          type="standard"
          onSuccess={onGoogleSuccess}
          onFailure={onGoogleFail}
        />
      </form>
    </CardWrapper>
  );
};

export default memo(Login);
