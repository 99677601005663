import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { decodeToken } from 'react-jwt';

import { book, cookieNames, Menu } from '@monorepo/common';

import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import MenuInternalLinks from './MenuInternalLinks';

import Layout from '../containers/Layout';
import Header from '../components/Header';
import { useAppSelector } from '../app/hooks';
import { fillProfile } from '../features/sliceUserProfile';

const useRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const { isAuth } = useAppSelector(({ auth }) => ({
    isAuth: auth.isAuth,
  }));

  useEffect(() => {
    if (isAuth && location.pathname === book.admin.account.login) {
      navigate(book.admin.dashboard);
    }
  }, [isAuth, location]);

  useEffect(() => {
    if (!cookies.get(cookieNames.access_token)) {
      setIsRedirect(true);
      setTimeout(() => {
        setIsRedirect(false);
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    if (isRedirect) {
      navigate(book.admin.account.login);
    }
  }, [isRedirect]);

  useEffect(() => {
    const token: string | undefined = cookies.get(cookieNames.access_token);
    if (token) {
      const {
        AdminId,
        UserName,
        IsSuper,
        UserRoles,
        UserAvatar
      }: any = decodeToken(token);
      dispatch(fillProfile({
        AdminId,
        UserName,
        IsSuper: IsSuper ? JSON.parse(IsSuper.toLowerCase()) : false,
        UserRoles: UserRoles ? JSON.parse(UserRoles).map(String) : [],
        UserAvatar,
      }));
    }
  }, [location.pathname]);

  if (isAuth !== null) {
    if (isAuth) {
      return (
        <Layout
          isAuth
          headerComponent={<Header />}
          menuComponent={(
            <Menu>
              <MenuInternalLinks />
            </Menu>
)}
          hideLeftSideForThisPages={[book.admin.account.login]}
          hideHeaderSideForThisPages={[book.admin.account.login]}
        >
          {/* here are all private the routes */}
          <PrivateRoutes />
        </Layout>
      );
    }

    return (
      <Layout>
        {/* here are all public the routes */}
        <PublicRoutes />
      </Layout>
    );
  }
  return null;
};

export default useRoutes;
