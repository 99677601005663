import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { TableSearch } from '@monorepo/common';
import { ISelectListItem } from '../../../interfaces';
import { useGetLicensesBaseQuery } from '../../../services/license';

interface ITableToolBarProps {
  onSearch: (v: string) => void
  applicationId: number,
  handleApplicationIdChange: (event: SelectChangeEvent<number>) => void
}

const TableToolBar = ({ onSearch, applicationId, handleApplicationIdChange }: ITableToolBarProps) => {
  const { data } = useGetLicensesBaseQuery('');

  return (
    <Grid
      container
      spacing={3}
      sx={{ p: 3 }}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TableSearch change={onSearch} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <FormControl fullWidth>
          <InputLabel id="application-select-label">Application</InputLabel>
          <Select
            labelId="application-select-label"
            value={applicationId}
            label="Application"
            onChange={handleApplicationIdChange}
            sx={{ textAlign: 'left' }}
          >
            <MenuItem
              key="-1"
              value="-1"
            >
              All applications
            </MenuItem>
            {data && data.applications.map((item: ISelectListItem) => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TableToolBar;
