import { useState, useMemo } from 'react';
import { book, CardWrapper, DataGrid } from '@monorepo/common';
import { SelectChangeEvent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { debounce } from 'debounce';
import { licenseApi } from '../../services/license';
import { ILicense } from '../../services/license/interfaces';
import TableToolBar from './components/TableToolBar';
import { Permission } from '../../constants/permission';
import AdminLink from '../../components/AdminLink';

const Licenses = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [application, setApplication] = useState(-1);

  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  const handleApplicationIdChange = (event: SelectChangeEvent<number>) => {
    setApplication(+event.target.value);
  };

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Licenses"
      title="Licenses"
      maxWidth="100%"
    >
      <DataGrid<ILicense>
        keyExtractor={(col) => col.serialNumber}
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            applicationId={application}
            handleApplicationIdChange={handleApplicationIdChange}
          />
        )}
        defaultGridProps={{
          defaultPageSize: 25
        }}
        columns={[
          {
            field: 'serialNumber',
            title: 'Serial Number',
            template: (col) => (
              <AdminLink
                url={book.admin.license(col.serialNumber)}
                title={col.serialNumber}
              />
            )
          },
          {
            field: 'productName',
            title: 'Product'
          },
          {
            field: 'firstName',
            title: 'First name',
          },
          {
            field: 'lastName',
            title: 'Last name'
          },
          {
            field: 'companyName',
            title: 'Company name',
          },
          {
            field: 'orderId',
            title: 'Order',
            template: (col) => (
              <AdminLink
                url={book.admin.order(col.orderId)}
                title={col.orderId}
                permissions={[Permission.Orders]}
              />
            ),
          },
          {
            field: 'supportContractId',
            title: 'Contract',
            template: (col) => (
              <AdminLink
                url={book.admin.supportContract(col.supportContractId)}
                title={col.supportContractId}
                permissions={[Permission.SupportContracts]}
              />
            )
          },
          {
            field: 'isActive',
            title: 'Active',
            align: 'center',
            template: (col) => (
              <>
                {col.isActive ? <CheckIcon /> : ''}
              </>
            )
          }
        ]}
        apiProps={{
          api: licenseApi,
          apiMethodName: 'useLazyGetLicensesQuery',
          apiParams: {
            searchText,
            applicationId: application === -1 ? undefined : application
          },
        }}
      />
    </CardWrapper>
  );
};

export default Licenses;
