import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { toggleMobileMenu } from '../../features/ui/sliceMobileMenu';
import { useAppSelector } from '../../app/hooks';

// interface BurgerMenuProps {}
const BurgerMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobileMenu } = useAppSelector(({ ui }) => ({
    isMobileMenu: ui.isMobileMenu
  }));
  const toggle = () => {
    dispatch(toggleMobileMenu(!isMobileMenu));
  };

  return (
    <button
      type="button"
      className={clsx(classes.menu, {
        [classes.active]: isMobileMenu
      })}
      onClick={toggle}
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export default BurgerMenu;
