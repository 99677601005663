import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Paper, Toolbar, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Btn, Loader } from '@monorepo/common';
import { useStyles } from '../../Order/styles';
import { useCreateOnlineAccountSenderMutation, useUpdateOnlineAccountSenderMutation, useDeleteOnlineAccountSenderMutation } from '../../../services/onlineAccount';
import { showNotification } from '../../../features/ui/sliceNotification';
import ConfirmModal from '../../../components/ConfirmModal';

interface IOnlineVerifiedSendersProps {
  region: string,
  id: number,
  items: string[],
}

const OnlineAccountVerifiedSenders = ({ region, id, items }: IOnlineVerifiedSendersProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editableRow, setEditableRow] = useState<number>(-1);
  const [senderToDelete, setSenderToDelete] = useState<string>('');
  const [verifiedSender, setVerifiedSender] = useState<string>('');

  const [createSender, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateOnlineAccountSenderMutation();
  const [updateSender, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateOnlineAccountSenderMutation();
  const [deleteSender, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteOnlineAccountSenderMutation();

  const addEmptyRow = () => {
    setEditableRow(0);
    setVerifiedSender('');
  };

  const addSender = () => {
    if (verifiedSender === '') {
      return;
    }

    createSender({
      region,
      accountId: id,
      verifiedSender,
    });
  };

  const saveSender = () => {
    if (verifiedSender === '') {
      return;
    }

    if (verifiedSender === items[editableRow - 1]) {
      setEditableRow(-1);
      return;
    }

    updateSender({
      region,
      accountId: id,
      sender: items[editableRow - 1],
      verifiedSender,
    });
  };

  const editSender = (index: number) => {
    setVerifiedSender(items[index]);
    setEditableRow(index + 1);
  };

  const deleteSenderConfirm = () => {
    deleteSender({
      region,
      accountId: id,
      sender: senderToDelete
    });
    setSenderToDelete('');
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Verified Sender created',
        type: 'success',
        show: true
      }));
      setEditableRow(-1);
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Verified Sender updated',
        type: 'success',
        show: true
      }));
      setEditableRow(-1);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Verified Sender removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <>
      {(isCreatingData || isUpdatingData || isDeletingData) && (
        <Loader
          position="absolute"
          size={70}
          blur
        />
      )}
      <ConfirmModal
        showModal={!!senderToDelete}
        setShowModal={(value: boolean) => setSenderToDelete(value ? senderToDelete : '')}
        onConfirm={() => deleteSenderConfirm()}
      />
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Verified Senders
        </Typography>
        <Btn
          className={classes.btn_add}
          click={addEmptyRow}
        >
          Add Verified Sender
        </Btn>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table className={classes.striped_table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              <TableCell>Verified Domains / Emails</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editableRow === 0 && (
              <TableRow key={0}>
                <TableCell>
                  <TextField
                    fullWidth
                    value={verifiedSender}
                    size="small"
                    onChange={(val) => setVerifiedSender(val.target.value)}
                  />
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Tooltip title="Save">
                    <IconButton
                      onClick={() => addSender()}
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => setEditableRow(-1)}
                      color="error"
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {items.map((col, index) => (
              <TableRow key={col}>
                <TableCell>
                  {editableRow === index + 1 ? (
                    <TextField
                      fullWidth
                      value={verifiedSender || ''}
                      size="small"
                      onChange={(val) => setVerifiedSender(val.target.value)}
                    />
                  ) : (
                    col
                  )}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {editableRow === index + 1 ? (
                    <>
                      <Tooltip title="Save">
                        <IconButton
                          onClick={() => saveSender()}
                          color="primary"
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton
                          onClick={() => setEditableRow(-1)}
                          color="error"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => editSender(index)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => setSenderToDelete(col)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OnlineAccountVerifiedSenders;
