import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, DataGrid } from '@monorepo/common';
import { Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { releaseApi, useDeleteReleaseMutation } from '../../../services/release';
import { IRelease } from '../../../services/release/interfaces';
import AdminLink from '../../../components/AdminLink';
import ConfirmModal from '../../../components/ConfirmModal';
import { showNotification } from '../../../features/ui/sliceNotification';

interface IApplicationReleasesProps {
  applicationId: number,
}

const ApplicationReleases = ({ applicationId }: IApplicationReleasesProps) => {
  const dispatch = useDispatch();

  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [deleteRelease, { isSuccess: isDeleteSuccess }] = useDeleteReleaseMutation();

  const deleteReleaseConfirm = (id: number) => {
    deleteRelease(id);
    setIdToDelete(0);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Release removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Releases
        </Typography>
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Btn link={`${book.admin.release()}/new?application=${applicationId}`}>
            Add release
          </Btn>
        </Box>
      </Toolbar>
      <ConfirmModal
        showModal={idToDelete > 0}
        setShowModal={(value: boolean) => setIdToDelete(value ? idToDelete : 0)}
        onConfirm={() => deleteReleaseConfirm(idToDelete)}
      />
      <DataGrid<IRelease>
        keyExtractor={(col) => col.id.toString()}
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultSortDirection: 'desc',
          defaultPageSize: 10
        }}
        columns={[
          {
            field: 'id',
            title: 'ID',
          },
          {
            field: 'version',
            title: 'Version',
            template: (col) => (
              <AdminLink
                url={book.admin.release(col.id)}
                title={col.version}
              />
            ),
          },
          {
            field: 'fileName',
            title: 'File Name',
          },
          {
            field: 'password',
            title: 'Password',
          },
          {
            field: 'releaseDate',
            title: 'Release Date',
            template: (col) => <>{col.releaseDate ? moment(col.releaseDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'isActive',
            title: 'Active',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (
              <>
                {col.isActive ? <CheckIcon /> : ''}
              </>
            )
          },
          {
            field: 'id',
            title: 'empty',
            align: 'center',
            template: (col) => (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => setIdToDelete(col.id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ),
          },
        ]}
        apiProps={{
          api: releaseApi,
          apiMethodName: 'useLazyGetReleasesQuery',
          apiParams: {
            applicationId
          }
        }}
      />
    </>
  );
};

export default ApplicationReleases;
