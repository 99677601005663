import React, { memo } from 'react';
import { ListSubheader } from '@material-ui/core';
import { useStyles } from '../styles';

interface ItemHeaderProps {
  title: string
}
const MenuItemHeader = ({ title }:ItemHeaderProps) => {
  const classes = useStyles();
  return (
    <ListSubheader
      disableGutters
      disableSticky
      className={classes.list_subheader}
    >
      {title}
    </ListSubheader>
  );
};

export default memo(MenuItemHeader);
