import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { ICoreLog, ICoreLogListRequest, ICreateTaskReq } from './interfaces';

export const coreLogApi = createApi({
  reducerPath: 'coreLogApi',
  tagTypes: ['coreLog'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getCoreLogs: build.query<IListResp<ICoreLog>, ICoreLogListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        site
      }) => ({
        url: endpoints.coreLogs,
        params: { pageSize, page, searchText, sortingOrder, sortBy, site }
      }),
    }),
    postCeateTask: build.mutation<string, ICreateTaskReq>({
      query: ({ logId }) => ({
        url: endpoints.coreLogCreateTask,
        params: { logId },
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLazyGetCoreLogsQuery,
  usePostCeateTaskMutation,
} = coreLogApi;
