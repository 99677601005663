import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListRequest, IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { ISalesRep, ISalesRepDetails } from './interfaces';

export const salesRepApi = createApi({
  reducerPath: 'salesRepApi',
  tagTypes: ['salesRep'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getSalesReps: build.query<IListResp<ISalesRep>, IListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
      }) => ({
        url: endpoints.salesReps,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
        }
      }),
      providesTags: () => ['salesRep']
    }),
    getSalesRep: build.query<ISalesRepDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.salesRep}/${id}`,
      }),
      providesTags: () => ['salesRep']
    }),
    createSalesRep: build.mutation<number, ISalesRepDetails>({
      query: ({
        adminUserId,
        name,
        email,
        phoneNumber,
        initials,
      }) => ({
        url: endpoints.salesRepCreate,
        method: 'POST',
        body: {
          adminUserId,
          name,
          email,
          phoneNumber,
          initials,
        }
      }),
      invalidatesTags: ['salesRep']
    }),
    updateSalesRep: build.mutation<ISalesRepDetails, ISalesRepDetails>({
      query: ({
        id,
        adminUserId,
        name,
        email,
        phoneNumber,
        initials,
      }) => ({
        url: `${endpoints.salesRepEdit}/${id}`,
        method: 'PATCH',
        body: {
          adminUserId,
          name,
          email,
          phoneNumber,
          initials,
        }
      }),
      invalidatesTags: ['salesRep']
    }),
    deleteSalesRep: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.salesRepDelete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['salesRep']
    }),
  }),
});

export const {
  useGetSalesRepQuery,
  useLazyGetSalesRepsQuery,
  useCreateSalesRepMutation,
  useUpdateSalesRepMutation,
  useDeleteSalesRepMutation,
} = salesRepApi;
