import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Btn, CardWrapper } from '@monorepo/common';
import { Box, Grid, TextField } from '@mui/material';
import GridField from '../../components/GridField';
import { showNotification } from '../../features/ui/sliceNotification';
import { useGetMergeTextQuery, useUpdateMergeTextMutation } from '../../services/mergeText';
import { IMergeTextDetails } from '../../services/mergeText/interfaces';

const MergeText = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [fields, setFields] = useState<IMergeTextDetails | null>(null);

  const { data, isLoading: isLoadingData, refetch } = useGetMergeTextQuery(id!);
  const [updateMergeText, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateMergeTextMutation();

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields!,
      [name]: value
    });
  };

  const save = () => {
    updateMergeText(fields!);
  };

  useEffect(() => {
    if (data) {
      setFields(data);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'Merge Text updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Merge Text"
      title={data ? `Merge Text #${id}` : 'Merge Text not found'}
      maxWidth="100%"
      isLoading={isLoadingData || isUpdatingData}
      childrenTitleRight={fields
        && (<Btn click={save}>Save</Btn>)}
    >
      {fields && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Type"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              {fields.type}
            </GridField>
            <GridField
              label="Label"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.label || ''}
                fullWidth
                onChange={(event) => {
                  changeCellField('label', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Text"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.text || ''}
                fullWidth
                multiline
                onChange={(event) => {
                  changeCellField('text', event.target.value);
                }}
                size="small"
              />
            </GridField>
          </Grid>
        </Box>
      )}
    </CardWrapper>
  );
};

export default MergeText;
