import { useEffect, useState } from 'react';
import { baseUrls, Btn, CardWrapper } from '@monorepo/common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Box, TextField } from '@mui/material';
import { endpoints } from '../../constants/endpoints';
import { downloadFile } from './fileDownload';
import { useStyles } from './styles';

const OrderExports = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const canNotExport = () => startDate == null || endDate == null;

  useEffect(() => {
  }, [isLoading]);

  const getDownloadFile = (urlPart: string) => {
    downloadFile(`${baseUrls.adminApi}${urlPart}?startDate=${moment(startDate)?.format('YYYY-MM-DD')}&endDate=${moment(endDate)?.format('YYYY-MM-DD')}`, setIsLoading);
  };

  const getSalesOrdersReportFile = () => {
    getDownloadFile(endpoints.exportSalesOrders);
  };

  const getSalesPaymentsReportFile = () => {
    getDownloadFile(endpoints.exportSalesPayments);
  };

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Order Exports"
      title="Order Exports"
      isLoading={isLoading}
    >
      <Box className={classes.date_range_picker_wrapper}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={startDate}
            onChange={(event) => setStartDate(event)}
            disableFuture
            shouldDisableDate={(d) => !!(endDate && d! > endDate!)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'Start date'
                }}
              />
            )}
          />
          <Box sx={{ mx: 2 }}> to </Box>
          <DatePicker
            value={endDate}
            onChange={(event) => setEndDate(event)}
            disableFuture
            shouldDisableDate={(d) => !!(startDate && moment(d) < moment(startDate).startOf('day'))}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'End date'
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Btn
        className={classes.btn}
        target="_blank"
        external
        disabled={canNotExport()}
        click={getSalesOrdersReportFile}
      >
        Export Orders
      </Btn>
      <Btn
        className={classes.btn}
        target="_blank"
        external
        disabled={canNotExport()}
        click={getSalesPaymentsReportFile}
      >
        Export Payments
      </Btn>
    </CardWrapper>
  );
};

export default OrderExports;
