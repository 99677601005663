import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Typography } from '@mui/material';
import { book, CardWrapper, DataGrid } from '@monorepo/common';
import CheckIcon from '@mui/icons-material/Check';
import { debounce } from 'debounce';
import TableToolBar from './components/TableToolBar';
import { onlineAccountApi } from '../../services/onlineAccount';
import { IOnlineAccount } from '../../services/onlineAccount/interfaces';
import AdminLink from '../../components/AdminLink';
import { useStyles } from './styles';
import { Permission } from '../../constants/permission';

const OnlineAccounts = () => {
  const classes = useStyles();
  const { region } = useParams();

  const [searchText, setSearchText] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [isTrial, setIsTrial] = useState<boolean | null>(null);
  const onSearch = useMemo(() => debounce((value: string) => {
    setSearchText(value);
  }, 800), []);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet={`Online Accounts ${region}`}
      title={`Online Accounts ${region}`}
      maxWidth="100%"
    >
      <DataGrid<IOnlineAccount>
        keyExtractor={(col) => col.id.toString()}
        sortable
        styleProps={{
          isSelectedEven: true
        }}
        searchableProps={{
          showSearchField: false,
        }}
        toolBarComponent={(
          <TableToolBar
            onSearch={onSearch}
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={setStartDate}
            handleEndDateChange={setEndDate}
            setIsActive={setIsActive}
            setIsTrial={setIsTrial}
          />
        )}
        defaultGridProps={{
          defaultSortBy: 'id',
          defaultSortDirection: 'desc',
          defaultPageSize: 25
        }}
        columns={[
          {
            rowId: (col) => col.id,
            field: 'id',
            title: 'ID',
            template: (col) => (
              <AdminLink
                url={book.admin.onlineAccount(region!, col.id)}
                title={col.id}
              />
            ),
          },
          {
            field: 'name',
            title: 'Name',
          },
          {
            field: 'adminEmail',
            title: 'Admin Email',
          },
          {
            field: 'isTrial',
            title: 'Trial',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (<>{col.isTrial ? <CheckIcon /> : ''}</>)
          },
          {
            field: 'isActive',
            title: 'Active',
            align: 'center',
            hideCurrentSortable: true,
            template: (col) => (<>{col.isActive ? <CheckIcon /> : ''}</>)
          },
          {
            field: 'startDate',
            title: 'Start Date',
            template: (col) => <>{col.startDate ? moment(col.startDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'endDate',
            title: 'End Date',
            template: (col) => <>{col.endDate ? moment(col.endDate).format('M/D/YYYY') : '-'}</>,
          },
          {
            field: 'supportContractId',
            title: 'Support Contract',
            template: (col) => (
              <>
                {col.supportContractId && (
                  <AdminLink
                    url={book.admin.supportContract(col.supportContractId!)}
                    title={col.supportContractId!}
                    permissions={[Permission.SupportContracts]}
                  />
                )}
              </>
            )
          },
          {
            field: 'chargifyId',
            title: 'Chargify ID',
            template: (col) => (
              <>
                {col.chargifyId && (
                  <a
                    target="_blank"
                    href={`https://checkbox.chargify.com/subscriptions/${col.chargifyId}`}
                    className={classes.link_wrapper}
                    rel="noreferrer"
                  >
                    <Typography
                      color="primary"
                      variant="subtitle2"
                      className={classes.link}
                    >
                      {col.chargifyId}
                    </Typography>
                  </a>
                )}
              </>
            )
          }
        ]}
        apiProps={{
          api: onlineAccountApi,
          apiMethodName: 'useLazyGetOnlineAccountsQuery',
          apiParams: {
            region,
            searchText,
            isActive: isActive === null ? undefined : isActive,
            isTrial: isTrial === null ? undefined : isTrial,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
          },
        }}
      />
    </CardWrapper>
  );
};

export default OnlineAccounts;
