import React, { memo } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@material-ui/icons';

interface ITableSearchProps {
  change?: (v: string) => void,
  className?: string
  placeholder?: string | null,
}
const TableSearch = ({ change, className, placeholder }:ITableSearchProps) => {
  return (
    <div className={className}>
      <TextField
        fullWidth
        onChange={(a) => {
          if (change) {
            change(a.target.value);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          )
        }}
        placeholder={placeholder as string || 'Search'}
      />
    </div>
  );
};

export default memo(TableSearch);
