import { book, CardWrapper, DataGrid } from '@monorepo/common';
import { mergeTextApi } from '../../services/mergeText';
import { IMergeText } from '../../services/mergeText/interfaces';
import AdminLink from '../../components/AdminLink';

const MergeTexts = () => (
  <CardWrapper
    titleCenter
    centerText
    helmet="Merge Texts"
    title="Merge Texts"
  >
    <DataGrid<IMergeText>
      keyExtractor={(col) => col.id.toString()}
      styleProps={{
        isSelectedEven: true
      }}
      defaultGridProps={{
        defaultSortBy: 'id',
        defaultPageSize: 25
      }}
      columns={[
        {
          field: 'id',
          title: 'ID',
          template: (col) => (
            <AdminLink
              url={book.admin.mergeText(col.id)}
              title={col.id}
            />
          )
        },
        {
          field: 'type',
          title: 'Type',
        },
        {
          field: 'label',
          title: 'Label',
        },
      ]}
      apiProps={{
        api: mergeTextApi,
        apiMethodName: 'useLazyGetMergeTextsQuery',
      }}
    />
  </CardWrapper>
);

export default MergeTexts;
