import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListResp, ISelectListItem } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IRenewalQuote, IRenewalQuoteListRequest, IRenewalQuoteDetails, IRenewalQuoteItem, IRenewalQuoteGenerateQuotesRequest, IRenewalQuoteEmail, IEmailPreviewRequest, IRenewalQuoteSendEmailRequest, IRenewalQuoteSendEmailsRequest } from './interfaces';

export const renewalQuoteApi = createApi({
  reducerPath: 'renewalQuoteApi',
  tagTypes: ['renewalQuote'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getRenewalQuotes: build.query<IListResp<IRenewalQuoteItem>, IRenewalQuoteListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
        startDate,
        endDate,
        hasEarlyQuotes,
        hasServerProducts,
        isOptedOut,
      }) => ({
        url: endpoints.renewalQuotes,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
          startDate,
          endDate,
          hasEarlyQuotes,
          hasServerProducts,
          isOptedOut,
        }
      }),
      providesTags: () => ['renewalQuote']
    }),
    getRenewalQuote: build.query<IRenewalQuoteDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.renewalQuote}/${id}`,
      }),
      providesTags: () => ['renewalQuote']
    }),
    getEmailsPreview: build.query<ISelectListItem[], number>({
      query: (templateType) => ({
        url: endpoints.renewalQuoteEmailsPreview,
        params: {
          templateType,
        }
      }),
    }),
    getEmailPreview: build.query<IRenewalQuoteEmail, IEmailPreviewRequest>({
      query: ({
        renewalId,
        templateId
      }) => ({
        url: `${endpoints.renewalQuoteEmailPreview}/${renewalId}/${templateId}`,
      }),
    }),
    updateRenewalQuoteItem: build.mutation<string, IRenewalQuote>({
      query: ({
        id,
        supportContractId,
        firstName,
        lastName,
        companyName,
        email,
        applicationName,
        contractEndDate,
        optOutDate,
        productId1Year,
        productId2Year,
        price1YearEarly,
        price2YearEarly,
        price1Year,
        price2Year
      }) => ({
        url: `${endpoints.renewalQuoteEdit}/${id}`,
        method: 'PATCH',
        body: {
          supportContractId,
          shipmentFirstName: firstName,
          shipmentLastName: lastName,
          shipmentCompany: companyName,
          shipmentEmail: email,
          applicationName,
          contractEndDate,
          optOutDate,
          productId1Year,
          productId2Year,
          price1YearEarly,
          price2YearEarly,
          price1Year,
          price2Year
        },
      }),
      invalidatesTags: ['renewalQuote']
    }),
    updateRenewalQuote: build.mutation<string, IRenewalQuoteDetails>({
      query: ({
        id,
        sourceOrderId,
        supportContractId,
        applicationName,
        contractEndDate,
        optOutDate,
        email2,
        email3,
        billingEmail,
        billingAddress1,
        billingAddress2,
        billingCity,
        billingCompany,
        billingCountry,
        billingFirstName,
        billingLastName,
        billingPhone,
        billingProvince,
        billingState,
        billingZip,
        shipmentEmail,
        shipmentAddress1,
        shipmentAddress2,
        shipmentCity,
        shipmentCompany,
        shipmentCountry,
        shipmentFirstName,
        shipmentLastName,
        shipmentPhone,
        shipmentProvince,
        shipmentState,
        shipmentZip,
        productId1Year,
        productId2Year,
        productQty1Year,
        productQty2Year,
        price1YearEarly,
        price2YearEarly,
        price1Year,
        price2Year,
        quoteId1Year,
        quoteId2Year,
        quoteId1YearEarly,
        quoteId2YearEarly,
      }) => ({
        url: `${endpoints.renewalQuoteEdit}/${id}`,
        method: 'PATCH',
        body: {
          sourceOrderId,
          supportContractId,
          applicationName,
          contractEndDate,
          optOutDate,
          email2,
          email3,
          billingEmail,
          billingAddress1,
          billingAddress2,
          billingCity,
          billingCompany,
          billingCountry,
          billingFirstName,
          billingLastName,
          billingPhone,
          billingProvince,
          billingState,
          billingZip,
          shipmentEmail,
          shipmentAddress1,
          shipmentAddress2,
          shipmentCity,
          shipmentCompany,
          shipmentCountry,
          shipmentFirstName,
          shipmentLastName,
          shipmentPhone,
          shipmentProvince,
          shipmentState,
          shipmentZip,
          productId1Year,
          productId2Year,
          productQty1Year,
          productQty2Year,
          price1YearEarly,
          price2YearEarly,
          price1Year,
          price2Year,
          quoteId1Year,
          quoteId2Year,
          quoteId1YearEarly,
          quoteId2YearEarly,
        },
        params: {
          includeDetails: true
        }
      }),
      invalidatesTags: ['renewalQuote']
    }),
    generateQuotes: build.mutation<number, IRenewalQuoteGenerateQuotesRequest>({
      query: ({
        startDate,
        endDate,
        earlyPriceEndDate,
        regularPriceEndDate
      }) => ({
        url: endpoints.renewalQuoteGenerator,
        method: 'POST',
        body: {
          startDate,
          endDate,
          earlyPriceEndDate,
          regularPriceEndDate
        },
      }),
    }),
    sendRenewalEmail: build.mutation<string, IRenewalQuoteSendEmailRequest>({
      query: ({
        renewalId,
        templateId,
        email,
      }) => ({
        url: endpoints.renewalQuoteSendEmail,
        method: 'POST',
        body: {
          renewalId,
          templateId,
          email,
        },
      }),
    }),
    sendRenewalEmails: build.mutation<number, IRenewalQuoteSendEmailsRequest>({
      query: ({
        startDate,
        endDate,
        templateId,
        hasEarlyQuotes,
        hasServerProducts,
      }) => ({
        url: endpoints.renewalQuoteSendEmails,
        method: 'POST',
        body: {
          startDate,
          endDate,
          templateId,
          hasEarlyQuotes,
          hasServerProducts,
        },
      }),
    }),
    deleteRenewalQuote: build.mutation<string, number>({
      query: (id: number) => ({
        url: `${endpoints.renewalQuoteDelete}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetRenewalQuotesQuery,
  useGetRenewalQuoteQuery,
  useGetEmailsPreviewQuery,
  useGetEmailPreviewQuery,
  useUpdateRenewalQuoteItemMutation,
  useUpdateRenewalQuoteMutation,
  useGenerateQuotesMutation,
  useSendRenewalEmailMutation,
  useSendRenewalEmailsMutation,
  useDeleteRenewalQuoteMutation,
} = renewalQuoteApi;
