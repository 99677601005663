import React, { memo, ReactElement, ReactNode } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { List } from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import { IChildrenProps } from '../../../interfaces';

import { useStyles } from '../styles';

interface SubMenuProps extends IChildrenProps {
  title: string,
  icon: ReactNode | ReactElement
}

const ItemWithSubMenu = ({ title, icon, children }:SubMenuProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        className={clsx(classes.menuItem, classes.headSubMenu, {
          [classes.activeHeadItem]: open
        })}
      >
        {icon && (
        <div className={classes.wrapperIcon}>
          {icon}
        </div>
        )}
        <ListItemText
          primary={title}
          className="title_head"
        />
        {open ? <IconExpandLess /> : <IconExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={clsx(classes.wrapper_for_dropdown)}
      >
        <List
          component="div"
          disablePadding
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default memo(ItemWithSubMenu);
