import { useEffect, useState } from 'react';
import { Paper, Toolbar, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Box } from '@mui/material';
import { Btn, Loader } from '@monorepo/common';
import EmptyTable from '@monorepo/common/src/components/DataGrid/components/EmptyTable';
import { useStyles } from '../../Order/styles';
import { useGetOnlineAccountUsersQuery, useGetOnlineAccountLogin7LinkQuery } from '../../../services/onlineAccount';

interface OnlineAccountUsersProps {
  region: string,
  id: number,
}

const OnlineAccountUsers = ({ region, id }: OnlineAccountUsersProps) => {
  const classes = useStyles();

  const [userName, setUserName] = useState<string>('');

  const { data, isLoading, isError } = useGetOnlineAccountUsersQuery({ region, id });
  const { data: login7Link, isLoading: isLoadingLink, isSuccess } = useGetOnlineAccountLogin7LinkQuery({ region, id, userName }, { skip: !userName });

  useEffect(() => {
    if (isSuccess) {
      window.open(login7Link, '_blank')?.focus();
    }
  }, [isSuccess]);

  return (
    <>
      {isLoadingLink && (
        <Loader
          position="absolute"
          size={70}
          blur
        />
      )}
      <Toolbar sx={{ mt: 2 }}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          component="div"
        >
          Users
        </Typography>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table className={classes.striped_table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Login 7</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell
                  colSpan={3}
                  sx={{ textAlign: 'center' }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {data && data.map((user) => (
              <TableRow key={user.userName}>
                <TableCell>
                  {user.userName}
                </TableCell>
                <TableCell>
                  <Btn click={() => setUserName(user.userName)}>
                    Login
                  </Btn>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(data && data.length === 0) && <EmptyTable />}
        {!data && isError && (
          <Box sx={{ color: 'red' }}>
            Cannot get user data for this account.
          </Box>
        )}
      </TableContainer>
    </>
  );
};

export default OnlineAccountUsers;
