import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { theme, config } from '@monorepo/common';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorker from './serviceWorker';
import { store } from './app/store';
import App from './App';
import GlobalStyles from './theme/GlobalStyles';

ReactDOM.render(
  <HelmetProvider>
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <Provider store={store}>
          <GoogleOAuthProvider clientId={config.adminGoogleClientId}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <App />
            </ThemeProvider>
          </GoogleOAuthProvider>
        </Provider>
      </BrowserRouter>
    </SnackbarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
