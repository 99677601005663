import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inline_btn: {
    display: 'inline-block',
    marginLeft: 8
  },
  link: {
    textDecoration: 'underline'
  },
  link_wrapper: {
    border: 0,
    whiteSpace: 'nowrap',
  },
}));
