import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { IListRequest, IListResp } from '../../interfaces';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IApplication, IApplicationDetails, IApplicationItemsRequest, IApplicationProductMap, IApplicationProdToAppRequest } from './interfaces';

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  tagTypes: ['application', 'applicationProdToApp'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getApplications: build.query<IListResp<IApplication>, IListRequest>({
      query: ({
        pageSize,
        page,
        searchText,
        sortingOrder,
        sortBy,
      }) => ({
        url: endpoints.applications,
        params: {
          pageSize,
          page,
          searchText,
          sortingOrder,
          sortBy,
        }
      }),
      providesTags: () => ['application'],
    }),
    getApplication: build.query<IApplicationDetails, string>({
      query: (id: string) => ({
        url: `${endpoints.application}/${id}`,
      }),
      providesTags: () => ['application'],
    }),
    createApplication: build.mutation<number, IApplicationDetails>({
      query: ({
        isActive,
        name,
        description,
        licenseText,
        renewalEmail,
      }) => ({
        url: endpoints.applicationCreate,
        method: 'POST',
        body: {
          isActive,
          name,
          description,
          licenseText,
          renewalEmail,
        },
      }),
      invalidatesTags: ['application'],
    }),
    updateApplication: build.mutation<IApplicationDetails, IApplicationDetails>({
      query: ({
        id,
        isActive,
        name,
        description,
        licenseText,
        renewalEmail,
      }) => ({
        url: `${endpoints.applicationEdit}/${id}`,
        method: 'PATCH',
        body: {
          isActive,
          name,
          description,
          licenseText,
          renewalEmail,
        }
      }),
      invalidatesTags: ['application'],
    }),
    getApplicationProdToApps: build.query<IListResp<IApplicationProductMap>, IApplicationItemsRequest>({
      query: ({
        pageSize,
        page,
        sortingOrder,
        sortBy,
        applicationId,
      }) => ({
        url: `${endpoints.applicationProdToApps}/${applicationId}`,
        params: {
          pageSize,
          page,
          sortingOrder,
          sortBy,
        },
      }),
      providesTags: () => ['applicationProdToApp'],
    }),
    createApplicationProdToApp: build.mutation<number, IApplicationProductMap>({
      query: ({
        applicationId,
        productId,
      }) => ({
        url: `${endpoints.applicationCreateProdToApp}/${applicationId}`,
        method: 'POST',
        body: {
          productId,
        },
      }),
      invalidatesTags: ['applicationProdToApp'],
    }),
    createApplicationMaintMap: build.mutation<number, IApplicationProductMap>({
      query: ({
        applicationId,
        productId,
      }) => ({
        url: `${endpoints.applicationCreateMaintMap}/${applicationId}`,
        method: 'POST',
        body: {
          productId,
        },
      }),
      invalidatesTags: ['application'],
    }),
    createApplicationUpgradeMap: build.mutation<number, IApplicationProductMap>({
      query: ({
        applicationId,
        productId,
      }) => ({
        url: `${endpoints.applicationCreateUpgradeMap}/${applicationId}`,
        method: 'POST',
        body: {
          productId,
        },
      }),
      invalidatesTags: ['application'],
    }),
    updateApplicationProdToApp: build.mutation<string, IApplicationProdToAppRequest>({
      query: ({
        applicationId,
        productId,
        newApplicationId,
        newProductId,
      }) => ({
        url: `${endpoints.applicationEditProdToApp}/${applicationId}/${productId}`,
        method: 'PATCH',
        body: {
          applicationId: newApplicationId,
          productId: newProductId,
        },
      }),
      invalidatesTags: ['applicationProdToApp'],
    }),
    updateApplicationMaintMap: build.mutation<string, IApplicationProdToAppRequest>({
      query: ({
        applicationId,
        productId,
        newApplicationId,
        newProductId,
      }) => ({
        url: `${endpoints.applicationEditMaintMap}/${applicationId}/${productId}`,
        method: 'PATCH',
        body: {
          applicationId: newApplicationId,
          productId: newProductId,
        },
      }),
      invalidatesTags: ['application'],
    }),
    updateApplicationUpgradeMap: build.mutation<string, IApplicationProdToAppRequest>({
      query: ({
        applicationId,
        productId,
        newApplicationId,
        newProductId,
      }) => ({
        url: `${endpoints.applicationEditUpgradeMap}/${applicationId}/${productId}`,
        method: 'PATCH',
        body: {
          applicationId: newApplicationId,
          productId: newProductId,
        },
      }),
      invalidatesTags: ['application'],
    }),
    deleteApplicationProdToApp: build.mutation<string, IApplicationProductMap>({
      query: ({
        applicationId,
        productId,
      }) => ({
        url: `${endpoints.applicationDeleteProdToApp}/${applicationId}/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['applicationProdToApp'],
    }),
    deleteApplicationMaintMap: build.mutation<string, IApplicationProductMap>({
      query: ({
        applicationId,
        productId,
      }) => ({
        url: `${endpoints.applicationDeleteMaintMap}/${applicationId}/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['application'],
    }),
    deleteApplicationUpgradeMap: build.mutation<string, IApplicationProductMap>({
      query: ({
        applicationId,
        productId,
      }) => ({
        url: `${endpoints.applicationDeleteUpgradeMap}/${applicationId}/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['application'],
    }),
  }),
});

export const {
  useLazyGetApplicationsQuery,
  useGetApplicationQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useGetApplicationProdToAppsQuery,
  useCreateApplicationProdToAppMutation,
  useCreateApplicationMaintMapMutation,
  useCreateApplicationUpgradeMapMutation,
  useUpdateApplicationProdToAppMutation,
  useUpdateApplicationMaintMapMutation,
  useUpdateApplicationUpgradeMapMutation,
  useDeleteApplicationProdToAppMutation,
  useDeleteApplicationMaintMapMutation,
  useDeleteApplicationUpgradeMapMutation
} = applicationApi;
