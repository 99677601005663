import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { book, Btn, CardWrapper, FormField } from '@monorepo/common';
import { Box, Grid, Switch } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showNotification } from '../../features/ui/sliceNotification';
import { useCreateProductMutation, useDeleteProductMutation, useGetProductQuery, useUpdateProductMutation } from '../../services/product';
import { IProductDetails } from '../../services/product/interfaces';
import { useStyles } from './styles';
import { schema } from './schema';
import GridField from '../../components/GridField';
import ConfirmModal from '../../components/ConfirmModal';

const Product = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isNew = id === 'new';

  const getNewProduct = () => {
    const user = {
      name: '',
      price: 0,
      partNumber: '',
      description: '',
      isActive: true
    } as unknown as IProductDetails;

    return {
      data: user,
      isLoading: false
    };
  };

  const { data, isLoading: isLoadingData } = isNew ? getNewProduct() : useGetProductQuery(id || '', { skip: !id });

  const [isActive, setIsActive] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [createProduct, { isLoading: isCreatingData, isSuccess: isCreateSuccess }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateProductMutation();
  const [deleteProduct, { isLoading: isDeletingData, isSuccess: isDeleteSuccess }] = useDeleteProductMutation();

  let pageTitle = '';

  if (data && isNew) {
    pageTitle = 'Create Product';
  } else if (data) {
    pageTitle = `Product #${data && data.id}`;
  } else {
    pageTitle = 'Product not found';
  }

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IProductDetails>({
    resolver: yupResolver(schema),
    defaultValues: {
      price: 0
    }
  });

  const onSubmit = (product: IProductDetails) => {
    if (isNew) {
      createProduct({ ...product, isActive }).then((res: any) => {
        if (res.error) {
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: res.error?.data || 'Error'
          }));
        }
      });
    } else {
      updateProduct({ ...product, isActive, includeDetails: true }).then((res: any) => {
        if (res.error) {
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: res.error?.data || 'Error'
          }));
        }
      });
    }
  };

  const deleteProductConfirm = () => {
    deleteProduct(+id!);
    setShowDeleteModal(false);
    navigate(book.admin.product());
  };

  useEffect(() => {
    if (data && !isNew) {
      setIsActive(data.isActive);
      reset({
        ...data
      });
    }
  }, [data]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(showNotification({
        text: 'Product created',
        type: 'success',
        show: true
      }));
      navigate(book.admin.product());
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(showNotification({
        text: 'Product updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(showNotification({
        text: 'Product removed',
        type: 'success',
        show: true
      }));
    }
  }, [isDeleteSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet="Product"
      title={pageTitle}
      maxWidth="100%"
      isLoading={isLoadingData || isCreatingData || isUpdatingData || isDeletingData}
      childrenTitleRight={data && (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Btn
            className={classes.inline_btn}
            click={handleSubmit(onSubmit)}
          >
            Save
          </Btn>
          <Btn
            className={classes.inline_btn}
            click={() => setShowDeleteModal(true)}
          >
            Delete
          </Btn>
        </Box>
      )}
    >
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={deleteProductConfirm}
      />
      {data && (
        <Box>
          <form>
            <Grid
              sx={{ mt: 2 }}
              container
              spacing={2}
            >
              <GridField
                label="Is Active"
                labelmd={3}
                fieldmd={9}
              >
                <Switch
                  checked={isActive}
                  onChange={(event) => {
                    setIsActive(event.target.checked);
                  }}
                  name="isActive"
                />
              </GridField>
              <GridField
                label="Name"
                labelmd={3}
                fieldmd={5}
              >
                <FormField
                  extraProps={{ ...register('name') }}
                  size="small"
                  errors={errors}
                  classNameForWrapper={classes.field_wrapper}
                />
              </GridField>
              <Grid
                item
                md={4}
              />
              <GridField
                label="Price"
                labelmd={3}
                fieldmd={9}
              >
                <FormField
                  extraProps={{ ...register('price') }}
                  type="number"
                  size="small"
                  fullWidth={false}
                  errors={errors}
                  classNameForWrapper={classes.field_wrapper}
                />
              </GridField>
              <GridField
                label="Part Number"
                labelmd={3}
                fieldmd={9}
              >
                <FormField
                  extraProps={{ ...register('partNumber') }}
                  size="small"
                  fullWidth={false}
                  errors={errors}
                  classNameForWrapper={classes.field_wrapper}
                />
              </GridField>
              <GridField
                label="Description"
                labelmd={3}
                fieldmd={5}
              >
                <FormField
                  extraProps={{ ...register('description') }}
                  size="small"
                  type="textarea"
                  errors={errors}
                  classNameForWrapper={classes.field_wrapper}
                />
              </GridField>
              <Grid
                item
                md={4}
              />
            </Grid>
          </form>
        </Box>
      )}
    </CardWrapper>
  );
};

export default Product;
