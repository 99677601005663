import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Btn, CardWrapper } from '@monorepo/common';
import { Box, Grid, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useStyles } from './styles';
import GridField from '../../components/GridField';
import { showNotification } from '../../features/ui/sliceNotification';
import { useGetOnlineAccountQuery, useUpdateOnlineAccountMutation } from '../../services/onlineAccount';
import { IOnlineAccountDetails } from '../../services/onlineAccount/interfaces';
import OnlineAccountLimits from './components/OnlineAccountLimits';
import OnlineAccountVerifiedSenders from './components/OnlineAccountVerifiedSenders';
import OnlineAccountUsers from './components/OnlineAccountUsers';
import ConfirmModal from '../../components/ConfirmModal';

const OnlineAccount = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { region, id } = useParams();

  const [showSaveSubmitModal, setShowSaveSubmitModal] = useState<boolean>(false);
  const [fields, setFields] = useState<IOnlineAccountDetails>({ isActive: false } as IOnlineAccountDetails);

  const { data, isLoading: isLoadingData, refetch } = useGetOnlineAccountQuery({ region: region!, id: +id! });
  const [updateOnlineAccount, { isLoading: isUpdatingData, isSuccess: isUpdateSuccess }] = useUpdateOnlineAccountMutation();

  const changeCellField = (name: string, value?: any) => {
    setFields({
      ...fields!,
      [name]: value
    });
  };

  const saveSubmit = () => {
    updateOnlineAccount({
      region: region!,
      ...fields!
    });
  };

  const save = () => {
    if (data?.contextUrl !== fields.contextUrl) {
      setShowSaveSubmitModal(true);
    } else {
      saveSubmit();
    }
  };

  useEffect(() => {
    if (data) {
      setFields({
        ...data,
        startDate: moment(data.startDate).toDate(),
        endDate: moment(data.endDate).toDate(),
      });
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      dispatch(showNotification({
        text: 'Online Account updated',
        type: 'success',
        show: true
      }));
    }
  }, [isUpdateSuccess]);

  return (
    <CardWrapper
      titleCenter
      centerText
      helmet={`Online Account ${data?.name}`}
      title={`Online Account ${data?.name}`}
      maxWidth="100%"
      isLoading={isLoadingData || isUpdatingData}
      childrenTitleRight={fields
        && (
          <Box>
            <Btn
              className={classes.inline_btn}
              click={save}
            >
              Save
            </Btn>
          </Box>
        )}
    >
      <ConfirmModal
        title="Account URL has been changed. Do you confirm this update?"
        showModal={showSaveSubmitModal}
        setShowModal={setShowSaveSubmitModal}
        onConfirm={saveSubmit}
      />
      {data && (
        <Box>
          <Grid
            sx={{ mt: 2 }}
            container
            spacing={2}
          >
            <GridField
              label="Is Active"
              labelmd={3}
              fieldmd={9}
            >
              <Switch
                checked={fields.isActive}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  changeCellField('isActive', event.target.checked);
                }}
                name="isActive"
              />
            </GridField>
            <GridField
              label="Support Contract ID"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                value={fields.supportContractId || ''}
                size="small"
                type="number"
                inputProps={{ maxLength: 10 }}
                onChange={(val) => changeCellField('supportContractId', +val.target.value)}
              />
            </GridField>
            <GridField
              label="Start Date"
              labelmd={3}
              fieldmd={9}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={fields.startDate}
                  onChange={(event) => changeCellField('startDate', event)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!(fields.endDate && fields.startDate && fields.endDate < fields.startDate)}
                    />
                  )}
                />
              </LocalizationProvider>
            </GridField>
            <GridField
              label="End Date"
              labelmd={3}
              fieldmd={9}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={fields.endDate}
                  onChange={(event) => changeCellField('endDate', event)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!(fields.endDate && fields.startDate && fields.endDate < fields.startDate)}
                      helperText={fields.endDate && fields.startDate && fields.endDate < fields.startDate && 'End date should be greater than start date'}
                    />
                  )}
                />
              </LocalizationProvider>
            </GridField>
            <GridField
              label="Account Type"
              labelmd={3}
              fieldmd={9}
            >
              {fields.contextType != null && (
                <Select
                  value={fields.contextType}
                  size="small"
                  onChange={(event: SelectChangeEvent<number>) => changeCellField('contextType', +event.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  {data?.contextTypes.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </GridField>
            <GridField
              label="Account URL"
              labelmd={3}
              fieldmd={9}
            >
              <TextField
                sx={{ width: 300 }}
                value={fields.contextUrl || ''}
                inputProps={{ maxLength: 255 }}
                error={!fields.contextUrl?.trim()}
                helperText={!fields.contextUrl?.trim() && 'The context URL field is required'}
                onChange={(event) => {
                  changeCellField('contextUrl', event.target.value);
                }}
                size="small"
              />
            </GridField>
            <GridField
              label="Database Name"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              <b>
                {fields.dbName}
              </b>
            </GridField>
            <GridField
              label="Upgrade Link"
              labelmd={3}
              fieldmd={9}
              wrap
            >
              <b>
                {fields.contextGuid && `https://orders.checkbox.com/mvc/purchase/subscribe/standard-1?cGuid=${fields.contextGuid}?domain=${fields.contextUrl.substring(fields.contextUrl.indexOf('.') + 1)}`}
              </b>
            </GridField>
            {fields.chargifySubscriptionId && (
              <GridField
                label="Chargify Subscription ID"
                labelmd={3}
                fieldmd={9}
                wrap
              >
                <a
                  target="_blank"
                  href={`https://checkbox.chargify.com/subscriptions/${fields.chargifySubscriptionId}`}
                  className={classes.link_wrapper}
                  rel="noreferrer"
                >
                  <Typography
                    color="primary"
                    variant="subtitle2"
                    className={classes.link}
                  >
                    {fields.chargifySubscriptionId}
                  </Typography>
                </a>
              </GridField>
            )}
          </Grid>
          <OnlineAccountLimits
            region={region!}
            id={+id!}
            items={data?.limits}
          />
          <OnlineAccountUsers
            region={region!}
            id={+id!}
          />
          <OnlineAccountVerifiedSenders
            region={region!}
            id={+id!}
            items={data?.verifiedSenders}
          />
        </Box>
      )}
    </CardWrapper>
  );
};

export default OnlineAccount;
