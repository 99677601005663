export enum Permission {
  AdminUsers = 1,
  Applications = 2,
  Logs = 3,
  Licenses = 4,
  Products = 5,
  Reports = 6,
  SupportContracts = 7,
  Users = 8,
  Quotes = 9,
  RenewalQuotes = 10,
  RenewalQuotesGenerator = 11,
  MessageTemplates = 12,
  Orders = 13,
  OnlineAccounts = 14,
  SalesReps = 15,
  MergeTexts = 16,
}
