import React, { memo, ReactNode, useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { IChildrenProps } from '@monorepo/common/src/interfaces';
import { ErrorBoundary } from '@monorepo/common';

import LeftSideBar from './components/LeftSideBar';
import GeneralOverlay from './components/GeneralOverlay';
import { useAppSelector } from '../../app/hooks';

import { useStyles } from './styles';

interface ILayoutProps extends IChildrenProps {
  isAuth?: boolean,

  showHeader?: boolean,
  showLeftSide?: boolean,

  menuComponent?: ReactNode,
  headerComponent?: ReactNode
  upperPanel?: ReactNode

  hideLeftSideForThisPages?: string[],
  hideHeaderSideForThisPages?: string[],
}
const Layout = ({
  children,
  showHeader = true,
  showLeftSide = true,
  menuComponent,
  isAuth = false,
  headerComponent,
  upperPanel,
  hideLeftSideForThisPages = [],
  hideHeaderSideForThisPages = [],
}:ILayoutProps) => {
  const classes = useStyles();
  const location = useLocation();

  const [showComponentLeftSide, setShowComponentLeftSide] = useState<boolean>(true);
  const [showComponentHeader, setShowComponentHeader] = useState<boolean>(true);
  const [hasPermission, setHasPermission] = useState<boolean>(true);

  const { isMobileMenu, noPermission } = useAppSelector(({ ui, globalError }) => ({
    isMobileMenu: ui.isMobileMenu,
    noPermission: globalError.status
  }));

  // for left side
  useEffect(() => {
    if (hideLeftSideForThisPages.length) {
      const result = hideLeftSideForThisPages.find((item: string) => item === location.pathname);
      if (!result) {
        setShowComponentLeftSide(true);
        return;
      }
      setShowComponentLeftSide(false);
    } else {
      setShowComponentLeftSide(true);
    }
  }, [location, hideLeftSideForThisPages]);

  // for header-
  useEffect(() => {
    if (hideHeaderSideForThisPages.length) {
      const result = hideHeaderSideForThisPages.find((item: string) => item === location.pathname);
      if (!result) {
        setShowComponentHeader(true);
        return;
      }
      setShowComponentHeader(false);
    } else {
      setShowComponentHeader(true);
    }
  }, [location, hideHeaderSideForThisPages]);

  useEffect(() => {
    if (noPermission && noPermission === 403) {
      setHasPermission(false);
      return;
    }
    setHasPermission(true);
  }, [noPermission]);

  return (
    <div className={classes.root}>
      <ErrorBoundary>
        {showComponentLeftSide && isAuth && showLeftSide && (
          <LeftSideBar>
            {menuComponent && menuComponent}
          </LeftSideBar>
        )}
      </ErrorBoundary>

      <main className={clsx(classes.content)}>
        <CssBaseline />
        {isAuth && showComponentHeader && showHeader && headerComponent && (
        <ErrorBoundary>
          {headerComponent}
        </ErrorBoundary>
        )}
        <div className={clsx(classes.work_zone, {
          [classes.menuOpen]: isMobileMenu
        })}
        >
          {upperPanel && upperPanel}

          {hasPermission && children}
          {/* <div className={classes.work_space}> */}
          {/*  {hasPermission && children} */}
          {/* </div> */}
        </div>
        {isMobileMenu && <GeneralOverlay />}
      </main>
    </div>
  );
};

export default memo(Layout);
