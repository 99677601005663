export interface IUserProfileReducer {
  AdminId: string | null,
  UserName: string | null,
  IsSuper: boolean,
  UserRoles: string[],
  UserAvatar: string | null,
}
export const userProfileInitialState:IUserProfileReducer = {
  AdminId: null,
  UserName: null,
  IsSuper: false,
  UserRoles: [],
  UserAvatar: null,
};
